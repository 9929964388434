import React, { useEffect, useState } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Paginate from '../Utilities/Paginate';
import {
  deletePromoCode,
  listPromoCodes,
} from '../../store/actions/promoActions';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import '../Utilities/CommonStyles.css';
const promoHeaders = ['S.No.', 'Host Name', 'Promo code', 'claimed count'];

const PromotionalCodesList = (props) => {
  const pageUrl = `/panel/promotional-codes`;
  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.promotions.isLoading);
  const totalItems = useSelector((state) => state.promotions.total);
  const perPage = useSelector((state) => state.promotions.perPage);
  const deletingCode = useSelector((state) => state.promotions.deletingCode);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const location = useLocation();
  const history = useHistory(); // Obtener el objeto de historial para actualizar la URL
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(listPromoCodes(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handlePageChange = (newPage) => {
    history.push(`${pageUrl}?page=${newPage}`);
  };

  const handleDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      await dispatch(deletePromoCode(id));
    }
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const promocodeRow = (data, i) => {
    return (
      <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
        <td>{startIndex + 1 + i}</td>
        <td>{data.hostName}</td>
        <td>{data.code}</td>
        <td className="text-center">{data.claimedCount}</td>
        <td>
          {deletingCode === data.id ? (
            <i className="fas fa-spinner fa-pulse ml-3"></i>
          ) : (
            <>
              <Link to={pageUrl + '/' + data.id + '/edit'}>
                <i className="fas fa-edit"></i>
              </Link>
              <span
                onClick={() => {
                  setDeleteData({ id: data.id, message: data.hostName });
                  setShow(!show);
                }}
                className="remove ml-4"
              >
                <i className="fas fa-trash text-danger"></i>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-3">
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {promoHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {props.data && props.data.length > 0 && (
                <tbody>
                  {props.data.slice(startIndex, endIndex).map(promocodeRow)}
                </tbody>
              )}
              <Paginate
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / perPage)}
                  onPageChange={handlePageChange}
              />
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default PromotionalCodesList;
