import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Header.css';
import NavbarLink from '../Utilities/NavbarLink';

const Header = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
      <Link className="navbar-brand" to="/">
        Ask Diem
      </Link>
      {auth.isLoggedIn ? (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <span className="navbar-text">Hi, {auth.user.Email}</span>
          </li>
          <NavbarLink className="nav-link" to="/logout">
            Logout
          </NavbarLink>
        </ul>
      ) : (
        <ul className="navbar-nav ml-auto">
          <NavbarLink className="nav-link" to="/login">
            Login
          </NavbarLink>
        </ul>
      )}
    </nav>
  );
};

export default Header;
