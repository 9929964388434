import * as Yup from 'yup';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import Picker from 'emoji-picker-react';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import ErrorMessage from '../Utilities/ErrorMessage';
import {
  editInterest,
  listInterests,
  setEditInterest,
} from '../../store/actions/interestActions';

const InterestEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const interestList = useSelector((state) => state.interests.interestData);
  const editData = useSelector((state) => state.interests.editData);
  const alreadyExist = useSelector((state) => state.interests.alreadyExist);
  const [editInterestData, seteditInterestData] = useState();
  const history = useHistory();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setShowEmojiPicker(false);
  };
  const initialValues = {
    interestName: editInterestData?.keyword || '',
    iconImg: editInterestData?.iconImg || '',
    fontColor: editInterestData?.fontColor || '#e1d8eb',
    backgroundColor: editInterestData?.backgroundColor || '#e1d8eb',
  };

  const fetchInterestEdit = useCallback(async () => {
    if (id) {
      !interestList && (await dispatch(listInterests(1)));
      await dispatch(setEditInterest(id));
    }
  }, [dispatch, interestList, id]);

  useEffect(() => {
    if (editData) {
      seteditInterestData(editData);
      setChosenEmoji({ emoji: editData.emoji });
    }
  }, [editData]);

  useEffect(() => {
    fetchInterestEdit();
  }, [fetchInterestEdit]);

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.keyword = values.interestName;
      data.iconImg = values.iconImg;
      data.emoji = chosenEmoji?.emoji;
      data.fontColor = values.fontColor;
      data.backgroundColor = values.backgroundColor;
      data.id = id;
      let response = await dispatch(editInterest(data));
      response && history.goBack();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    interestName: Yup.string().required('Interest name is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Edit Interest</h1>
      <br></br>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name"> Interest name</label>
            <input
              name="interestName"
              id="interestName"
              value={formik.values.interestName}
              placeholder="Enter the interest name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="interestName" formik={formik} />
          </div>
          <div className="form-group col-3 col-md-6">
            <label htmlFor="name">Icon image</label>
            <input
              name="iconImg"
              id="iconImg"
              value={formik.values.iconImg}
              placeholder="Enter the icon image name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="iconImg" formik={formik} />
          </div>
        </div>
        <div className="row pl-3">
          <div className="form-group emoji">
            <label htmlFor="name">Choose an emoji</label>
            <div
              title="pick"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="h2 pl-2"
              role="button"
            >
              {chosenEmoji?.emoji || (
                <IconButton color="primary" aria-label="emoji" onClick={() => {
                }}>
                  <EmojiEmotionsIcon/>
                </IconButton>
              )}
            </div>
            {chosenEmoji?.emoji && (
              <IconButton
                color="secondary"
                aria-label="clear emoji"
                onClick={() => setChosenEmoji(null)}
              >
                <ClearIcon/>
              </IconButton>
            )}
          </div>
          <div className="form-group pl-3">
            <label htmlFor="color">Background color:</label>
            <input
              type="color"
              name="backgroundColor"
              id="backgroundColor"
              className="ml-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.backgroundColor}
            />
            <ErrorMessage name="color" formik={formik} />
          </div>
        </div>
        {showEmojiPicker && (
          <Picker native={true} onEmojiClick={onEmojiClick} />
        )}
        {alreadyExist && (
          <div>
            <Alert severity="error">
              The interest given already exists! try changing it
            </Alert>
            <br></br>
          </div>
        )}
        <br></br>
        <div className="d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Update
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default InterestEdit;
