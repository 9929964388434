import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import PromotionalCode from './PromotionalCode';
import PromotionalCodeEdit from './PromotionalCodeEdit';

const Promotions = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchPromotions = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions]);
  return (
    <div>
      <CpPage ListPage={PromotionalCode} EditPage={PromotionalCodeEdit} />
    </div>
  );
};

export default Promotions;
