import { apiRequest } from '../../utils/requests';

export const savedSessionActions = {
  SET_SESSIONS: 'SET_SESSIONS',
  SESSIONS_LOADING: 'SESSIONS_LOADING',
};

export const setSessionListData = ({ sessions, count, isFirstTimeLoad }) => {
  return {
    type: savedSessionActions.SET_SESSIONS,
    payload: { sessions, count, isFirstTimeLoad },
  };
};

export const setSesionLoading = (data) => {
  return {
    type: savedSessionActions.SESSIONS_LOADING,
    payload: data,
  };
};

export const setSessionsList = (currentPage, dateObj) => {
  return async (dispatch, getState) => {
    await dispatch(setSesionLoading(true));
    const { auth } = getState();

    const { startDate: from, endDate: to } = dateObj;

    const apiUrl = `/sessions/savedsession/list`;
    try {
      const { data } = await apiRequest('post', apiUrl, {
        params: {
          offset: currentPage * 10 - 10,
          perPage: 10,
        },
        formData: {
          from, to
        },
        token: auth.user.token,
      });
      // const { listData } = data;
      const { trackedAudioSessions, count } = data;
      await dispatch(
        setSessionListData({
          sessions: trackedAudioSessions,
          count,
          isFirstTimeLoad: false,
        })
      );
      await dispatch(setSesionLoading(false));
    } catch (err) {
      await dispatch(setSesionLoading(false));
    }
  };
};
