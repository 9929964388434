import { delay3Seconds } from '../../utils/constants';
import { apiRequest } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = "/promocodes"

export const promoActions = {
    SET_PROMO_DATA: 'SET_PROMO_DATA',
    SET_IS_LOADING: 'SET_IS_LOADING',
    ADD_PROMO_CODE: 'ADD_PROMO_CODE',
    DELETE_PROMO_CODE: 'DELETE_PROMO_CODE',
    SET_DELETING_PROMOCODE: 'SET_DELETING_PROMOCODE',
    CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
    SET_EDIT_PROMO_CODE: 'SET_EDIT_PROMO_CODE',
    UPDATE_PROMO_CODE_LIST: 'UPDATE_PROMO_CODE_LIST',
    ADD_PROMO_CODE_COMPLETED: 'ADD_PROMO_CODE_COMPLETED',
    SET_ALREADY_EXIST: 'SET_ALREADY_EXIST'
}

export const setAlreadyExist = (data) => {
    return { type: promoActions.SET_ALREADY_EXIST, payload: data };
};

export const setPromoData = ({ promoCodes, count, isFirstTimeLoad = true }) => {
    return { type: promoActions.SET_PROMO_DATA, payload: { promoCodes, count, isFirstTimeLoad } };
};

export const setIsLoading = (data) => {
    return { type: promoActions.SET_IS_LOADING, payload: data };
};

export const setDeletingPromoCode = (data) => {
    return { type: promoActions.SET_DELETING_PROMOCODE, payload: data };
};

export const removePromoCode = (data) => {
    return { type: promoActions.DELETE_PROMO_CODE, payload: data }
}

export const addPromoCode = (data) => {
    return { type: promoActions.ADD_PROMO_CODE, payload: data };
};

export const addPromoCodeCompleted = (data) => {
    return { type: promoActions.ADD_PROMO_CODE_COMPLETED, payload: data };
};

export const setEditPromo = (promoDataId) => {
    return async (dispatch, getState) => {
        await dispatch({ type: promoActions.SET_EDIT_PROMO_CODE, payload: promoDataId })
    }
}
export const checkAlreadyExist = (data) => {
    return { type: promoActions.CHECK_ALREADY_EXIST, payload: data };
};

export const updatePromoCodeList = (data) => {
    return { type: promoActions.UPDATE_PROMO_CODE_LIST, payload: data }
}

export const listPromoCodes = (currentPage) => {
    return async (dispatch, getState) => {
        const { auth, promotions } = getState();
        const { promoData, perPage } = promotions
        let flag = true;
        const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
            list: promoData,
            currentPage,
            perPage,
            flag
        });
        const endpoint = `${pageUrl}/list`;
        if (shouldFetch) {
            await dispatch(setIsLoading(true))
            const { data } = await apiRequest(
                'get',
                endpoint,
                {
                    token: auth.user.token,
                    params
                }
            );
            const { promoCodes, count } = data;
            await dispatch(setPromoData({ promoCodes, count, isFirstTimeLoad }))
            await dispatch(setIsLoading(false))
        }
    }
}

export const addPromoCodes = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/create`;
            const { data } = await apiRequest(
                'post',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { promoCode, created } = data;
            if (created) {
                await dispatch(addPromoCode(promoCode))
                setTimeout(async () => {
                    await dispatch(addPromoCodeCompleted(promoCode))
                }, delay3Seconds)
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return created;
        }
    }
}

export const deletePromoCode = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value}/remove`;
            await dispatch(setDeletingPromoCode(value))
            await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            await dispatch(removePromoCode(value))
            await dispatch(setDeletingPromoCode(0))
        }
    }
}

export const editPromoCode = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value.id}/update`;
            const { data } = await apiRequest(
                'put',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { promoCode, updated } = data
            if (updated) {
                await dispatch(updatePromoCodeList(promoCode))
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return updated
        }
    }
}