import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from './authReducer';
import { communityReducer } from './communityReducer';
import { userReducer } from './userReducer';
import { keywordReducer } from './keywordReducer';
import { channelReducer } from './channelReducer';
import { layoutReducer } from './layoutReducer';
import { metricReducer } from './metricReducer';
import { promoReducer } from './promoReducer';
import { categoryReducer } from './categoryReducer';
import { interestReducer } from './interestReducer';
import { tagsReducer } from './tagsReducer';
import { reportsReducer } from './reportsReducer';
import { sessionReducer } from './sessionReducer';
import { usersListReducers } from './usersListReducers';
import { savedSessionReducer } from './savedSessionReducer';
import { ethnicReducer } from './ethnicReducer';
import { SearchKeywordsReducer } from './SearchKeywordReducer';
import { promotionReducer } from "./PromotionReducer";
import { interestTagReducer } from "./interestTagReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  communities: communityReducer,
  users: userReducer,
  keywords: keywordReducer,
  channels: channelReducer,
  layout: layoutReducer,
  metrics: metricReducer,
  promotions: promoReducer,
  categories: categoryReducer,
  interests: interestReducer,
  interestTags: interestTagReducer,
  tags: tagsReducer,
  reports: reportsReducer,
  sessions: sessionReducer,
  usersList: usersListReducers,
  savedSessions: savedSessionReducer,
  ethnics: ethnicReducer,
  searchKeywords: SearchKeywordsReducer,
  promotionItem: promotionReducer
});

export const middlewares = [ReduxThunk];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
