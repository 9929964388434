import React, { useEffect, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessage from '../Utilities/ErrorMessage';
import {createPromotionItem, listPromotionItems} from '../../store/actions/PromotionAction';
import PromotionItemList from './PromotionItemList';
import ImageUpload, { uploadImageToS3 } from '../Utilities/ImageUpload';

const PromotionItemCreate = () => {
    const [prevImg] = useState('');
    const [uploadedImg, setUploadedImg] = useState();
    const [currentSwitch, setCurrentSwitch] = useState("gradient")
    const useStyles = makeStyles((theme) => ({
        root: {
            height: '720px',
        },
    }));
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues = {
        title: '',
        description: '',
        cta: "Learn more",
        ctaUrl: '',
        imageUrl: ''
    };
    const promotion = useSelector((state) => state.promotionItem.promotionItemData);
    const auth = useSelector((state) => state.auth);

    const fetchData = useCallback(async () => {
        if (auth.user.token) {
            await dispatch(listPromotionItems());
        }
    }, [dispatch, auth.user.token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSubmit = async (
        values,
        { resetForm, setSubmitting, setFieldValue }
    ) => {
        const imageUrl = await uploadImageToS3(uploadedImg, auth?.user?.token)
        values = {...values, imageUrl}
        await dispatch(createPromotionItem({...values, isGradient: currentSwitch === 'gradient' ? true : false}))
        resetForm();
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string(),
        description: Yup.string().max(150, 'Max 150 characters'),
        cta: Yup.string().max(20, 'Max 20 characters'),
        imageUrl: Yup.string(),
        ctaUrl: Yup.string().url()
    });

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
    });
    const { setValues, setFieldValue } = formik;


    const setImageUrl = (imageUrl) => {
        setFieldValue('imageUrl', imageUrl);
    };

    return (
        <div className={classes.root}>
            <div className="card shadow p-5">
                <h1>Create a Promotion Item</h1>
                 {/*<ImagesUpload />*/}
                <form className="needs-validation" onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="title">Title</label>
                            <input
                                name="title"
                                id="title"
                                value={formik.values.title}
                                placeholder="Enter the promotion title"
                                onChange={formik.handleChange}
                                className="form-control"
                            />
                            <ErrorMessage name="title" formik={formik} />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="description">Description</label>
                            <textarea
                                name="description"
                                id="description"
                                value={formik.values.description}
                                placeholder="Enter the promotion description"
                                onChange={formik.handleChange}
                                className="form-control"
                            />
                            <ErrorMessage name="description" formik={formik} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="cta">Cta (Optional)</label>
                            <input
                              name="cta"
                              id="cta"
                              value={formik.values.cta}
                              placeholder="Enter the cta"
                              onChange={formik.handleChange}
                              className="form-control"
                            />
                            <ErrorMessage name="cta" formik={formik} />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="ctaUrl">Cta Url</label>
                            <input
                              name="ctaUrl"
                              id="ctaUrl"
                              value={formik.values.ctaUrl}
                              placeholder="Enter the cta url"
                              onChange={formik.handleChange}
                              className="form-control"
                            />
                            <ErrorMessage name="ctaUrl" formik={formik} />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="ctaUrl">Background color:</label>
                            <div className="d-flex align-items-center">
                                <div className="d-flex f-1 form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      checked={currentSwitch === 'gradient'}
                                      onChange={(e) => {
                                          setCurrentSwitch('gradient');
                                      }}
                                    />
                                    <span className="mx-3">Gradient</span>
                                </div>
                                <div className="d-flex f-1 form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={currentSwitch === 'white'}
                                      onChange={(e) => setCurrentSwitch('white')}
                                    />
                                    <span className="mx-3">
                              White
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex row">
                        <ImageUpload
                          prevImg={prevImg}
                          setFieldValue={setImageUrl}
                          imgAlt={formik.values.title}
                          className="col-12 col-lg-6 order-lg-2 mb-4"
                          setUploadedImg={setUploadedImg}
                        >
                            <ErrorMessage name="imageUrl" formik={formik} />
                        </ImageUpload>
                    </div>

                    <div className="d-flex">
                        <button
                            className="btn btn-success mr-5"
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Create
                        </button>
                    </div>
                </form>
                <br></br>
                {promotion && promotion?.length > 0 && (
                    <div>
                        <h2>Recent Promotion item list</h2>
                        <PromotionItemList data={promotion} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PromotionItemCreate;
