import { communityActions } from '../actions/communityActions';

export const initialState = {
  list: [],
  current: null,
  errors: { listError: null, currentItemError: null },
  total: 0,
  isLoading: false,
  isCurrentLoading: false,
  perPage: 10,
  search: '',
  feeds: [],
  deletingFeed: 0,
  editFeed: {},
};

export const communityReducer = (state = initialState, { type, payload }) => {
  let list;
  switch (type) {
    case communityActions.COMMUNITIES_LOADING:
      return { ...state, isLoading: payload.isLoading };
    case communityActions.CURRENT_COMMUNITY_LOADING:
      return { ...state, isCurrentLoading: payload.isLoading };
    case communityActions.SET_CURRENT_COMMUNITY:
      return { ...state, current: payload.community };
    case communityActions.SET_COMMUNITIES:
      list = payload.isFirstTimeLoad
        ? payload.communities
        : [...state.list, ...payload.communities];
      return {
        ...state,
        list,
        total: payload.count,
      };
    case communityActions.ADD_COMMUNITY:
      list = [...state.list, payload.community];
      return { ...state, list };
    case communityActions.EDIT_COMMUNITY:
      const idx = state.list.findIndex(
        (comm) => comm.id === payload.community.id
      );
      list = [...state.list];
      list[idx] = payload.community;
      return { ...state, list };
    case communityActions.SET_COMMUNITIES_ERROR:
      return {
        ...state,
        errors: { ...state.errors, listError: payload.error },
      };
    case communityActions.SET_CURRENT_COMMUNITY_ERROR:
      return {
        ...state,
        errors: { ...state.errors, currentItemError: payload.error },
      };
    case communityActions.SET_SEARCH:
      return { ...state, search: payload };

    case communityActions.SET_FEED:
      return { ...state, feeds: payload }

    case communityActions.SET_DELETING_FEED:
      return { ...state, deletingFeed: payload }

    case communityActions.SET_EDIT_FEED:
      const index = state.feeds.findIndex(
        (feed) => feed.id.toString() === payload
      );
      return { ...state, editFeed: state.feeds[index] };

    case communityActions.ADD_FEED:
      payload.addedNow = true;
      return { ...state, feeds: [payload, ...state.feeds] }

    case communityActions.ADD_FEED_COMPLETED: {
      const feedArray = [...state.feeds]
      const feedId = feedArray.findIndex((item) => item.id === payload)
      const newFeedElement = { ...feedArray[feedId] }
      newFeedElement.addedNow = false
      feedArray[feedId] = newFeedElement
      return { ...state, feeds: feedArray }
    }

    case communityActions.REMOVE_FEED: {
      const feedArray = [...state.feeds]
      const filteredFeed = feedArray.filter((item) => item.id !== payload);
      return { ...state, feeds: filteredFeed }
    }

    default:
      return state;
  }
};
