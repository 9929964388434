import inflection from 'inflection';
import React from 'react';
import { metricDimensions } from '../../utils/constants';
import { getTimeParts } from '../../utils/helpers';

const MetricsRow = ({ metrics, dimension = metricDimensions.daily }) => {
  const { dimensionValues, metricValues } = metrics;
  const [
    activeUsers,
    conversions,
    bounceRate,
    retentionRate,
    useTime,
    sessionInterval,
    accountsCreated,
    sessionsAttended,
    sessionSharingUsers,
  ] = metricValues;
  const [primaryValue, year, month, day] = dimensionValues;
  let dateString = `${year.value}`;
  if (dimension === metricDimensions.daily)
    dateString += `-${month.value}-${day.value}`;
  else if (dimension === metricDimensions.weekly)
    dateString += `-${month.value} ${inflection.ordinalize(
      primaryValue.value
    )} week`;
  else if (dimension === metricDimensions.monthly)
    dateString += `-${primaryValue.value}`;
  const useTimeParts = useTime && getTimeParts(useTime.value);
  const sessionIntervalParts =
    sessionInterval && getTimeParts(sessionInterval.value);
  return (
    <tr>
      <th style={{ wordBreak: 'keep-all' }}>{dateString}</th>
      <td>{activeUsers.value}</td>
      <td>
        {conversions.value} {inflection.inflect('user', conversions.value)}
      </td>
      {bounceRate && <td>{(Number(bounceRate.value) * 100).toFixed(2)}%</td>}
      {retentionRate && (
        <td>{(Number(retentionRate.value) * 100).toFixed(2)}%</td>
      )}
      {useTime && <td>{useTimeParts.stringValue}</td>}
      {sessionInterval && <td>{sessionIntervalParts.stringValue}</td>}
      {accountsCreated && <td>{accountsCreated.value}</td>}
      {sessionsAttended && <td>{(+sessionsAttended.value).toFixed(2)}</td>}
      {sessionSharingUsers && <td>{sessionSharingUsers.value}</td>}
    </tr>
  );
};

export default MetricsRow;
