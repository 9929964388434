import { apiRequest } from '../../utils/requests';

const pageUrl = "/promocodes"

export const promotionItemActions = {
    SET_PROMOTION_DATA: 'SET_PROMOTION_DATA',
    SET_IS_LOADING: 'SET_IS_LOADING',
    // ADD_PROMOTION_ITEM: 'ADD_PROMOTION_ITEM',
    // ADD_PROMOTION_ITEM_COMPLETED: 'ADD_PROMOTION_ITEM_COMPLETED',
    DELETE_PROMOTION_ITEM: 'DELETE_PROMOTION_ITEM',
    // SET_DELETING_PROMOTION_ITEM: 'SET_DELETING_PROMOTION_ITEM',
}

export const setPromotionData = ({ promotion, count }) => {
    return { type: promotionItemActions.SET_PROMOTION_DATA, payload: { promotion, count } };
};

export const setIsLoading = (data) => {
    return { type: promotionItemActions.SET_IS_LOADING, payload: data };
};

export const setDeletePromotion = (data) => {
    return { type: promotionItemActions.DELETE_PROMOTION_ITEM, payload: data };
};

export const setPromotionPriorities = (priorities) => ({
    type: promotionItemActions.SET_PROMOTION_PRIORITIES,
    priorities,
});

export const listPromotionItems = (currentPage) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const endpoint = `${pageUrl}/all/promotion/item?mode=admin`;
        await dispatch(setIsLoading(true))
        try {
            const { data } = await apiRequest(
              'get',
              endpoint,
              {
                  token: auth.user.token,
              }
            );
            const { promotion, count } = data;
            await dispatch(setPromotionData({ promotion, count }))
            await dispatch(setIsLoading(false))
        } catch (e) {
            await dispatch(setIsLoading(false))
        }
    }
}

export const createPromotionItem = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/add/promotion/item`;
            const { data } = await apiRequest(
                'post',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { created } = data;
            if (created) {
                await dispatch(listPromotionItems())
            }
            return created;
        }
    }
}

export const deletePromotionItem = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/delete/promotion/${value}`;
            const {data} = await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            if(data?.deleted) {
                await dispatch(listPromotionItems())
            }
        }
    }
}

export const showOrHidePromotion = (id, isVisible) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/promotion/item/${id}`;
            const { data } = await apiRequest(
                'post',
                endpoint,
                {
                    formData: {id, isVisible},
                    token: auth.user.token
                }
            );
            const { updated } = data
            if(updated){
                await dispatch(listPromotionItems())
            }
        }
    }
}

export const upAnDownPriority = (id, priority) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/promotion/item/priority/${id}`;
            const { data } = await apiRequest(
                'put',
                endpoint,
                {
                    formData: {id, priority},
                    token: auth.user.token
                }
            );
            const { updated } = data
            if(updated){
                await dispatch(listPromotionItems())
            }
        }
    }
}
