import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import InterestTagCreate from './InterestTagCreate';
import InterestTagEdit from './InterestTagEdit';

const InterestTags = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
      async function fetchData() {
        auth.user.token && await dispatch(setMainContentLoading(false));
      }

      fetchData();
    }, [auth.user.token, dispatch]
  );

  return (
    <div>
      <CpPage ListPage={InterestTagCreate} EditPage={InterestTagEdit} />
    </div>
  );
};

export default InterestTags;
