import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CpPage = ({ ListPage, CreatePage, EditPage }) => {
  const { path } = useRouteMatch();
  const isLoading = useSelector((state) => state.layout.isMainContentLoading);

  if (isLoading)
    return (
      <div className="fa-3x d-flex justify-content-center">
        <i className="fas fa-spinner fa-pulse"></i>
      </div>
    );
  return (
    <Switch>
      <Route
        path={[ path + '/:id/edit']}
        render={(routeParams) => <EditPage {...routeParams} />}
      />
      <Route
        path={[path + '/create', path + '/:id']}
        render={(routeParams) => <CreatePage {...routeParams} />}
      />
      <Route
        path={path}
        render={(routeParams) => <ListPage {...routeParams} />}
      />
    </Switch>
  );
};

export default CpPage;
