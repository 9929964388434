import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import _ from 'lodash';
import queryString from 'query-string';

const Paginate = ({
  perPage = 10,
  totalItems,
  currentPage,
  handleClick,
  pageButtons = 7,
  pageUrl,
  search = ''
}) => {
  const history = useHistory();
  const [prevPage, setPrevPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [pageItems, setPageItems] = useState([]);
  const totalPages = useMemo(() => parseInt(Math.ceil(totalItems / perPage)), [
    totalItems,
    perPage,
  ]);

  useEffect(() => {
    const prevPage = Math.max(currentPage - 1, 1);
    setPrevPage(prevPage);
    const nextPage = Math.min(currentPage + 1, totalPages);
    setNextPage(nextPage);

    const sideCount = parseInt(pageButtons / 2);
    let startCount = currentPage - sideCount;
    let endCount = currentPage + sideCount;
    let remainingBefore = Math.abs(Math.min(startCount - 1, 0));
    let remainingAfter = Math.abs(Math.min(totalPages - endCount, 0));
    let startPage = Math.max(startCount - remainingAfter, 1);
    let endPage = Math.min(endCount + remainingBefore, totalPages);

    const pageItems = _.range(startPage, endPage + 1);
    setPageItems(pageItems);
  }, [totalPages, currentPage, pageButtons]);

  const pageUrlString = (currentPage) => {
    let { url, query } = queryString.parseUrl(pageUrl);
    query = { ...query, page: currentPage };
    if (search) {
      query['search'] = search
    }
    return queryString.stringifyUrl({ url, query });
  };

  const handlePageClick = (pageNum) => {
    pageUrl ? history.push(pageUrlString(pageNum)) : handleClick(pageNum);
  };

  return (
    <div>
      <Pagination>
        {totalPages > pageButtons && (
          <Pagination.Item
            disabled={currentPage === 1}
            onClick={() => handlePageClick(1)}
          >
            1
          </Pagination.Item>
        )}
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageClick(prevPage)}
        />
        {pageItems.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageClick(nextPage)}
          disabled={currentPage === totalPages}
        />
        {totalPages > pageButtons && (
          <Pagination.Item
            onClick={() => handlePageClick(totalPages)}
            disabled={currentPage === totalPages}
          >
            {totalPages}
          </Pagination.Item>
        )}
      </Pagination>
    </div>
  );
};

export default Paginate;
