import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import Paginate from '../Utilities/Paginate';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import Alert from '@material-ui/lab/Alert';
import { deleteUser, searchUsersList, setUserAmbassadorChange, setUserDelete, setUserSearchKey, setUsersList } from '../../store/actions/usersListActions';

const usersHeaders = [
  'S.No.',
  'Name',
  'email',
  'Phone',
  'Username',
  'Ambassador'
];
const pageUrl = '/panel/users';

const UsersList = () => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.usersList.usersList);
  const isLoading = useSelector((state) => state.usersList.userLoading);
  const isUserDeleteLoading = useSelector((state) => state.usersList.deleteUserLoading);
  const totalItems = useSelector((state) => state.usersList.total);
  const perPage = useSelector((state) => state.usersList.perPage);
  const search = useSelector((state) => state.usersList.search);
  const deletingUser = useSelector((state) => state.usersList.deletingUser);
  const deleteError = useSelector((state) => state.usersList.errors.deleteError);

  const [show, setShow] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const setUserToDelete = async (data) => {
    await dispatch(setUserDelete(data))
  };

  const startIndex = (currentPage - 1) * perPage;

  const handleUserDelete = async (data) => {
    setShow(false)
    await dispatch(deleteUser(data?.id));
    if(search.length > 0) {
      await dispatch(searchUsersList(currentPage))
    }else{
      await dispatch(setUsersList(currentPage))
    }
  }

  const handleSearch = (e) => {
    dispatch(setUserSearchKey(e.target.value));
    if(e.target.value === '') {
        setPageUrl(1)
        dispatch(setUsersList(1));
    }
  };

  const clearSearch = () => {
    setPageUrl(1)
    dispatch(setUserSearchKey(''));
    dispatch(setUsersList(1));
  }

  const setPageUrl = (currentPage) => {
    let { url, query } = queryString.parseUrl(pageUrl);
    query = { ...query, page: currentPage };
    setCurrentPage(currentPage)
    history.push(queryString.stringifyUrl({ url, query }))
  };

  useEffect(() => {
    if (auth.user.token && currentPage) {
      if(search.length > 0) {
        dispatch(searchUsersList(currentPage))
      }else{
        dispatch(setUsersList(currentPage))
      }
    }
  }, [auth.user.token, currentPage, dispatch, search.length]);

  const searchUser = () => {
    setPageUrl(1)
    dispatch(searchUsersList(1))
  }

  const usersListRow = (user, i) => {
    const starClassnames = `fas edit fa-star ${user.IsAmbassador ? 'text-danger' : 'text-blue'}`
    return (
      <tr key={user.id} >
        <td>{startIndex + 1 + i}</td>
        <td>{(user?.FirstName || "") + " " + (user?.LastName || "")}</td>
        <td>{user.Email}</td>
        <td>{user.Phone}</td>
        <td>{user.username}</td>
        <td className="text-center">
          {user.IsAmbassador ? "⭐" : ''}
        </td>
        <td>
          {isUserDeleteLoading && user.id === deletingUser.id ? (
            <i className="fas fa-spinner edit fa-pulse ml-3"></i>
          ) : (
            <>
              <span
                onClick={() => dispatch(setUserAmbassadorChange(user.id))}
                className="ml-4"
              >
                <i className={starClassnames} />
              </span>
              <span
                onClick={() => {
                  setShow(true)
                  setUserToDelete(user)
                }}
                className="remove ml-4"
              >
              <i className="fas fa-trash text-danger"></i>
            </span>
            </>
          )}
        </td>
      </tr>
    );
  }

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex align-items-center">
          <h1>Users</h1>
          <div className="input-group search-field mx-3 w-auto flex-fill align-items-center">
            <input 
                className="form-control py-2 border" 
                placeholder="Search" 
                name="search"
                id="example-search-input"
                value={search}
                onChange={handleSearch}
                onKeyPress={(e) => {
                  if(e.key === "Enter"){
                    searchUser()
                  }
                }}
            />
            <span className="input-group-append">
                {
                    search?.length !== 0 && 
                    <button className="btn btn-outline-secondary border-left-0 border" type="button" onClick={() => clearSearch()}>
                        <i className="fa fa-times"></i>
                    </button>
                }
            </span>
            <button
                className={'input-group-append btn'}
                onClick={() => searchUser()}
            >
                <i className="fa fa-search input-group-text font-weight-bold"></i>
            </button>
          </div>
        </div>
      </div>
      {
        deleteError &&
        <div>
          <Alert severity="error">
            Something went wrong while deleting user!
          </Alert>
          <br></br>
        </div>
      }
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deletingUser}
              handleDelete={handleUserDelete}
              closePopup={() => setShow(false)}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {usersHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {users && users.length > 0 && (
                <tbody>
                  {users.map(usersListRow)}
                </tbody>
              )}
            </Table>
            <Paginate
              totalItems={totalItems}
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UsersList;
