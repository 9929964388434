import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import TagsCreate from './TagsCreate';
import TagsEdit from './TagsEdit';

const Interest = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchTagComponent = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchTagComponent();
  }, [fetchTagComponent]);
  return (
    <div>
      <CpPage ListPage={TagsCreate} EditPage={TagsEdit} />
    </div>
  );
};

export default Interest;
