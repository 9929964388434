import React from 'react';

const ErrorMessage = ({
  formik,
  name,
  style,
  className,
  displayDiv = false,
}) => {
  const errorPresent = formik.touched[name] && formik.errors[name];
  if (errorPresent || displayDiv)
    return (
      <div className={'invalid-feedback ' + className} style={style}>
        {errorPresent && formik.errors[name]}
      </div>
    );
  else return null;
};

export default ErrorMessage;
