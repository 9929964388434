import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmationPopup = ({
  show,
  data,
  handleDelete,
  closePopup = null,
  message,
  report = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    closePopup && closePopup()
    setOpen(false);
  }

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Modal
      dialogClassName="primary"
      show={open}
      onHide={handleClose}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      {!report ? (
        <Modal.Body>
          Are you sure you want to delete {data?.message}?
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div> {message.content}</div>
          {message.block && (
            <div>
              <br></br>
              <p className="font-weight-bold text-danger">
                (Note: once removed can not be undone!)
              </p>
            </div>
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleClose();
            handleDelete(data);
          }}
        >
          Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationPopup;
