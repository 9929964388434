import React, { useCallback  } from 'react';
import moment from 'moment';
import { DateTime, Interval } from 'luxon';
import { redirectLinkToDevice } from '../RedirectLinkToDevice';
import diemLogo from '../../../assets/images/DiemLogo.png';
import { ReactComponent as LiveSvg } from '../../../assets/images/waveLive.svg';
import { isMobile } from 'react-device-detect';
import './RedirectToApp.css';

const size = 2;
export function SessionCard({ currentData }) {
  let cohosts = currentData?.users?.filter(
    (user) =>
      user?.sessionuser?.isCohost === true && currentData?.host?.id !== user?.id
  );

  const sessionStartedStatus = useCallback(
    (
      startTime,
      endTime,
      { sessionCompletedFn, sessionStartedFn, upcomingSessionFn } = {}
    ) => {
      let todayDate = DateTime.local();
      let endedTime = DateTime.fromISO(endTime, {
        zone: 'local',
      });

      // Calculate If Session has started
      let startTimes = DateTime.fromISO(startTime, {
        zone: 'local',
      });
      let todayDatePlusOne = DateTime.local().plus({ minutes: 1 });
      const hasSessionStarted = Interval.fromDateTimes(
        startTimes,
        endedTime
      ).contains(todayDatePlusOne);
      let betweeenTwoHours = false;
      let sessionStatusCard = 'Upcoming Session';

      let result = endedTime
        .diff(todayDate, ['months', 'days', 'hours'])
        .toObject();
      let isGreater = Math.sign(result.hours);
      if (
        result.days <= 0 &&
        isGreater === -1 &&
        (result.hours <= -2 || result.days <= 0)
      ) {
        sessionStatusCard = 'Session Completed';
        if (sessionCompletedFn) sessionCompletedFn();
        if (result.hours >= -2 && result.hours <= -0 && !(result.days < 0)) {
          betweeenTwoHours = true;
        }
      } else if (hasSessionStarted) {
        sessionStatusCard = 'Live';
        if (sessionStartedFn) sessionStartedFn();
      } else if (result.days >= 0 && isGreater === 1) {
        sessionStatusCard = 'Upcoming Session';
        if (upcomingSessionFn) upcomingSessionFn();
      }
      return sessionStatusCard;
    },
    []
  );

  const CardLogo = () => {
    switch (
      sessionStartedStatus(currentData?.startTime, currentData?.endTime)
    ) {
      case 'Upcoming Session':
        return (
          <h4 className="cardDate">
            {moment(currentData?.startTime).format('MMM Do, hh:mm a')}
          </h4>
        );
      case 'Live':
        return (
          <div className="liveSessionCont">
            <div className="dot" />
            <text className="liveSessionText">REC</text>
            <LiveSvg />
          </div>
        );
      case 'Session Completed':
        return <LiveSvg />;
      default:
        return null;
    }
  };

  return (
    <div className="main d-flex align-items-center diem-blue-gradient">
      <div
        className="d-flex flex-column sessionCardCont"
        style={{
          height: isMobile ? '95%' : '90%',
          maxWidth: isMobile ? '100%' : '450px',
        }}
      >
        <div className="d-flex flex-column mainLogoCont">
          <img alt="" src={diemLogo} className="diemLogo" />
          <text className="logoText">
            {
              'The universe to curate your knowledge & discover the wisdom of others'
            }
          </text>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="sessionCard cardColor text-white">
            <div className="d-flex flex-column p-3">
              <div
                className="w-100 align-items-center d-flex justify-content-between"
                style={{
                  flexWrap: 'revert',
                }}
              >
                <div className="d-flex flex-row">
                  <div className="d-flex">
                    <div>
                      {currentData?.host?.PhotoUrl ? (
                        <img
                          alt=""
                          src={currentData?.host?.PhotoUrl}
                          className="dateAvatar imgBordered"
                        />
                      ) : (
                        <div className="nonImageAvatar">
                          {`${currentData?.host?.FirstName.charAt(
                            0
                          )}${currentData?.host?.FirstName.charAt(1)}`}
                        </div>
                      )}
                    </div>
                    <div
                      className="dateAvTxt"
                      {...(isMobile &&
                        cohosts &&
                        cohosts?.length !== 0 && {
                          style: {
                            maxWidth:
                              sessionStartedStatus(
                                currentData?.startTime,
                                currentData?.endTime
                              ) === 'Upcoming Session'
                                ? '60px'
                                : '75px',
                          },
                        })}
                    >
                      {`@${currentData?.host?.username}`}
                    </div>
                  </div>
                  {cohosts && cohosts?.length !== 0 && cohosts[0] && (
                    <div className="d-flex">
                      <text className="postText">&nbsp;&&nbsp;</text>
                      <div>
                        {cohosts[0]?.PhotoUrl ? (
                          <img
                            alt=""
                            src={cohosts[0]?.PhotoUrl}
                            className="dateAvatar imgBordered"
                          />
                        ) : (
                          <div className="nonImageAvatar">
                            {`${cohosts[0]?.FirstName.charAt(
                              0
                            )}${cohosts[0]?.FirstName.charAt(1)}`}
                          </div>
                        )}
                      </div>
                      <div
                        className="dateAvTxt"
                        {...(isMobile && {
                          style: {
                            maxWidth:
                              sessionStartedStatus(
                                currentData?.startTime,
                                currentData?.endTime
                              ) === 'Upcoming Session'
                                ? '60px'
                                : '75px',
                          },
                        })}
                      >
                        {`@${cohosts[0]?.username}`}
                      </div>
                    </div>
                  )}
                </div>
                {/* <h6 className="mb-4 blockTitle">{currentData?.name}</h6> */}
                <div>
                  <CardLogo />
                </div>
              </div>
              <div>
                <h6 className="mb-2 mt-1 blockTitle">{currentData?.name}</h6>
                <text className="sessionDesc">{currentData?.description}</text>
              </div>
              <div className="countImage d-flex align-items-center userImgCont mt-2 mb-2">
                {currentData?.users?.length <= size ? (
                  <span></span>
                ) : (
                  <span className="CountInfo">
                    +{currentData?.users?.length - size}{' '}
                    {sessionStartedStatus(
                      currentData?.startTime,
                      currentData?.endTime
                    ) === 'Live' ||
                    sessionStartedStatus(
                      currentData?.startTime,
                      currentData?.endTime
                    ) === 'Upcoming Session'
                      ? 'attending'
                      : 'attended'}
                  </span>
                )}
              </div>
              <div className="d-flex flex-row mt-2 justify-content-between">
                <div></div>
                <div className="m-1 subCatText">{currentData?.topicName}</div>
              </div>
            </div>
          </div>
          <button onClick={() => redirectLinkToDevice()} className="openBtn">
            Listen in the Diem app
          </button>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="mb-3 blockTitle-1">Don't have an account in Diem?</p>
            <div className="d-flex align-items-center">
              <div className="playImg mr-2">
                <a href="https://apps.apple.com/in/app/diem-womxns-social-platform/id1524855792">
                  <img alt="" src="./icons/app.png" className="w-100" />
                </a>
              </div>
              <div className="playImg">
                <a href="https://play.google.com/store/apps/details?id=com.askdiem.app&hl=en">
                  <img alt="" src="./icons/play.png" className="w-100" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
