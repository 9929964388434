import { apiRequest, getErrorMsg } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = '/channels';

export const channelActions = {
  SET_CHANNELS: 'SET_CHANNELS',
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL',
  ADD_CHANNEL: 'ADD_CHANNEL',
  EDIT_CHANNEL: 'EDIT_CHANNEL',
  CHANNELS_LOADING: 'CHANNELS_LOADING',
  CURRENT_CHANNEL_LOADING: 'CURRENT_CHANNEL_LOADING',
  SET_CHANNELS_ERROR: 'SET_CHANNELS_ERROR',
  SET_CURRENT_CHANNEL_ERROR: 'SET_CURRENT_CHANNEL_ERROR',
  SET_CHANNEL_ADD_ERROR: 'SET_CHANNEL_ADD_ERROR',
  REMOVE_CHANNEL: 'REMOVE_CHANNEL',
  SET_DELETING_CHANNEL: 'SET_DELETING_CHANNEL',
  SET_SEARCH: 'CHANNELS:SET_SEARCH',
};

export const setLoading = (isLoading) => {
  return { type: channelActions.CHANNELS_LOADING, payload: { isLoading } };
};

export const setCurrentLoading = (isLoading) => {
  return {
    type: channelActions.CURRENT_CHANNEL_LOADING,
    payload: { isLoading },
  };
};

export const setChannels = ({ channels, count, isFirstTimeLoad = true }) => {
  return {
    type: channelActions.SET_CHANNELS,
    payload: { channels, count, isFirstTimeLoad },
  };
};

export const setChannel = ({ channel }) => {
  return {
    type: channelActions.SET_CURRENT_CHANNEL,
    payload: { channel },
  };
};

export const setChannelsError = (error) => {
  return {
    type: channelActions.SET_CHANNELS_ERROR,
    payload: { error },
  };
};

export const setcurrentItemError = (error) => {
  return {
    type: channelActions.SET_CURRENT_CHANNEL_ERROR,
    payload: { error },
  };
};

export const setChannelsAction = (currentPage) => {
  return async (dispatch, getState) => {
    try {
      const { channels, auth } = getState();
      const { list, perPage } = channels;
      const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
        list,
        currentPage,
        perPage,
      });
      if (shouldFetch) {
        await dispatch(setLoading(true));
        const response = await apiRequest(
          'get',
          `${pageUrl}?private=1&inactive=true`,
          {
            token: auth.user.token,
            params,
          }
        );
        const { channels, count } = response.data;
        await dispatch(setChannels({ channels, count, isFirstTimeLoad }));
        await dispatch(setChannelsError(null));
      }
    } catch (err) {
      await dispatch(setChannelsError(getErrorMsg(err)));
    }
    await dispatch(setLoading(false));
  };
};

export const setChannelAction = (id) => {
  return async (dispatch, getState) => {
    await dispatch(setCurrentLoading(true));
    try {
      const { channels, auth } = getState();
      const { list } = channels;
      let channel = list.find((comm) => comm.id === id);
      if (!channel && auth.user.token) {
        const response = await apiRequest('get', `${pageUrl}/${id}`, {
          token: auth.user.token,
        });
        channel = response.data.channel;
      }
      await dispatch(setChannel({ channel }));
      await dispatch(setcurrentItemError(null));
    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
    }
    await dispatch(setCurrentLoading(false));
  };
};

export const addChannel = ({ channel }) => {
  return { type: channelActions.ADD_CHANNEL, payload: { channel } };
};

export const editChannel = ({ channel }) => {
  return { type: channelActions.EDIT_CHANNEL, payload: { channel } };
};

export const removeChannel = ({ channel }) => {
  return { type: channelActions.REMOVE_CHANNEL, payload: { channel } };
};

export const setDeletingChannel = ({ id }) => {
  return { type: channelActions.SET_DELETING_CHANNEL, payload: { id } };
};

export const addEditChannelAction = ({
  values,
  isCreatePage,
  onSuccess,
  onError,
}) => {
  return async (dispatch, getState) => {
    try {
      const { channels, auth } = getState();
      const { list } = channels;
      const method = isCreatePage ? 'post' : 'patch';
      const endpoint = isCreatePage ? pageUrl : `${pageUrl}/${values.id}`;
      const dispatchAction = isCreatePage ? addChannel : editChannel;
      const { data } = await apiRequest(method, endpoint, {
        formData: values,
        token: auth.user.token,
      });
      const { channel } = data;
      await dispatch(setcurrentItemError(null));
      if (channel) {
        if (list.length === 0) await dispatch(setChannelsAction());
        await dispatch(dispatchAction({ channel }));
      }
      if (onSuccess) await onSuccess(channel);
    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
      if (onError) await onError(err);
    }
  };
};

export const removeChannelAction = (id) => {
  return async (dispatch, getState) => {
    await dispatch(setDeletingChannel({ id }));
    try {
      const { auth } = getState();
      const { data } = await apiRequest('delete', `${pageUrl}/${id}`, {
        token: auth.user.token,
      });
      const { channel } = data;
      await dispatch(setcurrentItemError(null));
      if (channel) await dispatch(removeChannel({ channel }));
    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
    }
    await dispatch(setDeletingChannel({ id: null }));
  };
};

export const setChannelSearch = (search) => {
  return { type: channelActions.SET_SEARCH, payload: search };
};
