import React, {useCallback, useEffect} from 'react';
import CpPage from "../PageLayouts/CpPage";
import PromotionItemCreate from "./PromotionItemCreate";
import {useDispatch, useSelector} from "react-redux";
import {setMainContentLoading} from "../../store/actions/layoutActions";

const PromotionItem = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  return(
    <div>
      <CpPage ListPage={PromotionItemCreate}  />
    </div>
  )
}

export default PromotionItem