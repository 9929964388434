import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCommunitiesAction } from '../../store/actions/communityActions';
import { setKeywordsAction } from '../../store/actions/keywordAction';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import CommunityList from './CommunityList';
import CommunityTab from './CommunityTab';

const Communities = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchCommunities = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setCommunitiesAction(1));
      await dispatch(setKeywordsAction());
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchCommunities();
  }, [fetchCommunities]);
  return (
    <div>
      <CpPage ListPage={CommunityList} CreatePage={CommunityTab} />
    </div>
  );
};

export default Communities;
