import DailyMetrics from '../components/Metrics/DailyMetrics';
import MonthlyMetrics from '../components/Metrics/MonthlyMetrics';
import WeeklyMetrics from '../components/Metrics/WeeklyMetrics';

export const routes = [
  {
    path: '/daily',
    component: DailyMetrics,
  },
  {
    path: '/weekly',
    component: WeeklyMetrics,
  },
  {
    path: '/monthly',
    component: MonthlyMetrics,
  },
  {
    path: '/',
    exact: true,
    component: DailyMetrics,
  },
];
