import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from 'react-bootstrap/Table';
import {deleteInterestTag} from '../../store/actions/interestTagAction';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import InterestTagRow from './InterestTagRow';

const interestTagHeaders = [
  'S.No.',
  'ID',
  'Tag Name',
  'Created Manually',
  'Post Count',
  'Created',
  'Interests',
  'Actions'
];

const InterestTagsTable = ({interestTags, startIndex}) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const handleDelete = async (params) => {
      const {id} = params;
      if (auth.user.token) {
        await dispatch(deleteInterestTag(id));
      }
    };

    const [show, setShow] = useState(false);
    const [deleteData, setDeleteData] = useState();

    const interestTagRow = (interestTag, i) => (
      <InterestTagRow
        interestTag={interestTag}
        idx={i}
        startIndex={startIndex}
        onDeleteClick={onDeleteClick}
        key={`row-${interestTag?.id}-${i}`}
      />
    )

    const onDeleteClick = (tag) => {
      setDeleteData({id: tag.id, message: tag.tagName});
      setShow(!show);
    }



    return (
      <>
        <Table striped bordered hover>
          <thead>
          <tr>
            {interestTagHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
          </thead>
          {interestTags && interestTags.length > 0 && (
            <tbody>{interestTags.map(interestTagRow)}</tbody>
          )}
        </Table>
        <ConfirmationPopup
          show={show}
          data={deleteData}
          handleDelete={handleDelete}
        />
      </>
    )
  }
;

export default InterestTagsTable;