import { promoActions } from "../actions/promoActions";

const initialState = {
    promoData: [],
    isLoading: false,
    deletingCode: 0,
    alreadyExist: false,
    editData: {},
    total: 0,
    perPage: 5
}

export const promoReducer = (state = initialState, { type, payload }) => {
    let promoData;
    switch (type) {
        case promoActions.SET_PROMO_DATA:
            promoData = payload.isFirstTimeLoad ? payload.promoCodes : [...state.promoData, ...payload.promoCodes]
            return { ...state, promoData, total: payload.count }

        case promoActions.SET_ALREADY_EXIST:
            return { ...state, alreadyExist: payload }
        case promoActions.SET_IS_LOADING:
            return { ...state, isLoading: payload }

        case promoActions.SET_DELETING_PROMOCODE:
            return { ...state, deletingCode: payload }

        case promoActions.ADD_PROMO_CODE:
            payload.addedNow = true
            return { ...state, promoData: [payload, ...state.promoData], total: state.total + 1 }

        case promoActions.DELETE_PROMO_CODE:
            const promoArray = [...state.promoData]
            const updatedPromoData = promoArray.filter((item) => item.id !== payload);
            return { ...state, promoData: updatedPromoData, total: state.total - 1 }

        case promoActions.SET_EDIT_PROMO_CODE:
            const index = state.promoData.findIndex(
                (item) => item.id.toString() === payload
            );
            return { ...state, editData: state.promoData[index] };

        case promoActions.UPDATE_PROMO_CODE_LIST: {
            const data = [...state.promoData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) {
                data[index] = payload
            }
            return { ...state, promoData: data }
        }

        case promoActions.ADD_PROMO_CODE_COMPLETED: {
            const data = [...state.promoData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) data[index].addedNow = false;
            return { ...state, promoData: data }
        }

        case promoActions.CHECK_ALREADY_EXIST: {
            const data = [...state.promoData]
            const index = data.findIndex((item) => item.code === payload.code)
            if (index < 0) return { ...state, alreadyExist: false }
            else return { ...state, alreadyExist: true }
        }

        default:
            return state;
    }
};