import { layoutActions } from '../actions/layoutActions';

export const initialState = {
  isMainContentLoading: true,
};

export const layoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case layoutActions.MAIN_CONTENT_LOADING:
      return { ...state, isMainContentLoading: payload.isLoading };
    default:
      return state;
  }
};
