import { savedSessionActions } from '../actions/savedSessionAction';

export const initialState = {
  sessionList: [],
  errors: { listError: null, deleteError: null },
  total: 0,
  isLoading: false,
  perPage: 10,
  deletingSession: null,
  isDeleteSessionLoading: false,
  search: '',
};

export const savedSessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case savedSessionActions.SET_SESSIONS:
      return {
        ...state,
        sessionList: payload.sessions,
        total: payload.count,
      };
    case savedSessionActions.SESSIONS_LOADING:
      return { ...state, isLoading: payload };
    
    default:
      return state;
  }
};
