import { apiRequest } from "../../utils/requests";
import { getPagingParams } from "../../utils/store-helper";

export const userListActions = {
    SET_USERS_LIST: 'SET_USERS_LIST',
    USERS_LIST_LOADING: 'USERS_LIST_LOADING',
    SET_USERS_LIST_ERROR: 'SET_USERS_LIST_ERROR',
    REMOVE_USERS: 'REMOVE_USERS',
    SET_DELETING_USERS: 'SET_DELETING_USERS',
    DELETE_USER: 'DELETE_USER',
    DELETE_USER_LOADING: 'DELETE_USER_LOADING',
    SET_SEARCH_USER: 'USERS:SET_SEARCH_USER',
    CLEAR_USER_LIST: 'CLEAR_USER_LIST',
    SET_USER_DELETE_ERROR: 'SET_USER_DELETE_ERROR',
    UPDATE_USER_LIST: 'UPDATE_USER_LIST',
};

export const setUsersListData = ({ rows, count, isFirstTimeLoad, search }) => {
    return {
        type: userListActions.SET_USERS_LIST,
        payload: { rows, count, isFirstTimeLoad, search }
    }
}
export const updateUserList = (user) => {
  return {type: userListActions.UPDATE_USER_LIST, payload: user}
}

export const setUserDelete = (data) => {
    return {
        type: userListActions.SET_DELETING_USERS,
        payload: data
    }
}

export const deleteUserData = (data) => {
    return {
        type: userListActions.DELETE_USER,
        payload: data
    }
}

export const setUserSearchKey = (data) => {
    return {
        type: userListActions.SET_SEARCH_USER,
        payload: data
    }
}

export const clearUserList = () => {
    return {
        type: userListActions.CLEAR_USER_LIST,
        payload: []
    }
}

export const setUserLoader = (data) => {
    return {
        type: userListActions.USERS_LIST_LOADING,
        payload: data
    }
}

export const setUserDeleteLoader = (data) => {
    return {
        type: userListActions.DELETE_USER_LOADING,
        payload: data
    }
}

export const setUserDeleteError = (data) => {
    return {
        type: userListActions.SET_USER_DELETE_ERROR,
        payload: data
    }
}

export const setUsersList = (currentPage) => {
    return async (dispatch, getState) => {
        await dispatch(clearUserList());
        await dispatch(setUserLoader(true))
        const { auth } = getState();
        const apiUrl = '/main/users/list'
        try {
            const { data } = await apiRequest(
                'get',
                `${apiUrl}?orderBy=desc&exclude=true`,
                {
                    params: {
                        offset: currentPage * 10 - 10,
                        perPage: 10
                    },
                    token: auth.user.token
                }
            )
            const { listData } = data;
            const { rows, count} = listData;
            await dispatch(setUsersListData({ rows, count, isFirstTimeLoad: false, search: false }));
            await dispatch(setUserLoader(false))
        } catch (err) {
            console.log(err)
            await dispatch(setUserLoader(false))
        }
    }
}

export const searchUsersList = (currentPage) => {
    return async (dispatch, getState) => {
        await dispatch(clearUserList());
        await dispatch(setUserLoader(true))
        const { auth, usersList } = getState();
        let flag = true;
        const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
            list: [],
            currentPage,
            perPage: usersList?.perPage,
            flag
        });
        if(shouldFetch) {
            const apiUrl = '/search/records'
            try {
                const { data } = await apiRequest(
                    'get',
                    `${apiUrl}?mode=users&searchText=${usersList?.search}`,
                    {
                        params: params,
                        token: auth.user.token
                    }
                )
                const { users, count } = data;
                await dispatch(setUsersListData({ rows: users, count, isFirstTimeLoad, search: true }));
                await dispatch(setUserLoader(false))
            } catch (err) {
                console.log(err)
                await dispatch(setUserLoader(false))
            }
        }
    }
}

export const setUserAmbassadorChange = (id) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        await dispatch(setUserLoader(true))
        const apiUrl = `/main/users/${id}/ambassadorate`
        try {
            const { data } = await apiRequest('patch',`${apiUrl}`, {
                    token: auth.user.token
                })
            await dispatch(updateUserList(data))
            await dispatch(setUserLoader(false))
        } catch (err) {
            console.log(err)
            await dispatch(setUserLoader(false))
        }
    }
}

export const deleteUser = (id) => {
    return async (dispatch, getState) => {
        await dispatch(setUserDeleteLoader(true))
        const { auth } = getState();
        const apiUrl = `/users/${id}/delete?hardDelete=1`
        try {
            const { data } = await apiRequest(
                'get',
                apiUrl,
                {
                    token: auth.user.token
                }
            )
            if(data) {
                await dispatch(deleteUserData(id));
                await dispatch(setUserDeleteLoader(false))
                await dispatch(setUserDeleteError(false))
            }
        } catch (error) {
            await dispatch(setUserDeleteLoader(false))
            await dispatch(setUserDeleteError(true))
            setTimeout(async () => {
                await dispatch(setUserDeleteError(false))
            }, 3000);

        }
    }
}