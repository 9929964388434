import Category from '../components/Categories/Category';
import Channels from '../components/Channels/Channels';
import Communities from '../components/Communities/Communities';
import Ethnic from '../components/Community/Ethnic';
import DiemMetrics from '../components/DiemMetrics/DiemMetrics';
import SocketTest from '../components/Extras/SocketTest';
import Interest from '../components/Interests/Interests';
import Tags from '../components/Tags/Tags';
import Home from '../components/Main/Home';
import Metrics from '../components/Metrics/Metrics';
import Promotions from '../components/PromotionalCodes/Promotions';
import InterestTags from '../components/InterestTags/InterestTags';
import SavedSessions from '../components/SavedSessions/SavedSessions';
import { SearchKeywords } from '../components/SearchKeywords/SearchKeywords';
import Sessions from '../components/Sessions/Sessions';
import Users from '../components/Users/Users';
import { WebReports } from '../components/WebReports/WebReports';
import PromotionItem from "../components/PromotionItem/PromotionItem";

export const routes = [
  {
    path: '/panel/communities',
    component: Communities,
  },
  {
    path: '/panel/channels',
    component: Channels,
  },
  {
    path: '/panel/sockettest',
    component: SocketTest,
  },
  {
    path: '/panel/metrics',
    component: Metrics,
  },
  {
    path: '/panel/promotional-codes',
    component: Promotions,
  },
  {
    path: '/panel/categories',
    component: Category,
  },
  {
    path: '/panel/interests',
    component: Interest,
  },
  {
    path: '/panel/tags',
    component: Tags,
  },
  {
    path: '/panel/interest-tags',
    component: InterestTags,
  },
  {
    path: '/panel/sessions',
    component: Sessions,
  },
  {
    path: '/panel/users',
    component: Users,
  },
  {
    path: '/panel/savedsessions',
    component: SavedSessions,
  },
  {
    path: '/panel/community',
    component: Ethnic,
  },
  {
    path: '/panel/diem-metrics',
    component: DiemMetrics,
  },
  {
    path: '/panel/most-used-keywords',
    component: SearchKeywords,
  },
  {
    path: '/panel/web-reports',
    component: WebReports,
  },
  {
    path: '/panel/promotion-items',
    component: PromotionItem,
  },
  {
    path: '/panel',
    component: Home,
  },
  {
    path: '/',
    exact: true,
    component: () => {
      window.location.href = 'https://askdiem.com/';
      return null;
    },
  },
];
