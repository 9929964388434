import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import queryString from 'query-string';
import moment from 'moment';
import Paginate from '../Utilities/Paginate';
import {
  setSessionsList,
} from '../../store/actions/savedSessionAction';

const sessionHeaders = [
  'S.No.',
  'Session Name',
  'Session Start Time',
  'Listener (Total Count)',
  'Listener (Unique Count)'
];
const pageUrl = '/panel/savedsessions';

const SavedSessionsList = () => {
  const auth = useSelector((state) => state.auth);
  const sessions = useSelector((state) => state.savedSessions.sessionList);
  const isLoading = useSelector((state) => state.savedSessions.isLoading);
  const totalItems = useSelector((state) => state.savedSessions.total);
  const perPage = useSelector((state) => state.savedSessions.perPage);

  const location = useLocation();
  const dispatch = useDispatch();

  const initialDateVal = {
    startDate: new Date('01-01-2021'),
    endDate: new Date(),
    key: 'selection',
  };
  const [selectionRange, setSelectionRange] = useState(initialDateVal);

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const startIndex = (currentPage - 1) * perPage;

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(setSessionsList(currentPage, selectionRange));
    }
  }, [auth.user.token, currentPage, dispatch, selectionRange]);

  const sessionRow = (session, i) => (
    <tr key={session.id}>
      <td>{startIndex + 1 + i}</td>
      <td>{session?.name}</td>
      <td>{moment(session?.startTime).format('MMM Do, hh:mm a')}</td>
      <td align="center">{session?.listenedBy}</td>
      <td align="center">{session?.uniqueListeners}</td>
    </tr>
  );

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex justify-content-around">
          <h1>Saved Sessions</h1>
          <div className="input-group w-auto flex-fill justify-content-around">
            <div className="" style={{ zIndex: 1000, position: 'relative' }}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={({ selection = initialDateVal }) => {
                  setSelectionRange(selection);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-end p-3 ">
            <button
              className="btn btn-primary mr-5"
              onClick={() => {
                setCurrentPage(1);
                dispatch(setSessionsList(1, selectionRange));
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {sessionHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              {sessions && sessions.length > 0 && (
                <tbody>{sessions.map(sessionRow)}</tbody>
              )}
            </Table>
            <Paginate
              totalItems={totalItems}
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SavedSessionsList;
