import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SessionCard } from './components/SessionCard';
import { Community } from './components/Community';
import { Channel } from './components/Channel';
import { Users } from './components/Users';
import { Message } from './components/Message';
import { Feed } from './components/Feed';
import { DisplayNotFound } from './components/DisplayNotFound';
import queryString from 'query-string';

const API_URL = (process.env.REACT_APP_API_URL || '') + '/api';

export function RedirectToAppPage() {
  const location = useLocation();
  const [pageType, setPageType] = useState('');
  const [currentData, setCurrentData] = useState({});
  const [displayNotFound, setDisplayNotFound] = useState(false);

  const checkForSession = (valueKeys) => {
    return valueKeys.findIndex((item) => item === 'session');
  };

  useEffect(() => {
    const value = queryString.parse(location.search);
    let valueKeys = Object.keys(value);
    let keyword;

    if (valueKeys.length >= 2) {
      keyword = valueKeys[2];
    } else {
      keyword = valueKeys[0];
    }

    if (checkForSession(valueKeys) >= 0) {
      keyword = 'session';
    }

    setPageType(keyword);

    switch (keyword) {
      case 'session':
        axios
          .get(API_URL + `/sessions/${value?.session}`)
          .then((response) => {
            setCurrentData(response.data.session);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;
      case 'community':
        axios
          .get(API_URL + `/communities/${value?.community}`)
          .then((response) => {
            setCurrentData(response.data.community);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;
      case 'channel':
        axios
          .get(API_URL + `/channels/${value?.channel}`)
          .then((response) => {
            setCurrentData(response.data.channel);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;
      case 'feed':
        axios
          .get(API_URL + `/feed/${value?.feed}`)
          .then((response) => {
            setCurrentData(response.data.feed);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;
      case 'user':
        axios
          .get(API_URL + `/users/${value?.user}`)
          .then((response) => {
            setCurrentData(response.data.user);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;

      case 'message':
        axios
          .post(API_URL + `/feed/message`, {
            feedUserId: value?.feedid,
            messageId: value?.message,
            feedGroup: value?.feedgroup,
          })
          .then((response) => {
            setCurrentData(response.data.message);
          })
          .catch((err) => {
            setDisplayNotFound(true);
          });
        break;

      default:
        break;
    }
  }, [location]);

  const renderByPageType = (type) => {
    if (displayNotFound) {
      return <DisplayNotFound />;
    }

    switch (type) {
      case 'session':
        return <SessionCard currentData={currentData} />;

      case 'community':
        return <Community currentData={currentData} />;

      case 'channel':
        return <Channel currentData={currentData} />;

      case 'user':
        return <Users currentData={currentData} />;

      case 'message':
        return <Message currentData={currentData} />;

      case 'feed':
        return <Feed currentData={currentData} />;

      default:
        return 'foo';
    }
  };

  return <div>{pageType && currentData && renderByPageType(pageType)}</div>;
}
