import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportKeywords,
  fetchTop100Keywords,
} from '../../store/actions/SearchKeywordActions';
import { Table, Container } from 'react-bootstrap';
import './SearchKeywords.css';

export const SearchKeywords = () => {
  const dispatch = useDispatch();
  const searchHeaders = ['S.no', 'Word', 'Count'];

  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.searchKeywords.isLoading);
  const keywordsList = useSelector(
    (state) => state.searchKeywords.keywordsList
  );
  const [currentSwitch, setCurrentSwitch] = useState('activities');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const searchRow = (item, index) => {
    const { word = '', count = 0 } = item;
    return (
      <tr key={index.toString()}>
        <td className='text-center'>{index + 1}</td>
        <td className='text-center'>{word}</td>
        <td className='text-center'>{count}</td>
      </tr>
    );
  };

  useEffect(() => {
    let to = new Date().toISOString().split('T')[0];
    if (currentSwitch && auth?.user?.token) {
      dispatch(
        fetchTop100Keywords({
          mode: currentSwitch,
          fromDate: fromDate ? fromDate : '2023-01-01',
          toDate: toDate ? toDate : to,
        })
      );
    }
  }, [currentSwitch, fromDate, toDate, auth, dispatch]);

  return (
    <div className="d-flex f-1 flex-column">
      <div className="card shadow p-4">
        <h1>Most used keywords</h1>
      </div>
      <div className="d-flex f-1 align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex f-1 form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={currentSwitch === 'activities'}
              onChange={(e) => {
                setCurrentSwitch('activities');
              }}
            />
            <span className="mx-3">Most used search keywords</span>
          </div>
          <div className="d-flex f-1 form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={currentSwitch === 'contributions'}
              onChange={(e) => setCurrentSwitch('contributions')}
            />
            <span className="mx-3">
              Most used contribution/response keywords
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span className="mx-3">From</span>
          <input
            type="date"
            className="form-control"
            placeholder="From date"
            name="from"
            id="from"
            min="2023-01-01"
            max={new Date().toISOString().split('T')[0]}
            style={{ width: 200 }}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
          <span className="mx-3">To</span>
          <input
            type="date"
            className="form-control"
            placeholder="To date"
            name="to"
            id="to"
            min="2023-01-01"
            max={new Date().toISOString().split('T')[0]}
            style={{ width: 200 }}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </div>
        <div className="d-flex f-1 justify-content-center">
          {isLoading ? (
            <div className="fa-2x d-flex flex-fill justify-content-center pr-5">
              <i className="fas fa-spinner fa-pulse"></i>
            </div>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!fromDate || !toDate}
              onClick={async (e) => {
                e.preventDefault();
                await dispatch(
                  exportKeywords({ mode: currentSwitch, fromDate, toDate })
                );
                await dispatch(
                  fetchTop100Keywords({
                    mode: currentSwitch,
                    fromDate,
                    toDate,
                  })
                );
              }}>
              Export
            </button>
          )}
        </div>
      </div>

      <br></br>
      {keywordsList && keywordsList.length > 0 ? (
        <Container style={{ maxHeight: '80vh', overflow: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                {searchHeaders.map((header) => (
                  <th className="text-center" key={header}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {keywordsList && keywordsList.length > 0 && (
              <tbody>{keywordsList.map(searchRow)}</tbody>
            )}
          </Table>
        </Container>
      ) : (
        <span className='text-center'>No Keywords found</span>
      )}
    </div>
  );
};
