import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import SavedSessionsList from './SavedSessionsList';

const SavedSessions = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchSessions = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  return (
    <div>
      <CpPage ListPage={SavedSessionsList} />
    </div>
  );
};

export default SavedSessions;
