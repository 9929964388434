import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import {
  deleteSubEthnic,
  editSubEthnic,
  emptyEditData,
  listSubEthnics,
  setEditSubEthnic,
} from '../../store/actions/ethnicActions';
import Paginate from '../Utilities/Paginate';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import { Dropdown } from 'react-bootstrap';

const subEthnicHeaders = ['S.No.', 'Sub Community Name', 'Community'];

const EthnicList = (props) => {
  const pageUrl = `/panel/community`;
  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.ethnics.isLoading);
  const totalItems = useSelector((state) => state.ethnics.total);
  const perPage = useSelector((state) => state.ethnics.perPage);
  const deletingSubEthnic = useSelector(
    (state) => state.ethnics.deletingSubEthnic
  );
  const data = useSelector((state) => state.ethnics.subEthnicData);

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();
  const [editDropDown, setEditDropDown] = useState();
  const [editName, setEditName] = useState();
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const editData = useSelector((state) => state.ethnics.editData);

  useEffect(() => {
    if (auth.user.token && currentPage >= 2) {
      dispatch(listSubEthnics(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    if (editData) {
      setEditDropDown(editData?.ethnic);
    }
  }, [editData]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handleDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      await dispatch(deleteSubEthnic(id));
    }
  };

  const handleEdit = async (params) => {
    await dispatch(editSubEthnic(params));
    await dispatch(emptyEditData());
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const subEthnicRow = (data, i) => {
    let isEditMode = editData?.id === data.id;

    return (
      <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
        <td>{startIndex + 1 + i}</td>
        <td>
          {!isEditMode ? (
            data.name
          ) : (
            <>
              <input
                name="subCommunityName"
                id="subCommunityName"
                value={editName}
                placeholder="Enter the Sub-Community name"
                onChange={(e) => setEditName(e.target.value)}
                className="form-control"
              />
            </>
          )}
        </td>
        <td>
          {!isEditMode ? (
            data.ethnic.name
          ) : (
            <>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {editDropDown?.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {props.ethnicData &&
                    props.ethnicData.map((item) => {
                      return (
                        <Dropdown.Item
                          key={item.id.toString()}
                          onClick={() => {
                            setEditDropDown(item);
                          }}
                        >
                          {item?.name}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </td>
        <td>
          {deletingSubEthnic === data.id ? (
            <i className="fas fa-spinner fa-pulse ml-3"></i>
          ) : !isEditMode ? (
            <>
              <span
                title="edit"
                onClick={async () => {
                  setEditName(data.name);
                  await dispatch(setEditSubEthnic(data.id));
                }}
                className="ml-4"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-edit"></i>
              </span>
              <span
                title="delete"
                onClick={() => {
                  setDeleteData({ id: data.id, message: data.name });
                  setShow(!show);
                }}
                className="ml-4"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-trash text-danger"></i>
              </span>
            </>
          ) : (
            <>
              <span
                title="save"
                onClick={async () => {
                  handleEdit({
                    name: editName,
                    id: data.id,
                    ethnicId: editDropDown?.id,
                  });
                }}
                className="ml-4"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-save"></i>
              </span>
              <span
                title="clear"
                onClick={async () => {
                  await dispatch(emptyEditData());
                }}
                className="ml-4"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-times text-danger"></i>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-3">
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {subEthnicHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {data && data.length > 0 && (
                <tbody>
                  {data.slice(startIndex, endIndex).map(subEthnicRow)}
                </tbody>
              )}
            </Table>
            {data && (
              <Paginate
                totalItems={totalItems}
                currentPage={currentPage}
                perPage={perPage}
                pageUrl={pageUrl}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EthnicList;
