import {promotionItemActions} from "../actions/PromotionAction";

const initialState = {
    promotionItemData: [],
    isLoading: false,
    deletePromotion: null,
}

export const promotionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case promotionItemActions.SET_PROMOTION_DATA:
            return { ...state, promotionItemData: payload?.promotion}
        case promotionItemActions.SET_IS_LOADING:
            return { ...state, isLoading: payload }
        case promotionItemActions.DELETE_PROMOTION_ITEM:
            return { ...state, deletePromotion: payload }
        default:
            return state;
    }
};
