import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { setUserAction } from '../../store/actions/authActions';
import { apiRequest, setSubmitError } from '../../utils/requests';

const Login = ({ loginPage, errors, touched, isSubmitting, history }) => {
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth.isLoggedIn) history.replace('/panel');
  }, [auth, history]);

  const urlParams = new URLSearchParams(window.location.search);
  const secretKey = urlParams.get('secretKey');

  return (
    <div className="card shadow col-lg-6 mx-auto">
      <div className="card-body">
        <h1>{loginPage ? 'Log In' : 'Sign Up'}</h1>
        <Form action="/action_page.php" className="needs-validation">
          <div className="form-group">
            <label htmlFor="email">Email address:</label>
            <Field
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter email"
              id="email"
            />
            {touched.email && errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="pwd">Password:</label>
            <Field
              type="password"
              name="password"
              className="form-control"
              placeholder="Enter password"
              id="pwd"
            />
            {touched.password && errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
          {!loginPage && (
            <div className="form-group">
              <label htmlFor="secretKey">Secret Key:</label>
              <Field
                type="text"
                name="secretKey"
                autoComplete="off"
                className="form-control"
                placeholder="Enter Secret Key"
                id="secretKey"
                readOnly={secretKey}
              />
              {touched.secretKey && errors.secretKey && (
                <div className="invalid-feedback">{errors.secretKey}</div>
              )}
            </div>
          )}
          {errors.submitError && (
            <div className="alert alert-danger">{errors.submitError}</div>
          )}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {loginPage ? 'Log In' : 'Sign Up'}
          </button>
        </Form>
      </div>
    </div>
  );
};

const LoginFormik = withFormik({
  mapPropsToValues({ email, password }) {
    const urlParams = new URLSearchParams(window.location.search);
    const secretKey = urlParams.get('secretKey');
    return {
      email: email || '',
      password: password || '',
      secretKey: secretKey || '',
    };
  },
  async handleSubmit(values, { props, setErrors, resetForm, setSubmitting }) {
    const endpoint = props.loginPage ? 'login' : 'signup';
    try {
      const formFields = {
        Email: values.email,
        Password: values.password,
      };
      if (!props.loginPage) {
        formFields.secretKey = values.secretKey;
      }
      const response = await apiRequest('post', `/admins/${endpoint}`, {
        formData: formFields,
        storeToken: !!values.rememberMe,
      });
      resetForm();
      await props.dispatch(
        setUserAction(response.data, props.history, {
          storeToken: values.rememberMe,
        })
      );
      setSubmitting(false);
      props.history.push('/panel');
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Minimum 6 characters!')
      .max(50, 'Maximum 50 characters!')
      .required('Password is required'),
  }),
})(Login);

export default LoginFormik;
