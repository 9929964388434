import React from 'react';
import './RedirectToApp.css';

export function DisplayNotFound() {
  return (
    <div className="conatiner notFoundMain d-flex align-items-center">
      <div className="row m-0 w-100">
        <div className="col-sm-12">
          <div>
            <div className="d-flex align-items-center justify-content-center flex-column mb-4">
              <div className="logoWrap imgBordered">
                <img
                  src="https://askdiem-reactnative.s3.us-east-2.amazonaws.com/staging-backend/communities/new/0b572f80-5506-11eb-86d9-0bb142a48852.png"
                  className="w-100 imgBordered"
                  alt=""
                />
              </div>
              <p className="mb-1 mt-2">Diem</p>
              <p className="contentInfo">
                Looks Like Invalid Link! Contact Diem Support!
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <p className="mb-3">Don't have an account in Diem?</p>
              <div className="d-flex align-items-center">
                <div className="playImg mr-2">
                  <a href="https://apps.apple.com/in/app/diem-womxns-social-platform/id1524855792">
                    <img alt="" src="./icons/app.png" className="w-100" />
                  </a>
                </div>
                <div className="playImg">
                  <a href="https://play.google.com/store/apps/details?id=com.askdiem.app&hl=en">
                    <img alt="" src="./icons/play.png" className="w-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
