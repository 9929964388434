import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setKeywordsAction } from '../../store/actions/keywordAction';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';

const Category = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchCategory = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setKeywordsAction(1));
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);
  return (
    <div>
      <CpPage ListPage={CategoryCreate} EditPage={CategoryEdit} />
    </div>
  );
};

export default Category;
