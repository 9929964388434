import {tagsActions} from "../actions/tagsActions";

const initialState = {
    tagsData: [],
    isLoading: false,
    deletingTags: 0,
    alreadyExist: false,
    editData: {},
    total: 0,
    perPage: 5
}

export const tagsReducer = (state = initialState, { type, payload }) => {
    let tagsData;
    switch (type) {
        case tagsActions.SET_TAGS_DATA:
            tagsData = payload.isFirstTimeLoad ? payload.tagsList : [...state.tagsData, ...payload.tagsList]
            return { ...state, tagsData, total: payload.count };

        case tagsActions.SET_ALREADY_EXIST:
            return { ...state, alreadyExist: payload }
        case tagsActions.SET_IS_LOADING:
            return { ...state, isLoading: payload }

        case tagsActions.SET_DELETING_TAGS:
            return { ...state, deletingInterest: payload }

        case tagsActions.ADD_TAGS:
            payload.addedNow = true
            return { ...state, tagsData: [payload, ...state.tagsData], total: state.total + 1 }

        case tagsActions.DELETE_TAGS:
            const categoryArray = [...state.tagsData]
            const updatedTagsData = categoryArray.filter((item) => item.id !== payload);
            return { ...state, tagsData: updatedTagsData, total: state.total - 1 }

        case tagsActions.SET_EDIT_TAGS: {
            const index = state.tagsData.findIndex(
                (item) => item.id.toString() === payload
            );
            return { ...state, editData: state.tagsData[index] };
        }
        case tagsActions.UPDATE_TAGS_LIST: {
            const data = [...state.interestData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) {
                data[index] = payload
            }
            return { ...state, interestData: data }
        }

        case tagsActions.ADD_TAGS_COMPLETED: {
            const data = [...state.tagsData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) data[index].addedNow = false;
            return { ...state, tagsData: data }
        }

        default:
            return state;
    }
}
