import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchFeedMetrics } from '../../store/actions/metricActions';
import './DiemMetrics.css';

const DiemMetrics = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const metricsData = useSelector((state) => state.metrics);
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  const initialValues = {
    from: '',
    to: '',
  };
  

  const handleFormSumbit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue, validateForm }
  ) => {
    const { to, from } = values;
    try {
      dispatch(fetchFeedMetrics({from, to}));
    } catch (err) {

    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      from: Yup.date().required('From date is required'),
      to: Yup.date()
        .test({
          message: 'To date must be greater than From date',
          test: (value, testContext) => {
            const { from } = testContext.from[0].value;
            return value > new Date(from) || !value;
          },
        })
        .required('To date is required'),
    });

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSumbit,
    validationSchema,
  });
  

  const CardComponent = ({id, content = '', value = '', color, icon }) => {
    return (
      <div key={id} className="col-md-4 col-xl-3">
        <div className={`card shadow ${color} order-card`}>
          <div className="card-block">
            <h6 className="m-b-20">{content}</h6>
            <h2 className="text-right">
              {/* <i className={`fa ${icon} f-left`}></i> */}
              <span>{value}</span>
            </h2>
            {/* <p className="m-b-0">
                Completed Orders<span className="f-right">{value}</span>
              </p> */}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (auth) {
      dispatch(fetchFeedMetrics());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    const { diemMetrics } = metricsData || {};

    if (diemMetrics) {
      const {
        noOfDiemsCreated = 0,
        uniqueUsersCreatingDiem = 0,
        percentUsersCommenting = 0,
        noOfDiemsCommented = 0,
      } = diemMetrics;
      let tempData = [
        {
          content: '# of Diems created',
          value: `${noOfDiemsCreated}`,
          color: 'bg-c-green',
          icon: 'fa-d',
        },
        {
          content: '# of Diems commented',
          value: `${noOfDiemsCommented}`,
          color: 'bg-c-yellow',
          icon: 'fa-comment',
        },
        {
          content: 'User comment %',
          value: `${Math.round(percentUsersCommenting * 100) / 100}%`,
          color: 'bg-c-pink',
          icon: 'fa-comment',
        },
        {
          content: 'no. of users creating diem',
          value: `${uniqueUsersCreatingDiem}`,
          color: 'bg-c-blue',
          icon: 'fa-d',
        },
      ];
      setData(tempData);
    }
  }, [metricsData]);



  const { values, errors } = formik;
  const { from, to } = values;
  
  useEffect(() => {
    if (errors && (errors?.from || errors?.to)) {
      if (errors?.from) setError(errors?.from);
      if (!errors?.from && errors?.to) setError(errors?.to);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  },[errors])

  return (
    <div className="card shadow p-4">
      <div className="d-flex column justify-content-between">
        <h1>Diem metrics</h1>
        <div className="d-flex column">
          <form
            className="needs-validation d-flex align-items-center"
            onSubmit={formik.handleSubmit}>
            <input
              type="date"
              className="form-control"
              placeholder="From date"
              name="from"
              min="2022-10-27"
              id="from"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.from}
            />
            <span className="mx-3">To</span>
            <input
              type="date"
              className="form-control"
              placeholder="To date"
              name="to"
              id="to"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.to}
            />
            <button
              type="submit"
              className={
                'input-group-append ml-3 btn btn-lg ' +
                (from && to ? 'btn-success' : 'btn-default border')
              }
              // disabled={formik.isSubmitting}
            >
              <i className="fa fa-search font-weight-bold"></i>
            </button>
          </form>
        </div>
      </div>
      <div
        className="error-container d-flex flex-row justify-content-between"
        style={{ maxHeight: '30px' }}>
        <div></div>

        {(errors?.from || errors?.to) && error && (
          <div className="text-danger">{error}</div>
        )}
      </div>
      <div className="container">
        <div className="row mt-5">
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, index) => {
              return <CardComponent key={index} {...item} id={index} />;
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiemMetrics;
