import { interestTagActions } from '../actions/interestTagAction';

export const initialState = {
  interestTagList: [],
  errors: { listError: null, deleteError: null },
  total: 0,
  isLoading: false,
  editData: {},
  perPage: 10,
  search: '',
};

export const interestTagReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case interestTagActions.SET_INTEREST_TAGS:
      return {
        ...state,
        interestTagList: payload.interestTags,
        total: payload.count,
      };
    case interestTagActions.INTEREST_TAGS_LOADING:
      return { ...state, isLoading: payload };
    case interestTagActions.SET_INTEREST_ALREADY_EXIST:
      return {...state, alreadyExist: payload}
    case interestTagActions.SET_DELETING_INTEREST_TAG:
      return {...state, deletingInterest: payload}

    case interestTagActions.ADD_INTEREST_TAGS:
      payload.addedNow = true
      return {...state, interestTagList: [payload, ...state.interestTagList], total: state.total + 1}

    case interestTagActions.DELETE_INTEREST_TAG:
      const itArray = [...state.interestTagList]
      const updatedTagsData = itArray.filter((item) => item.id !== payload);
      return {...state, interestTagList: updatedTagsData, total: state.total - 1}

    case interestTagActions.SET_EDIT_INTEREST_TAG: {
      const index = state.interestTagList.findIndex(
        (item) => item.id.toString() === payload
      );
      return {...state, editData: state.interestTagList[index]};
    }
    case interestTagActions.UPDATE_INTEREST_TAGS_LIST: {
      const data = [...state.interestTagList]
      const index = data.findIndex((item) => item.id === payload.id)
      if (index !== undefined) {
        data[index] = payload
      }
      return {...state, interestTagList: data}
    }

    case interestTagActions.ADD_INTEREST_TAG_COMPLETED: {
      const data = [...state.interestTagList]
      const index = data.findIndex((item) => item.id === payload.id)
      if (index !== undefined) data[index].addedNow = false;
      return {...state, interestTagList: data}
    }
    default:
      return state;
  }
};
