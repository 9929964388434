export function redirectDefaultPage() {
  let websiteURL = 'https://askdiem.com';
  let appURL = 'askdiem://';
  let playStoreURL =
    'https://play.google.com/store/apps/details?id=com.askdiem.app&hl=en';
  let appStoreURL =
    'https://apps.apple.com/in/app/diem-womxns-social-platform/id1524855792';

  var fallbackLink =
    websiteURL + '/' + window.location.search + window.location.hash;

  var isiOS =
      navigator.userAgent.match('iPad') ||
      navigator.userAgent.match('iPhone') ||
      navigator.userAgent.match('iPod'),
    isAndroid = navigator.userAgent.match('Android');
  // Mobile
  if (isiOS || isAndroid) {
    if (appURL.indexOf('?') !== -1) {
      appURL = appURL + '&';
    } else {
      appURL = appURL + '?';
    }

    if (
      window.location.search &&
      window.location.search.length > 0 &&
      (window.location.search.startsWith('?') ||
        window.location.search.startsWith('&'))
    ) {
      appURL =
        appURL +
        window.location.search.substring(1, window.location.search.length) +
        window.location.hash;
    } else {
      appURL = appURL + window.location.search + window.location.hash;
    }

    fallbackLink = isAndroid ? playStoreURL : appStoreURL;

    try {
      window.location.assign(appURL);

    } catch (err) {
      window.location.assign(fallbackLink);
    }
  } else {
    //Other OS (E.g: Windows)
    {
      window.location.assign(fallbackLink);
    }
  }
}
