import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import inflection from 'inflection';
import queryString from 'query-string';

import Paginate from '../Utilities/Paginate';
import {
  removeChannelAction,
  setChannelsAction,
  setChannelSearch,
} from '../../store/actions/channelActions';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';

const channelHeaders = [
  'S.No.',
  'Name',
  'Image',
  'Community',
  'Type',
  'Private',
  'Active',
];
const pageUrl = '/panel/channels';

const ChannelList = () => {
  const auth = useSelector((state) => state.auth);
  const channels = useSelector((state) => state.channels.list);
  const isLoading = useSelector((state) => state.channels.isLoading);
  const totalItems = useSelector((state) => state.channels.total);
  const perPage = useSelector((state) => state.channels.perPage);
  const search = useSelector((state) => state.channels.search);
  const deletingChannel = useSelector(
    (state) => state.channels.deletingChannel
  );
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(setChannelsAction(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  const handleChannelDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      dispatch(removeChannelAction(id));
    }
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const handleSearch = (e) => {
    dispatch(setChannelSearch(e.target.value));
  };

  const filteredChannels = useMemo(
    () =>
      search.trim()
        ? channels.filter((c) =>
            c.name.toLowerCase().includes(search.toLowerCase())
          )
        : channels,
    [search, channels]
  );

  const channelRow = (channel, i) => (
    <tr key={channel.id}>
      <td>{startIndex + 1 + i}</td>
      <td>{channel.name}</td>
      <td>
        {channel.imageUrl && (
          <img
            className="img-thumbnail thumb-img"
            src={channel.imageUrl}
            alt={channel.name}
          />
        )}
      </td>
      <td>{channel.community && channel.community.name}</td>
      <td>{inflection.capitalize(channel.type)}</td>
      <td className="text-center">
        <div
          className={
            'dot d-inline-block' +
            (channel.isPrivate ? ' bg-success' : ' bg-danger')
          }
        ></div>
      </td>
      <td className="text-center">
        <div
          className={
            'dot d-inline-block' +
            (channel.isActive ? ' bg-success' : ' bg-danger')
          }
        ></div>
      </td>
      <td>
        {deletingChannel === channel.id ? (
          <i className="fas fa-spinner fa-pulse ml-3"></i>
        ) : (
          <>
            <Link to={pageUrl + '/' + channel.id}>
              <i className="fas fa-edit"></i>
            </Link>
            <span
              onClick={() => {
                setDeleteData({ id: channel.id, message: channel.name });
                setShow(!show);
              }}
              className="ml-4"
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-trash text-danger"></i>
            </span>
          </>
        )}
      </td>
    </tr>
  );

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex align-items-center">
          <h1>Channels</h1>
          <div className="input-group search-field mx-3 w-auto flex-fill">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              name="search"
              value={search}
              onChange={handleSearch}
            />
            <div className="input-group-append">
              <i className="fa fa-search input-group-text font-weight-bold"></i>
            </div>
          </div>
          <Link to={pageUrl + '/create'} className="btn btn-primary ml-auto">
            Create Channel
          </Link>
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleChannelDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {channelHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {filteredChannels && filteredChannels.length > 0 && (
                <tbody>
                  {filteredChannels.slice(startIndex, endIndex).map(channelRow)}
                </tbody>
              )}
            </Table>
            <Paginate
              totalItems={search.trim() ? filteredChannels.length : totalItems}
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ChannelList;
