import { userListActions } from "../actions/usersListActions";

export const initialState = {
    usersList: [],
    errors: { listError: null, deleteError: null },
    total: 0,
    userLoading: false,
    perPage: 10,
    deletingUser: null,
    deleteUserLoading: false,
    search: ''
}

export const usersListReducers = (state = initialState, { type, payload }) => {
    let usersList;
    switch(type){
        case userListActions.SET_USERS_LIST:
            return {
                ...state,
                usersList: payload.rows,
                total: payload.count,
            };
        case userListActions.SET_SEARCH_USER:
            return {
                ...state, search: payload
            }
        case userListActions.SET_DELETING_USERS:
            return {
                ...state, deletingUser: payload
            }
        case userListActions.DELETE_USER:
            usersList = state.usersList.filter((user) => user.id !== payload);
            return { ...state, usersList: usersList };  
        case userListActions.CLEAR_USER_LIST:
            return {
                ...state,
                usersList: []
            }
        case userListActions.USERS_LIST_LOADING:
            return {
                ...state,
                userLoading: payload
            }
        case userListActions.DELETE_USER_LOADING:
            return {
                ...state,
                deleteUserLoading: payload
            }
        case userListActions.SET_USER_DELETE_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    deleteError: payload
                }
            }
        case userListActions.UPDATE_USER_LIST: {
          const data = [...state.usersList]
          const index = data.findIndex((item) => item.id === payload.id)
          if (index !== undefined) {
            data[index] = payload
          }
          return {...state, usersList: data}
        }
        default:
            return state;
    }
}