import {apiRequest} from '../../utils/requests';
import {delay3Seconds} from '../../utils/constants';

const pageUrl = "/interest-tags"

export const interestTagActions = {
  SET_INTEREST_TAGS: 'SET_INTEREST_TAGS',
  INTEREST_TAGS_LOADING: 'INTEREST_TAGS_LOADING',
  ADD_INTEREST_TAGS: 'ADD_INTEREST_TAGS',
  DELETE_INTEREST_TAG: 'DELETE_INTEREST_TAG',
  SET_DELETING_INTEREST_TAG: 'SET_DELETING_INTEREST_TAG',
  SET_EDIT_INTEREST_TAG: 'SET_EDIT_INTEREST_TAG',
  UPDATE_INTEREST_TAGS_LIST: 'UPDATE_INTEREST_TAGS_LIST',
  ADD_INTEREST_TAG_COMPLETED: 'ADD_INTEREST_TAG_COMPLETED',
  CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
  SET_INTEREST_ALREADY_EXIST: 'SET_INTEREST_ALREADY_EXIST'
};

export const setInterestTagListData = ({interestTags, count, isFirstTimeLoad}) => {
  return {
    type: interestTagActions.SET_INTEREST_TAGS,
    payload: {interestTags, count, isFirstTimeLoad},
  };
};

export const setInterestTagsLoading = (data) => {
  return {
    type: interestTagActions.INTEREST_TAGS_LOADING,
    payload: data,
  };
};

export const setInterestTagList = (currentPage, search = '') => {
  return async (dispatch, getState) => {
    await dispatch(setInterestTagsLoading(true));
    const {auth} = getState();

    const apiUrl = `${pageUrl}`;
    const params = {
      offset: currentPage * 10 - 10,
      perPage: 10
    }
    if (search) params['search'] = search;
    try {
      const {data} = await apiRequest('get', apiUrl, {
        params,
        token: auth.user.token,
      });
      // const { listData } = data;
      const {interestTags, count} = data;
      await dispatch(
        setInterestTagListData({
          interestTags,
          count,
          isFirstTimeLoad: false,
        })
      );
      await dispatch(setInterestTagsLoading(false));
    } catch (err) {
      await dispatch(setInterestTagsLoading(false));
    }
  };
};

export const setAlreadyExist = (data) => {
  return {type: interestTagActions.SET_INTEREST_ALREADY_EXIST, payload: data};
};

export const setInterestTagsDeleting = (data) => {
  return {type: interestTagActions.SET_DELETING_INTEREST_TAG, payload: data};
};

export const removeInterestTags = (data) => {
  return {type: interestTagActions.DELETE_INTEREST_TAG, payload: data, total: data.count - 1}
}

export const addInterestTags = (data) => {
  return {type: interestTagActions.ADD_INTEREST_TAGS, payload: data};
};

export const addInterestTagsCompleted = (data) => {
  return {type: interestTagActions.ADD_INTEREST_TAG_COMPLETED, payload: data};
};

export const setEditInterestTags = (id) => {
  return async (dispatch, getState) => {
    await dispatch({type: interestTagActions.SET_EDIT_INTEREST_TAG, payload: id})
  }
}

export const updateInterestTagsList = (data) => {
  return {type: interestTagActions.UPDATE_INTEREST_TAGS_LIST, payload: data}
}

export const addInterestTag = (value) => {
  return async (dispatch, getState) => {
    const {auth} = getState();

    if (auth.user.token) {
      const endpoint = `${pageUrl}`;

      try {
        const {data} = await apiRequest(
          'post',
          endpoint,
          {
            formData: value,
            token: auth.user.token
          }
        );

        const {status} = data;
        if (status === "Created") {
          await dispatch(addInterestTags(data.data));

          setTimeout(async () => {
            await dispatch(addInterestTagsCompleted(data.data));
          }, delay3Seconds);

          return data;
        }
      } catch (error) {
        await dispatch(setAlreadyExist(true));
        setTimeout(async () => {
          await dispatch(setAlreadyExist(false));
        }, delay3Seconds);
        return error;
      }
    }
  }
}

export const deleteInterestTag = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value}`;
            await dispatch(setInterestTagsDeleting(value))
            await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            await dispatch(removeInterestTags(value))
            await dispatch(setInterestTagsDeleting(0))
        }
    }
}

export const editInterestTag = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value.id}`;
            const { data } = await apiRequest(
                'patch',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { interestTag, updated } = data
            if (updated) {
                await dispatch(updateInterestTagsList(interestTag))
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return updated
        }
    }
}
