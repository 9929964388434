import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import UsersList from './UsersList';
const Users = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  
  const fetchUsers = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <div>
      <CpPage ListPage={UsersList} />
    </div>
  );
};

export default Users;
