import { apiRequest, getErrorMsg } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = '/keywords';

export const keywordActions = {
  SET_KEYWORDS: 'SET_KEYWORDS',
  SET_KEYWORDS_ERROR: 'SET_KEYWORDS_ERROR',
  KEYWORDS_LOADING: 'KEYWORDS_LOADING',
};

export const setLoading = (isLoading) => {
  return { type: keywordActions.KEYWORDS_LOADING, payload: { isLoading } };
};

export const setKeywords = ({ keywords, count }) => {
  return {
    type: keywordActions.SET_KEYWORDS,
    payload: { keywords, count },
  };
};

export const setKeywordsError = (error) => {
  return {
    type: keywordActions.SET_KEYWORDS_ERROR,
    payload: { error },
  };
};

export const setKeywordsAction = (currentPage) => {
  return async (dispatch, getState) => {
    try {
      const { keywords, auth } = getState();
      const { list, perPage } = keywords;
      const { shouldFetch, params } = getPagingParams({
        list,
        currentPage,
        perPage,
      });
      if (shouldFetch) {
        await dispatch(setLoading(true));
        const response = await apiRequest('get', pageUrl, {
          token: auth.user.token,
          params,
        });
        const { keywords, count } = response.data;
        keywords.sort((a, b) => a.keyword.toLowerCase().localeCompare(b.keyword.toLowerCase()))
        await dispatch(setKeywords({ keywords, count }));
        await dispatch(setKeywordsError(null));
      }
    } catch (err) {
      await dispatch(setKeywordsError(getErrorMsg(err)));
    }
    await dispatch(setLoading(false));
  };
};
