import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ErrorMessage from '../Utilities/ErrorMessage';
import { setSubmitError } from '../../utils/requests';
import {
  setEditPromo,
  editPromoCode,
  listPromoCodes,
} from '../../store/actions/promoActions';

const PromotionalCodeEdit = () => {
  const { id } = useParams();
  const promoData = useSelector((state) => state.promotions.promoData);
  const editData = useSelector((state) => state.promotions.editData);
  const alreadyExist = useSelector((state) => state.promotions.alreadyExist);
  const [editPromoData, setEditPromoData] = useState(undefined);
  const useStyles = makeStyles((theme) => ({
    root: {
      height: '720px',
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchPromoEditData = useCallback(async () => {
    if (id) {
      promoData && (await dispatch(listPromoCodes(1)));
      await dispatch(setEditPromo(id));
    }
  }, [dispatch, promoData, id]);

  useEffect(() => {
    if (editData) {
      setEditPromoData(editData);
    }
  }, [editData]);

  useEffect(() => {
    fetchPromoEditData();
  }, [fetchPromoEditData]);

  const history = useHistory();

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.hostName = values.hostName;
      data.code = values.promoToken;
      data.id = id;
      let response = await dispatch(editPromoCode(data));
      response && history.goBack();
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  };

  const initialValues = {
    hostName:
      editPromoData && editPromoData.hostName ? editPromoData.hostName : '',
    promoToken: editPromoData && editPromoData.code ? editPromoData.code : '',
  };
  const validationSchema = Yup.object().shape({
    hostName: Yup.string().required('host name is required'),
    promoToken: Yup.string().max(6, 'Max 6 characters'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className={classes.root}>
      <div className="card shadow p-5">
        <h1>Edit Promo code</h1>
        {editPromoData !== undefined && (
          <form className="needs-validation" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="name">Host name:</label>
                <input
                  name="hostName"
                  id="hostName"
                  value={formik.values.hostName || ''}
                  placeholder="Enter the host name"
                  onChange={formik.handleChange}
                  className="form-control"
                />
                <ErrorMessage name="hostName" formik={formik} />
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="name">Promo token:</label>
                <input
                  name="promoToken"
                  id="promoToken"
                  value={formik.values.promoToken || ''}
                  placeholder="Enter the promo token for the host"
                  onChange={formik.handleChange}
                  className="form-control"
                />
                <ErrorMessage name="promoToken" formik={formik} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="name">Claimed count:</label>
                <input
                  name="claimedCount"
                  id="claimedCount"
                  value={editPromoData.claimedCount?.toString() || ''}
                  readOnly
                  className="form-control"
                />
              </div>
            </div>
            {alreadyExist && (
              <div>
                <Alert severity="error">
                  The promo code given already exists! try changing it
                </Alert>
                <br></br>
              </div>
            )}
            <div className="d-flex">
              <button
                className="btn btn-success mr-5"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Update
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => history.goBack()}
                disabled={formik.isSubmitting}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default PromotionalCodeEdit;
