import { apiRequest } from "../../utils/requests";

export const sessionActions = {
    SET_SESSIONS: 'SET_SESSIONS',
    SESSIONS_LOADING: 'SESSIONS_LOADING',
    SET_SESSIONS_ERROR: 'SET_SESSIONS_ERROR',
    REMOVE_SESSION: 'REMOVE_SESSION',
    SET_DELETING_SESSION: 'SET_DELETING_SESSION',
    DELETE_SESSION: 'DELETE_SESSION',
    DELETE_SESSION_LOADING: 'DELETE_SESSION_LOADING',
    SET_SEARCH_SESSION: 'SET_SEARCH_SESSION',
    SET_SESSION_DELETE_ERROR: 'SET_SESSION_DELETE_ERROR'
};

export const setSessionListData = ({ sessions, count, isFirstTimeLoad }) => {
    return {
        type: sessionActions.SET_SESSIONS,
        payload: { sessions, count, isFirstTimeLoad }
    }
}

export const setSessionDelete = (data) => {
    return {
        type: sessionActions.SET_DELETING_SESSION,
        payload: data
    }
}

export const deleteSessionData = (data) => {
    return {
        type: sessionActions.DELETE_SESSION,
        payload: data
    }
}

export const setSesionLoading = (data) => {
    return {
        type: sessionActions.SESSIONS_LOADING,
        payload: data
    }
}

export const setDeleteSessionLoading = (data) => {
    return {
        type: sessionActions.DELETE_SESSION_LOADING,
        payload: data
    }
}

export const setSessionSearchKey = (data) => {
    return {
        type: sessionActions.SET_SEARCH_SESSION,
        payload: data
    }
}

export const setSessionDeleteError = (data) => {
    return {
        type: sessionActions.SET_SESSION_DELETE_ERROR,
        payload: data
    }
}

export const setSessionsList = (currentPage) => {
    return async (dispatch, getState) => {
        await dispatch(setSesionLoading(true))
        const { auth } = getState();
        const apiUrl = '/main/sessions/list?orderBy=desc&exclude=true'
        try {
            const { data } = await apiRequest(
                'get',
                apiUrl,
                {
                    params: {
                        offset: currentPage * 10 - 10,
                        perPage: 10
                    },
                    token: auth.user.token
                }
            )
            const { listData } = data;
            const { rows, count} = listData;
            await dispatch(setSessionListData({ sessions: rows, count, isFirstTimeLoad: false }));
            await dispatch(setSesionLoading(false))
        } catch (err) {
            await dispatch(setSesionLoading(false))
        }
    }
}

export const searchSessionsList = (currentPage) => {
    return async (dispatch, getState) => {
        await dispatch(setSesionLoading(true))
        const { auth, sessions } = getState();
        const apiUrl = '/search/records'
        try {
            const { data } = await apiRequest(
                'get',
                `${apiUrl}?mode=sessions&searchText=${sessions?.search}`,
                {
                    params: {
                        offset: currentPage * 10 - 10,
                        perPage: 10
                    },
                    token: auth.user.token
                }
            )
            await dispatch(setSessionListData({ sessions: data?.sessions, count: data?.count, isFirstTimeLoad: false }));
            await dispatch(setSesionLoading(false))
        } catch (err) {
            await dispatch(setSesionLoading(false))
        }
    }
}

export const sessionDelete = (id) => {
    return async (dispatch, getState) => {
        await dispatch(setDeleteSessionLoading(true))
        const { auth } = getState();
        if(id) {
            try {
                const { data } = await apiRequest(
                    'delete',
                    `/sessions/${id}`,
                    {
                        token: auth.user.token
                    }
                )
                if(data) {
                    await dispatch(deleteSessionData(id))
                    await dispatch(setSessionDelete(false))
                    await dispatch(setDeleteSessionLoading(false))
                    await dispatch(setSessionDeleteError(false))
                }
            } catch (err) {
                await dispatch(setDeleteSessionLoading(false))
                await dispatch(setSessionDeleteError(true))
                setTimeout(async () => {
                    await dispatch(setSessionDeleteError(false))
                }, 3000)
            }
        }
    }
}