import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchMetricsData } from '../../store/actions/metricActions';
import { metricDimensions } from '../../utils/constants';
import ErrorMessage from '../Utilities/ErrorMessage';
import './MetricsFilter.css';
import { setSubmitError } from '../../utils/requests';

const MetricsFilter = ({ dimension = metricDimensions.daily }) => {
  const dispatch = useDispatch();
  const dimensionState = useSelector((state) => state.metrics[dimension]);
  const { error } = dimensionState;

  const initialValues = {
    from: '',
    to: '',
  };

  const handleFormSumbit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    const { to, from } = values;
    try {
      dispatch(fetchMetricsData({ useFilters: true, dimension, to, from }));
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      from: Yup.date().required('From date is required'),
      to: Yup.date()
        .test({
          message: 'To date must be greater than From date',
          test: (value, testContext) => {
            const { from } = testContext.from[0].value;
            return value > new Date(from) || !value;
          },
        })
        .required('To date is required'),
    });

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSumbit,
    validationSchema,
  });

  const { values } = formik;
  const { from, to } = values;

  return (
    <div className="input-group metrics-filter-container mx-3 w-auto flex-fill flex-column ">
      <div className="d-flex align-items-center">
        <form
          className="needs-validation d-flex align-items-center"
          onSubmit={formik.handleSubmit}
        >
          <input
            type="date"
            className="form-control"
            placeholder="From date"
            name="from"
            id="from"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.from}
          />
          <span className="mx-3">To</span>
          <input
            type="date"
            className="form-control"
            placeholder="To date"
            name="to"
            id="to"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.to}
          />
          <button
            type="submit"
            className={
              'input-group-append ml-3 btn btn-lg ' +
              (from && to ? 'btn-success' : 'btn-default border')
            }
            disabled={formik.isSubmitting}
          >
            <i className="fa fa-search font-weight-bold"></i>
          </button>
        </form>
      </div>
      <div
        className="error-container d-flex align-items-center"
        style={{ maxHeight: '30px' }}
      >
        <ErrorMessage
          name="from"
          formik={formik}
          className="w-50"
          displayDiv={true}
        />
        <ErrorMessage
          name="to"
          formik={formik}
          className="w-50"
          displayDiv={true}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

export default MetricsFilter;
