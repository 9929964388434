import React, { useEffect, useCallback } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/Main/Header';
import { routes } from './routes/mainRoutes';
import { autoLoginUser } from './store/actions/authActions';

function App() {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const autoLogin = useCallback(async () => {
    if (!auth.isLoggedIn) {
      const token =
        sessionStorage.getItem('token') || localStorage.getItem('token');
      const unprotectedRoutes = ['/login', '/', '/signup', '/redirect'];
      const isUnprotectedRoute = unprotectedRoutes.includes(location.pathname);
      if (token && !auth.errors.autoLoginError) dispatch(autoLoginUser(token));
      else if (!isUnprotectedRoute) history.replace('/login');
    }
  }, [
    dispatch,
    history,
    location.pathname,
    auth.isLoggedIn,
    auth.errors.autoLoginError,
  ]);

  useEffect(() => {
    autoLogin();
  }, [autoLogin]);

  const isRedirectPage = location.pathname.includes('/redirect');

  return (
    <div className="App">
      {!isRedirectPage && <Header />}
      <div className={'container-fluid' + (isRedirectPage ? '' : ' mt-3')}>
        <Switch>
          {routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(routeParams) => (
                <route.component
                  {...(route.props || {})}
                  dispatch={dispatch}
                  {...routeParams}
                />
              )}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default App;
