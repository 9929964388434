import { metricsActions } from '../actions/metricActions';

export const initialState = {
  headers: { dimensionHeaders: [], metricHeaders: [] },
  daily: { metrics: [], count: 0, isLoading: false },
  weekly: { metrics: [], count: 0, isLoading: false },
  monthly: { metrics: [], count: 0, isLoading: false },
  diemMetrics: {}
};

export const metricReducer = (
  state = initialState,
  { type, payload = {} } = {}
) => {
  let { daily, weekly, monthly } = state;
  const { isLoading, metrics, error, headers, diemMetrics = {} } = payload;
  switch (type) {
    case metricsActions.SET_DAILY_METRICS_LOADING:
      return {
        ...state,
        daily: { ...daily, isLoading },
      };
    case metricsActions.SET_WEEKLY_METRICS_LOADING:
      return {
        ...state,
        weekly: { ...weekly, isLoading },
      };
    case metricsActions.SET_MONTHLY_METRICS_LOADING:
      return {
        ...state,
        monthly: { ...monthly, isLoading },
      };
    case metricsActions.SET_DAILY_METRICS_ERROR:
      return {
        ...state,
        daily: { ...daily, error },
      };
    case metricsActions.SET_WEEKLY_METRICS_ERROR:
      return {
        ...state,
        weekly: { ...weekly, error },
      };
    case metricsActions.SET_MONTHLY_METRICS_ERROR:
      return {
        ...state,
        monthly: { ...monthly, error },
      };
    case metricsActions.SET_DAILY_METRICS:
      return {
        ...state,
        daily: { ...daily, metrics },
      };
    case metricsActions.SET_WEEKLY_METRICS:
      return {
        ...state,
        weekly: { ...weekly, metrics },
      };
    case metricsActions.SET_MONTHLY_METRICS:
      return {
        ...state,
        monthly: { ...monthly, metrics },
      };
    case metricsActions.SET_METRICS_HEADERS:
      return {
        ...state,
        headers,
      };
    
    case metricsActions.SET_DIEM_FEED_METRICS:
      return {
        ...state,
        diemMetrics,
      };
    
    default:
      return state;
  }
};
