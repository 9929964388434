import { delay3Seconds } from '../../utils/constants';
import { apiRequest } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = "/tags"

export const tagsActions = {
    SET_TAGS_DATA: 'SET_TAGS',
    SET_IS_LOADING: 'SET_IS_LOADING',
    ADD_TAGS: 'ADD_TAGS',
    DELETE_TAGS: 'DELETE_TAGS',
    SET_DELETING_TAGS: 'SET_DELETING_TAGS',
    CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
    SET_EDIT_TAGS: 'SET_EDIT_TAGS',
    UPDATE_TAGS_LIST: 'UPDATE_TAGS_LIST',
    ADD_TAGS_COMPLETED: 'ADD_TAGS_COMPLETED',
    SET_ALREADY_EXIST: 'SET_ALREADY_EXIST'
}

export const setAlreadyExist = (data) => {
    return { type: tagsActions.SET_ALREADY_EXIST, payload: data };
};

export const setTagsData = ({ tagsList, count, isFirstTimeLoad = true }) => {
    return { type: tagsActions.SET_TAGS_DATA, payload: { tagsList, count, isFirstTimeLoad } };
};

export const setIsLoading = (data) => {
    return { type: tagsActions.SET_IS_LOADING, payload: data };
};

export const setTagsDeleting = (data) => {
    return { type: tagsActions.SET_DELETING_TAGS, payload: data };
};

export const removeTags = (data) => {
    return { type: tagsActions.DELETE_TAGS, payload: data }
}

export const addTags = (data) => {
    return { type: tagsActions.ADD_TAGS, payload: data };
};

export const addTagsCompleted = (data) => {
    return { type: tagsActions.ADD_TAGS_COMPLETED, payload: data };
};

export const setEditTags = (id) => {
    return async (dispatch, getState) => {
        await dispatch({ type: tagsActions.SET_EDIT_TAGS, payload: id })
    }
}

export const updateTagsList = (data) => {
    return { type: tagsActions.UPDATE_TAGS_LIST, payload: data }
}

export const listTags = (currentPage) => {
    return async (dispatch, getState) => {
        try {
            const { tags } = getState();
            const { tagsData, perPage } = tags || {};
            let flag = true;

            const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
                list: tagsData,
                currentPage,
                perPage,
                flag
            });
            const endpoint = `${pageUrl}/list`;
            if (shouldFetch) {
                try {
                    dispatch(setIsLoading(true));

                    const response = await apiRequest('get', endpoint, { params });

                    const responseData = response.data || {};
                    const { data, count } = responseData;

                    if (Array.isArray(data)) {
                        dispatch(setTagsData({ tagsList: data, count, isFirstTimeLoad }));
                    } else {
                        console.error('Error fetching tags: data is not iterable');
                    }

                    dispatch(setIsLoading(false));
                } catch (err) {
                    console.error('Error fetching tags:', err);
                    dispatch(setIsLoading(false));
                }
            }
        } catch (error) {
            console.error('Error in listTags:', error);
            dispatch(setIsLoading(false));
        }
    };
};

export const addTag = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();

        if (auth.user.token) {
            const endpoint = `${pageUrl}/create`;

            try {
                const { data } = await apiRequest(
                    'post',
                    endpoint,
                    {
                        formData: value,
                        token: auth.user.token
                    }
                );

                const { status } = data;

                if (status === "Created") {
                    await dispatch(addTags(data.data));

                    setTimeout(async () => {
                        await dispatch(addTagsCompleted(data.data));
                    }, delay3Seconds);

                    return data;
                }
            } catch (error) {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false));
                }, delay3Seconds);
                return error;
            }
        }
    }
}


export const deleteTags = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value}/remove`;
            await dispatch(setTagsDeleting(value))
            await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            await dispatch(removeTags(value))
            await dispatch(setTagsDeleting(0))
        }
    }
}

export const editTags = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value.id}/update`;
            const { data } = await apiRequest(
                'put',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { interest, updated } = data
            if (updated) {
                await dispatch(updateTagsList(interest))
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return updated
        }
    }
}
