import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../../routes/homeRoutes';
import LeftNav from './LeftNav';

const Layout = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="row">
      {auth.isLoggedIn && (
        <div className="col-md-3 col-lg-2">
          <LeftNav />
        </div>
      )}
      <div className={auth.isLoggedIn ? 'col-md-9 col-lg-10' : 'col-12'}>
        <Switch>
          {routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(routeParams) => (
                <route.component {...(route.props || {})} {...routeParams} />
              )}
            />
          ))}
          <Redirect to="/panel" />
        </Switch>
      </div>
    </div>
  );
};

export default Layout;
