import { ethnicActions } from '../actions/ethnicActions';

const initialState = {
  subEthnicData: [],
  isLoading: false,
  deletingSubEthnic: 0,
  alreadyExist: false,
  editData: {},
  total: 0,
  perPage: 5,
  ethnicData: []
};

export const ethnicReducer = (state = initialState, { type, payload }) => {
  let subEthnicData;
  switch (type) {
    case ethnicActions.SET_SUB_ETHNIC_DATA:
      subEthnicData = payload.isFirstTimeLoad
        ? payload.subEthnicList
        : [...state.subEthnicData, ...payload.subEthnicList];
      return { ...state, subEthnicData, total: payload.count };

    case ethnicActions.SET_ALREADY_EXIST:
      return { ...state, alreadyExist: payload };
    case ethnicActions.SET_IS_LOADING:
      return { ...state, isLoading: payload };

    case ethnicActions.SET_DELETING_SUB_ETHNIC:
      return { ...state, deletingSubEthnic: payload };

    case ethnicActions.ADD_SUB_ETHNIC:
      payload.addedNow = true;
      return {
        ...state,
        subEthnicData: [payload, ...state.subEthnicData],
        total: state.total + 1,
      };

    case ethnicActions.DELETE_SUB_ETHNIC:
      const subEthnicArray = [...state.subEthnicData];
      const updatedSubEthnicData = subEthnicArray.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        subEthnicData: updatedSubEthnicData,
        total: state.total - 1,
      };

    case ethnicActions.SET_EDIT_SUB_ETHNIC: {
      const index = state.subEthnicData.findIndex(
        (item) => {
          return item.id.toString() === payload.toString()
        }
      );
      return { ...state, editData: state.subEthnicData[index] };
    }
    case ethnicActions.UPDATE_SUB_ETHNIC_LIST: {
      const data = [...state.subEthnicData];
      const index = data.findIndex((item) => item.id === payload.id);
      if (index !== undefined) {
        data[index] = payload;
      }
      return { ...state, subEthnicData: data };
    }
      
    case ethnicActions.ADD_ETHNIC_DATA:
      return { ...state, ethnicData: payload }
    
    case ethnicActions.EMPTY_EDIT_DATA:
      return {...state, editData: {}}

    default:
      return state;
  }
};
