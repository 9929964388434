import { userActions } from '../actions/userActions';

export const initialState = {
  list: [],
  errors: { listError: null },
  total: 0,
  isLoading: false,
  perPage: 10,
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case userActions.USERS_LOADING:
      return { ...state, isLoading: payload.isLoading };
    case userActions.SET_USERS:
      const list = [...state.list, ...payload.users];
      return {
        ...state,
        list,
        total: payload.count,
      };
    case userActions.SET_USERS_ERROR:
      return {
        ...state,
        error: { ...state.error, listError: payload.error },
      };
    default:
      return state;
  }
};
