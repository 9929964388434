import { authActions } from '../actions/authActions';

export const initialState = {
  isLoggedIn: false,
  user: {
    id: '',
    token: '',
    Email: '',
  },
  errors: {
    autoLoginError: false,
  },
};

const setToken = (payload) => {
  let token = sessionStorage.getItem('token') || localStorage.getItem('token');
  if (payload.token) {
    token = payload.token;
    if (payload.storeToken) {
      localStorage.setItem('token', token);
    }
    sessionStorage.setItem('token', token);
  }
  return token;
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.LOGOUT:
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      return initialState;
    case authActions.SET_USER:
      const user = action.payload.user;
      const token = setToken(action.payload);
      const auth = {
        ...state,
        isLoggedIn: true,
        user: { ...user, token },
        errors: { ...initialState.errors },
      };
      return auth;
    case authActions.AUTO_LOGIN_ERROR:
      return { ...state, errors: { ...state.errors, autoLoginError: true } };
    default:
      return state;
  }
};
