import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InterestTag.css';
import queryString from 'query-string';

const InterestTagRow = ({interestTag, idx, startIndex, onDeleteClick}) => {
  const location = useLocation();
  const { page = 1 } = queryString.parse(location.search);
  if (!startIndex) {
    startIndex = (page - 1) * 10;
  }
  if (!interestTag || !interestTag.id) return null;
  const pageUrl = `/panel/interest-tags`;
  const {id, tagName, postCount, isManual, createdAt, relatedKeywords = []} = interestTag

  return (<tr key={`row-${id}`}>
      <td>{startIndex + 1 + idx}</td>
      <td>{id}</td>
      <td>{tagName}</td>
      <td align="center">{isManual ? 'True' : 'False'}</td>
      <td align="center">{postCount === 0 ? '-' : postCount}</td>
      <td className="date">{createdAt && createdAt.substring(0, 16).replace('T', ' ')}</td>
      <td className="interests"><ul>{relatedKeywords.map(item => <li key={item.id}>{item.keyword}</li>)}</ul></td>
      <td>
        <>
          <Link to={pageUrl + '/' + id + '/edit?page=' + page}>
                <i className="fas fa-edit text-blue"></i>
          </Link>
          <span
            onClick={() => onDeleteClick(interestTag)}
            className="remove ml-2"
          >
            <i className="fas fa-trash text-danger"></i>
          </span>
        </>
      </td>
    </tr>);
};

export default InterestTagRow;