import React from 'react';
import {NavLink} from 'react-router-dom';

const LeftNav = () => {
  return (
    <ul className="nav nav-pills flex-column">
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/communities"
        >
          Communities
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/metrics"
        >
          Metrics
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/promotional-codes"
        >
          Promotional codes
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/categories"
        >
          Categories
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/tags"
        >
          Super Tags
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/interests"
        >
          Interests
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/interest-tags"
        >
          Interest's Tags
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/sessions"
        >
          Sessions
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/users"
        >
          Users
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/savedsessions"
        >
          Saved Sessions
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/community"
        >
          Community
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/diem-metrics"
        >
          Diem Metrics
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/most-used-keywords"
        >
          Most used Keywords
        </NavLink>
      </li>
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/web-reports"
        >
          Web Reports
        </NavLink>
      </li>
      {/** Promotion item admin panel */}
      <li className="">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/panel/promotion-items"
        >
          Promotion Items
        </NavLink>
      </li>
    </ul>
  );
};

export default LeftNav;
