export const getPagingParams = ({ list, currentPage, perPage, flag }) => {
  const listLength = list.length;
  const firstItemNeeded = currentPage ? (currentPage - 1) * perPage + 1 : 1;
  const isFirstTimeLoad = !currentPage && listLength === 0;
  const isListNotEnough = currentPage && listLength < firstItemNeeded;
  const shouldFetch = isFirstTimeLoad || isListNotEnough;

  const pagesPerRequest = flag ? perPage : 10;
  const loadedPages = Math.ceil(listLength / perPage);
  const pagesToFetch = currentPage - loadedPages;
  const requestSetsToFetch = Math.ceil(pagesToFetch / pagesPerRequest);
  const params = currentPage
    ? {
        offset: listLength,
        perPage: requestSetsToFetch * pagesPerRequest * perPage,
      }
    : {};
  return { isFirstTimeLoad, shouldFetch, params };
};
