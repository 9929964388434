import { keywordActions } from '../actions/keywordAction';

export const initialState = {
  list: [],
  errors: { listError: null },
  total: 0,
  isLoading: false,
  perPage: 10,
};

export const keywordReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case keywordActions.KEYWORDS_LOADING:
      return { ...state, isLoading: payload.isLoading };
    case keywordActions.SET_KEYWORDS:
      const list = [...state.list, ...payload.keywords];
      return {
        ...state,
        list,
        total: payload.count,
      };
    case keywordActions.SET_KEYWORDS_ERROR:
      return {
        ...state,
        error: { ...state.error, listError: payload.error },
      };
    default:
      return state;
  }
};
