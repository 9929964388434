import React from 'react';

const InterestTagsSearch = ({handleSearch, setValueSearch, valueSearch}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  return (
    <div className="col-12 d-flex align-items-center">
      <h1>Interest's Tags</h1>
      <div className="input-group search-field mx-3 w-auto flex-fill">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          name="search"
          value={valueSearch}
          onKeyDown={handleKeyDown}
          onChange={(e) => setValueSearch(e.target.value)}
        />
        <div className="input-group-append" onClick={handleSearch}>
          <i className="fa fa-search input-group-text font-weight-bold"></i>
        </div>
      </div>
    </div>
  )
}

export default InterestTagsSearch;