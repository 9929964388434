import Layout from '../components/Main/Layout';
import Login from '../components/Main/Login';
import Logout from '../components/Main/Logout';
import { RedirectToAppPage } from '../components/Redirects/RedirectToApp';

export const routes = [
  {
    path: '/login',
    component: Login,
    props: {
      loginPage: true,
    },
  },
  {
    path: '/signup',
    component: Login,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/redirect',
    component: RedirectToAppPage,
  },
  {
    path: '/panel',
    component: Layout
  },
  {
    path: '/',
    exact: true,
    component: () => {
      window.location.href = 'https://askdiem.com/';
      return null;
    }
  },
];
