import { metricDimensions } from '../../utils/constants';
import { apiRequest, getErrorMsg } from '../../utils/requests';

const pageUrl = '/metrics';

export const metricsActions = {
  SET_DAILY_METRICS: 'METRICS:SET_DAILY_METRICS',
  SET_DAILY_METRICS_LOADING: 'METRICS:SET_DAILY_METRICS_LOADING',
  SET_DAILY_METRICS_ERROR: 'METRICS:SET_DAILY_METRICS_ERROR',
  SET_DAILY_METRICS_FROM_DATE: 'METRICS:SET_DAILY_METRICS_FROM_DATE',
  SET_DAILY_METRICS_TO_DATE: 'METRICS:SET_DAILY_METRICS_TO_DATE',
  SET_WEEKLY_METRICS: 'METRICS:SET_WEEKLY_METRICS',
  SET_WEEKLY_METRICS_LOADING: 'METRICS:SET_WEEKLY_METRICS_LOADING',
  SET_WEEKLY_METRICS_ERROR: 'METRICS:SET_WEEKLY_METRICS_ERROR',
  SET_WEEKLY_METRICS_FROM_DATE: 'METRICS:SET_WEEKLY_METRICS_FROM_DATE',
  SET_WEEKLY_METRICS_TO_DATE: 'METRICS:SET_WEEKLY_METRICS_TO_DATE',
  SET_MONTHLY_METRICS: 'METRICS:SET_MONTHLY_METRICS',
  SET_MONTHLY_METRICS_LOADING: 'METRICS:SET_MONTHLY_METRICS_LOADING',
  SET_MONTHLY_METRICS_ERROR: 'METRICS:SET_MONTHLY_METRICS_ERROR',
  SET_MONTHLY_METRICS_FROM_DATE: 'METRICS:SET_MONTHLY_METRICS_FROM_DATE',
  SET_MONTHLY_METRICS_TO_DATE: 'METRICS:SET_MONTHLY_METRICS_TO_DATE',
  SET_METRICS_HEADERS: 'METRICS:SET_METRICS_HEADERS',
  SET_DIEM_FEED_METRICS: 'METRICS:SET_DIEM_FEED_METRICS'
};

const setMetricsLoading = (isLoading, action) => {
  return {
    type: action,
    payload: { isLoading },
  };
};

const setMetricsFetchError = (error, action) => {
  return {
    type: action,
    payload: { error },
  };
};

const setMetricsData = (metrics, action) => {
  return { type: action, payload: { metrics } };
};

const setFeedMetrics = (diemMetrics) => {
  return {
    type: metricsActions.SET_DIEM_FEED_METRICS,
    payload: diemMetrics,
  };
};

const setMetricsHeaders = ({ reports }) => {
  return {
    type: metricsActions.SET_METRICS_HEADERS,
    payload: {
      headers: {
        dimensionHeaders: reports[0].dimensionHeaders,
        metricHeaders: reports[0].metricHeaders,
      },
    },
  };
};

const setMetricsLoadingAction = async ({
  isLoading = false,
  dispatch,
  dimension,
  useFilters,
}) => {
  if (!useFilters || dimension === metricDimensions.daily)
    await dispatch(
      setMetricsLoading(isLoading, metricsActions.SET_DAILY_METRICS_LOADING)
    );
  if (!useFilters || dimension === metricDimensions.weekly)
    await dispatch(
      setMetricsLoading(isLoading, metricsActions.SET_WEEKLY_METRICS_LOADING)
    );
  if (!useFilters || dimension === metricDimensions.monthly)
    await dispatch(
      setMetricsLoading(isLoading, metricsActions.SET_MONTHLY_METRICS_LOADING)
    );
};

const setMetricsFetchErrorAction = async ({
  error = null,
  dispatch,
  dimension,
  useFilters,
}) => {
  if (!useFilters || dimension === metricDimensions.daily)
    await dispatch(
      setMetricsFetchError(error, metricsActions.SET_DAILY_METRICS_LOADING)
    );
  if (!useFilters || dimension === metricDimensions.weekly)
    await dispatch(
      setMetricsFetchError(error, metricsActions.SET_WEEKLY_METRICS_LOADING)
    );
  if (!useFilters || dimension === metricDimensions.monthly)
    await dispatch(
      setMetricsFetchError(error, metricsActions.SET_MONTHLY_METRICS_LOADING)
    );
};

const setMetricsDataAction = async ({
  reports = [],
  dispatch,
  dimension,
  useFilters,
}) => {
  const data = useFilters && reports[0];
  if (!useFilters || dimension === metricDimensions.daily)
    await dispatch(
      setMetricsData(
        (data || reports[0]).rows,
        metricsActions.SET_DAILY_METRICS
      )
    );
  if (!useFilters || dimension === metricDimensions.weekly)
    await dispatch(
      setMetricsData(
        (data || reports[1]).rows,
        metricsActions.SET_WEEKLY_METRICS
      )
    );
  if (!useFilters || dimension === metricDimensions.monthly)
    await dispatch(
      setMetricsData(
        (data || reports[2]).rows,
        metricsActions.SET_MONTHLY_METRICS
      )
    );
};

export const fetchMetricsData = ({
  useFilters = false,
  dimension = metricDimensions.daily,
  to,
  from,
} = {}) => {
  return async (dispatch, getState) => {
    await setMetricsLoadingAction({
      isLoading: true,
      dispatch,
      dimension,
      useFilters,
    });

    try {
      const { auth } = getState();
      let params = {};
      if (useFilters) {
        params = {
          from: new Date(from).toISOString(),
          to: new Date(to).toISOString(),
          dimension,
        };
      }
      const { data } = await apiRequest('get', `${pageUrl}`, {
        token: auth.user.token,
        params,
      });
      const { reports } = data;
      await setMetricsFetchErrorAction({
        error: null,
        dispatch,
        dimension,
        useFilters,
      });
      if (reports) {
        await dispatch(setMetricsHeaders({ reports }));
        await setMetricsDataAction({
          reports,
          dispatch,
          dimension,
          useFilters,
        });
      }
    } catch (err) {
      await setMetricsFetchErrorAction({
        error: getErrorMsg(err),
        dispatch,
        dimension,
        useFilters,
      });
    }
    await setMetricsLoadingAction({
      isLoading: false,
      dispatch,
      dimension,
      useFilters,
    });
  };
};

export const fetchFeedMetrics = (payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const apiUrl = `/feed/metrics`;
    if (auth && auth.user.token) {
      try {
        const { data } = await apiRequest('post', apiUrl, {
          token: auth.user.token,
          formData: payload
        });
        if (data) {
          console.log(data);
          await dispatch(
            setFeedMetrics({
              diemMetrics: data,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
};
