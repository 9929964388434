import * as Yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import ErrorMessage from '../Utilities/ErrorMessage';
import InterestTagsList from '../InterestTags/InterestTagsList';
import {
  addInterestTag,
} from '../../store/actions/interestTagAction';


const InterestTagCreate = () => {
  const dispatch = useDispatch();
  const initialValues = {
    tagName: '',
  };
  const alreadyExist = useSelector((state) => state.interestTags.alreadyExist);
  const history = useHistory();

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.tagName = values.tagName;
      let response = await dispatch(addInterestTag(data));
      response && resetForm();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    tagName: Yup.string().required('Tag name is required'),
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Create Interest's Tag</h1>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name">Tag name</label>
            <input
              name="tagName"
              id="tagName"
              value={formik.values.tagName}
              placeholder="Enter the tag name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="tagName" formik={formik} />
          </div>
        </div>
        {alreadyExist && (
          <div>
            <Alert severity="error">
              The tag given already exists! Try change it
            </Alert>
            <br />
          </div>
        )}
        <div className="d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
      <br></br>
      <div>
        <InterestTagsList />
      </div>
    </div>
  );
};

export default InterestTagCreate;
