import inflection from 'inflection';

export const sanitizeResponse = (respObj) => {
  for (const key in respObj) {
    if (respObj[key] === null || respObj[key] === undefined) {
      respObj[key] = '';
    }
  }
  return respObj;
};

export const trimString = (str, max) =>
  str && str.length > max ? str.substr(0, max - 3) + '...' : str;

export const getUserName = (
  user,
  { returnType = 'email', trimEmail = false } = {}
) => {
  const fullName = [user.FirstName, user.LastName]
    .filter((w) => w)
    .join(' ')
    .trim();
  const username = user.username;
  let email = user.Email || '';
  const generatedMailSuffix = '@privaterelay.appleid.com';
  const isEmailGenerated = email.includes(generatedMailSuffix);
  email = email.replace(generatedMailSuffix, '');
  let finalName;
  switch (returnType) {
    case 'username':
      finalName = username || fullName || email;
      break;
    case 'name':
      finalName = fullName || username || email;
      break;
    default:
      finalName = (!isEmailGenerated && email) || username || fullName || email;
  }
  return !trimEmail ? finalName : finalName.replace(/@.*$/i, '');
};

export const getTimeParts = (duration, fullForms = false) => {
  duration = Number(duration);
  const oneDay = 24 * 60 * 60;
  const oneHour = 60 * 60;
  const oneMinute = 60;
  const days = Math.floor(duration / oneDay);
  const hours = Math.floor((duration % oneDay) / oneHour);
  const minutes = Math.floor(((duration % oneDay) % oneHour) / oneMinute);
  const seconds = Math.floor(((duration % oneDay) % oneHour) % oneMinute);
  let stringValue = '';
  if (days > 0)
    stringValue += fullForms
      ? `${days} ${inflection.inflect('day', days)} `
      : `${days}d `;
  if (hours > 0)
    stringValue += fullForms
      ? `${hours} ${inflection.inflect('hour', hours)} `
      : `${hours}h `;
  if (minutes > 0)
    stringValue += fullForms
      ? `${minutes} ${inflection.inflect('minute', minutes)} `
      : `${minutes}m `;
  if (seconds > 0)
    stringValue += fullForms
      ? `${seconds} ${inflection.inflect('second', seconds)} `
      : `${seconds}s`;
  stringValue = stringValue.trim();
  return { days, hours, minutes, seconds, stringValue };
};