import axios from 'axios';
import { redirectDefaultPage } from './RedirectDefaultPage';

export const redirectLinkToDevice = () => {
  const API_URL = (process.env.REACT_APP_API_URL || '') + '/api';
  let url = window.location.href;
  axios
    .post(API_URL + `/promocodes/generatelink`, {
      url: url,
    })
    .then((response) => {
      const { data = {} } = response;
    window.location.href = data?.shortLink;
    })
    .catch((err) => {
      console.log(err);
      redirectDefaultPage();
    });
};
