import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import '../Utilities/CommonStyles.css';
import {
    deletePromotionItem, showOrHidePromotion, upAnDownPriority
} from "../../store/actions/PromotionAction";
import Paginate from "../Utilities/Paginate";
import { useLocation, useHistory } from 'react-router-dom';
const promotionHeaders = ['S.No.', 'Title', 'Description', 'CTA', 'CTA url', 'Image', 'Background','Actions','Delete','Priority'];

const PromotionItemList = (props) => {
    const location = useLocation();
    const [pageUrl] = useState(location.pathname);
    const auth = useSelector((state) => state.auth);
    const totalItems = useSelector((state) => state.promotions.total);
    const perPage = useSelector((state) => state.promotions.perPage);
    const isLoading = useSelector((state) => state.promotionItem.isLoading);
    const [show, setShow] = useState(false);
    const [deleteData, setDeleteData] = useState();

    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState();

    const handlePageClick = (pageNum) => {
        history.push(`${pageUrl}?page=${pageNum}`);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get('page');
        setCurrentPage(page ? parseInt(page) : 1);
    }, [location.search]);

    const handleDelete = async (params) => {
        const { id } = params;
        if (auth.user.token) {
            await dispatch(deletePromotionItem(id))
            setShow(false)
        }
    };

    const handleShoeOrHide = async (id, isVisible) => {
        if (auth.user.token) {
            await dispatch(showOrHidePromotion(id, isVisible))
        }
    }

    const handlePriority = async (id, priority) => {
        if (auth.user.token) {
            await dispatch(upAnDownPriority(id, priority))
        }
    }

    const promotionItemRow = (data, i) => {
        return (
            <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
                <td>{1 + i}</td>
                <td>{data?.title}</td>
                <td>{data?.description}</td>
                <td>{data?.cta}</td>
                <td>{data?.ctaUrl}</td>
                <td>
                    <img
                        className="img-fluid"
                        src={data?.imageUrl}
                        alt={data?.title}
                        style={{ width: 25, height: 25 }}
                    />
                </td>
                <td>
                    <span>{data?.isGradient ? "Gradient" : "White"}</span>
                </td>
                <td>
          <span
              onClick={() => {
                  handleShoeOrHide(data?.id, !data?.isVisible)
              }}
              className="remove ml-4"
          >
            <i className={`fa ${data?.isVisible ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true"></i>
          </span>
                </td>
                <td>
          <span
              onClick={() => {
                  setDeleteData({ id: data?.id, message: `the "${data?.title}" promotion item` });
                  setShow(!show);
              }}
              className="remove ml-4"
          >
            <i className="fas fa-trash text-danger"></i>
          </span>
                </td>
                <td>
                              <span
                                  onClick={() => {
                                      handlePriority(data?.id, !data?.priority)
                                  }}
                                  className="ml-4"
                              >
            <i className={`fa ${data?.priority ? 'fa-arrow-up' : 'fa-arrow-down text-danger'}`} aria-hidden="true"></i>
          </span>
                </td>
            </tr>
        );
    };

    return (
        <div className="p-3">
            <div className="row">
                {isLoading ? (
                    <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
                ) : (
                    <>
                        <ConfirmationPopup
                            show={show}
                            data={deleteData}
                            handleDelete={handleDelete}
                        />
                        <Table striped bordered hover responsive={true}>
                            <thead>
                            <tr>
                                {promotionHeaders.map((header) => (
                                    <th key={header}>{header}</th>
                                ))}
                            </tr>
                            </thead>
                            {props.data && props.data.length > 0 && (
                                <tbody>
                                {props.data.map(promotionItemRow)}
                                </tbody>
                            )}
                        </Table>
                        {props.data && (
                          <Paginate
                            totalItems={totalItems}
                            currentPage={currentPage}
                            perPage={perPage}
                            pageUrl={pageUrl}
                            handleClick={handlePageClick}
                          />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PromotionItemList;
