import { delay3Seconds } from '../../utils/constants';
import { apiRequest } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = "/category"

export const categoryActions = {
    SET_CATEGORY_DATA: 'SET_CATEGORY_DATA',
    SET_IS_LOADING: 'SET_IS_LOADING',
    ADD_CATEGORY: 'ADD_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    SET_DELETING_CATEGORY: 'SET_DELETING_CATEGORY',
    CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
    SET_EDIT_CATEGORY: 'SET_EDIT_CATEGORY',
    UPDATE_CATEGORY_LIST: 'UPDATE_CATEGORY_LIST',
    ADD_CATEGORY_COMPLETED: 'ADD_CATEGORY_COMPLETED',
    SET_ALREADY_EXIST: 'SET_ALREADY_EXIST'
}

export const setAlreadyExist = (data) => {
    return { type: categoryActions.SET_ALREADY_EXIST, payload: data };
};

export const setCategoryData = ({ categoryList, count, isFirstTimeLoad = true }) => {
    return { type: categoryActions.SET_CATEGORY_DATA, payload: { categoryList, count, isFirstTimeLoad } };
};

export const setIsLoading = (data) => {
    return { type: categoryActions.SET_IS_LOADING, payload: data };
};

export const setCategoryDeleting = (data) => {
    return { type: categoryActions.SET_DELETING_CATEGORY, payload: data };
};

export const removeCategory = (data) => {
    return { type: categoryActions.DELETE_CATEGORY, payload: data }
}

export const addCategory = (data) => {
    return { type: categoryActions.ADD_CATEGORY, payload: data };
};

export const addCategoryCompleted = (data) => {
    return { type: categoryActions.ADD_CATEGORY_COMPLETED, payload: data };
};

export const setEditCategory = (categoryId) => {
    return async (dispatch, getState) => {
        await dispatch({ type: categoryActions.SET_EDIT_CATEGORY, payload: categoryId })
    }
}
export const checkAlreadyExist = (data) => {
    return { type: categoryActions.CHECK_ALREADY_EXIST, payload: data };
};

export const updateCategoryList = (data) => {
    return { type: categoryActions.UPDATE_CATEGORY_LIST, payload: data }
}

export const listCategories = (currentPage) => {
    return async (dispatch, getState) => {
        const { auth, categories } = getState();
        const { categoryData, perPage } = categories;
        let flag = true;
        const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
            list: categoryData,
            currentPage,
            perPage,
            flag
        });
        const endpoint = `${pageUrl}/list`;
        if (shouldFetch) {
            await dispatch(setIsLoading(true))
            const { data } = await apiRequest(
                'get',
                endpoint,
                {
                    token: auth.user.token,
                    params
                }
            );
            const { categoryList, count } = data;
            await dispatch(setCategoryData({ categoryList, count, isFirstTimeLoad }))
            await dispatch(setIsLoading(false))
        }
    }
}

export const addCategories = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/create`;
            const { data } = await apiRequest(
                'post',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { category, created } = data;
            if (created) {
                await dispatch(addCategory(category))
                setTimeout(async () => {
                    await dispatch(addCategoryCompleted(category))
                }, delay3Seconds)
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return created;
        }
    }
}

export const deleteCategory = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value}/remove`;
            await dispatch(setCategoryDeleting(value))
            await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            await dispatch(removeCategory(value))
            await dispatch(setCategoryDeleting(0))
        }
    }
}

export const editCategory = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value.id}/update`;
            const { data } = await apiRequest(
                'put',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { category, updated } = data
            if (updated) {
                await dispatch(updateCategoryList(category))
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return updated
        }
    }
}