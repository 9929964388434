import { apiRequest, getErrorMsg } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = '/users';

export const userActions = {
  SET_USERS: 'SET_USERS',
  SET_USERS_ERROR: 'SET_USERS_ERROR',
  USERS_LOADING: 'USERS_LOADING',
};

export const setLoading = (isLoading) => {
  return { type: userActions.USERS_LOADING, payload: { isLoading } };
};

export const setUsers = ({ users, count }) => {
  return {
    type: userActions.SET_USERS,
    payload: { users, count },
  };
};

export const setUsersError = (error) => {
  return {
    type: userActions.SET_USERS_ERROR,
    payload: { error },
  };
};

export const setUsersAction = (currentPage) => {
  return async (dispatch, getState) => {
    try {
      const { users, auth } = getState();
      const { list, perPage } = users;
      const { shouldFetch, params } = getPagingParams({
        list,
        currentPage,
        perPage,
      });
      if (shouldFetch) {
        await dispatch(setLoading(true));
        const response = await apiRequest('get', pageUrl, {
          token: auth.user.token,
          params,
        });
        const { users, count } = response.data;
        await dispatch(setUsers({ users, count }));
        await dispatch(setUsersError(null));
      }
    } catch (err) {
      await dispatch(setUsersError(getErrorMsg(err)));
    }
    await dispatch(setLoading(false));
  };
};
