import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { setSubmitError } from '../../utils/requests';
import { sanitizeResponse } from '../../utils/helpers';
import ImageUpload, {uploadImageToS3} from '../Utilities/ImageUpload';
import ErrorMessage from '../Utilities/ErrorMessage';
import {
  addEditCommunityAction,
  setCommunityAction,
} from '../../store/actions/communityActions';
import { icon, checkedIcon } from "../common/CommonComponents";


const CommunityCreate = (props) => {
  const auth = useSelector((state) => state.auth);
  const community = useSelector((state) => state.communities.current);
  const keywords = useSelector((state) => state.keywords.list);
  const isLoading = useSelector((state) => state.communities.isCurrentLoading);

  const { path } = useRouteMatch();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [prevImg, setPrevImg] = useState('');
  const [uploadedImg, setUploadedImg] = useState();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [copied, setCopied] = useState(false);

  const isCreatePage = useMemo(() => path.includes('create'), [path]);
  const pageAction = isCreatePage ? 'Create' : 'Edit';

  const initialValues = {
    id: '',
    name: '',
    imageUrl: '',
    guidelines: '',
    description: '',
    shortDescription: '',
    isActive: true,
    isPrivate: false,
    color: '#e1d8eb',
    website: '',
  };

  const handleSumbit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      setErrors({ submitError: '' });
      const imageUrl = await uploadImageToS3(uploadedImg, auth?.user?.token)
      if (imageUrl) {
        setFieldValue('imageUrl', imageUrl);
        values = { ...values, imageUrl };
      }
      values.keywords = selectedKeywords.map((keyword) => keyword.id);
      await dispatch(
        addEditCommunityAction({
          values,
          isCreatePage,
          onSuccess: async (community) => {
            setSubmitting(false);
            if (community) {
              try {
                history.goBack();
              } catch (err) {
                history.push('/communities');
              }
            }
          },
          onError: async (err) => {
            setSubmitError(err, setErrors);
            setSubmitting(false);
          },
        })
      );
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Community Name is required'),
    imageUrl: Yup.string().url('Upload a valid image'),
    website: Yup.string().url('Enter a valid website'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleSumbit,
    validationSchema,
  });

  const { setValues, setFieldValue } = formik;

  const fetchInfo = useCallback(async () => {
    if (!isCreatePage && params.id) {
      dispatch(setCommunityAction(+params.id));
    }
  }, [params, isCreatePage, dispatch]);

  useEffect(() => {
    if (community && !isCreatePage) {
      sanitizeResponse(community);
      setValues(community);
      const { imageUrl, keywords } = community;
      setPrevImg(imageUrl || '');
      setSelectedKeywords(keywords || []);
    }
  }, [community, isCreatePage, setValues]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  const setImageUrl = (imageUrl) => {
    setFieldValue('imageUrl', imageUrl);
  };

  let copyTimeout;
  const handleDeepLinkCopy = () => {
    setCopied(true);
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => setCopied(false), 2000);
  };

  return (
      <div>
        <h1>{pageAction} Community</h1>
        {isLoading ? (
          <div className="fa-3x d-flex justify-content-center">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <form className="needs-validation" onSubmit={formik.handleSubmit}>
            {!isCreatePage && community && community.dynamicLink && (
              <>
                <CopyToClipboard
                  text={community.dynamicLink}
                  onCopy={handleDeepLinkCopy}
                >
                  <div className="form-group input-group" title="click to copy">
                    <div className="input-group-append">
                      {copied && (
                        <span className="input-group-text text-white bg-success">
                          Copied to clipboard!
                        </span>
                      )}
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={community.dynamicLink}
                      readOnly
                    />
                    <div className="input-group-append">
                      <i className="input-group-text fas fa-copy"></i>
                    </div>
                  </div>
                </CopyToClipboard>
              </>
            )}
            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="name">Community Name:</label>
                <input
                  name="name"
                  id="name"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <ErrorMessage name="name" formik={formik}/>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="color">Color:</label>
                <input
                  type="color"
                  name="color"
                  id="color"
                  className="form-control col-md-2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.color}
                />
                <ErrorMessage name="color" formik={formik}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex flex-column no-gutters">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    name="description"
                    id="description"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  />
                  <ErrorMessage name="description" formik={formik}/>
                </div>
                <div className="form-group col-12 col-md-6">
                  <Autocomplete
                    multiple
                    id="keywords"
                    options={keywords}
                    disableCloseOnSelect
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                      option.keyword + ' ' + option.emoji
                    }
                    getOptionSelected={(option, value) => {
                      return option.id === value.id;
                    }}
                    onChange={(e, value) => setSelectedKeywords(value)}
                    renderOption={(option, {selected}) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.keyword} {option.emoji}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Keywords"
                        placeholder="Interests"
                      />
                    )}
                    value={selectedKeywords}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 order-lg-2 mb-4">
                <ImageUpload
                  prevImg={prevImg}
                  setFieldValue={setImageUrl}
                  imgAlt={formik.values.name}
                  className="mb-4"
                  setUploadedImg={setUploadedImg}
                >
                  <ErrorMessage name="imageUrl" formik={formik}/>
                </ImageUpload>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column mb-4">
                <div className="form-group mt-auto">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        name="isPrivate"
                        id="isPrivate"
                        className="form-check-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isPrivate}
                        disabled
                      />
                      Private Community
                    </label>
                  </div>
                  <ErrorMessage name="isPrivate" formik={formik} />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        name="isActive"
                        id="isActive"
                        className="form-check-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isActive}
                      />
                      Active
                    </label>
                  </div>
                  <ErrorMessage name="isActive" formik={formik} />
                </div>
                {formik.errors.submitError && (
                  <div className="alert alert-danger">
                    {formik.errors.submitError}
                    {formik.errors}
                  </div>
                )}
                <div className="d-flex">
                  <button
                    className="btn btn-success mr-5"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {isCreatePage ? 'Create' : 'Update'}
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
  );
};

export default CommunityCreate;
