import React, { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import {
  addPromoCodes,
  listPromoCodes,
} from '../../store/actions/promoActions';
import ErrorMessage from '../Utilities/ErrorMessage';
import PromotionalCodesList from './PromotionalCodeList';

const PromotionalCode = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: '720px',
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = {
    hostName: '',
    promoToken: '',
  };
  const promoData = useSelector((state) => state.promotions.promoData);
  const auth = useSelector((state) => state.auth);
  const alreadyExist = useSelector((state) => state.promotions.alreadyExist);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(listPromoCodes(1));
    }
  }, [dispatch, auth.user.token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.hostName = values.hostName;
      data.code = values.promoToken;
      let response = await dispatch(addPromoCodes(data));
      response && resetForm();
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  };
  const validationSchema = Yup.object().shape({
    hostName: Yup.string().required('host name is required'),
    promoToken: Yup.string().max(6, 'Max 6 characters')
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });
  const { setValues, setFieldValue } = formik;

  return (
    <div className={classes.root}>
      <div className="card shadow p-5">
        <h1>Create Promo code</h1>
        <form className="needs-validation" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="name">Host name:</label>
              <input
                name="hostName"
                id="hostName"
                value={formik.values.hostName}
                placeholder="Enter the host name"
                onChange={formik.handleChange}
                className="form-control"
              />
              <ErrorMessage name="hostName" formik={formik} />
            </div>
            <div className="form-group col-12 col-md-6">
              <label htmlFor="name">Promo token:</label>
              <input
                name="promoToken"
                id="promoToken"
                value={formik.values.promoToken}
                placeholder="Enter the promo token for the host"
                onChange={formik.handleChange}
                className="form-control"
              />
              <ErrorMessage name="promoToken" formik={formik} />
            </div>
          </div>
          {alreadyExist && (
            <div>
              <Alert severity="error">
                The promo code given already exists! try changing it
              </Alert>
              <br></br>
            </div>
          )}
          <div className="d-flex">
            <button
              className="btn btn-success mr-5"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Create
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => history.goBack()}
              disabled={formik.isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
        <br></br>
        {promoData && promoData.length > 0 && (
          <div>
            <h2>Promo Code list</h2>
            <PromotionalCodesList data={promoData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionalCode;
