import { apiRequest } from '../../utils/requests';

const pageUrl = '/report';

export const reportActions = {
  SET_REPORT_DATA: 'SET_REPORT_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_DELETING_REPORT: 'SET_DELETING_REPORT',
  UPDATE_REPORT_DATA: 'UPDATE_REPORT_DATA',
};

//new web app reports
export const setIsLoading = (data) => {
  return { type: reportActions.SET_IS_LOADING, payload: data };
};

export const setReportData = ({
  reports,
  count,
}) => {
  return {
    type: reportActions.SET_REPORT_DATA,
    payload: { reports, count },
  };
};

export const setDeletingReport = (data) => {
  return { type: reportActions.SET_DELETING_REPORT, payload: data };
};

export const updateReportList = (data) => {
  return { type: reportActions.UPDATE_REPORT_DATA, payload: data };
};

//new web app reports
export const listWebReports = (currentPage) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const params = {
      offset: currentPage * 10 - 10,
      perPage: 10
    }
    const endpoint = `${pageUrl}/v2/list`;
    await dispatch(setIsLoading(true));
    const { data } = await apiRequest('get', endpoint, {
      token: auth.user.token,
      params,
    });
    const { reports, count } = data;
    await dispatch(setReportData({ reports, count }));
    await dispatch(setIsLoading(false));
  };
};

export const deleteNewReport = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    try {
      if (auth.user.token) {
        const mode = value?.typeofContent;
        const isActivity = ['activity', 'activities'].includes(mode)
        let activityId =  isActivity ? value?.feedactivityId : value?.feedactivitycontributionId;
        const endpoint = `/feed/${mode}/${activityId}/delete?reportId=${value?.id}`;
        await dispatch(setDeletingReport(value));
        let body = {};
        const { data } = await apiRequest('delete', endpoint, {
          formData: body,
          token: auth.user.token,
        });
        if (data) {
          await dispatch(
            updateReportList({
              ...value,
              status: 'DELETED',
            })
          );
          await dispatch(setDeletingReport(0));
        }
      }
    } catch (error) {
      console.log(error);
      await dispatch(setDeletingReport(0));
    }
  };
};

export const modifyNewReport = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    try {
      if (auth.user.token && value?.id) {
        const endpoint = `report/v2/${value?.id}/resolve`;
        await dispatch(setDeletingReport(value));
        const { data } = await apiRequest('patch', endpoint, {
          token: auth.user.token,
        });
        if (data) {
          await dispatch(
            updateReportList({
              ...value,
              status: 'RESOLVED',
            })
          );
          await dispatch(setDeletingReport(0));
        }
      }
    } catch (error) {
      console.log(error);
      await dispatch(setDeletingReport(0));
    }
  };
};

export const deleteNewReportAndBlockUser = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();

    try {
      if (auth.user.token) {
        let mode =
          value?.typeofContent === 'contribution'
            ? 'contributions'
            : 'activities';
        let activityId =
          value?.typeofContent === 'contribution'
            ? value?.feedactivitycontributionId
            : value?.feedactivityId;
        const endpoint = `/feed/${mode}/${activityId}/delete?reportId=${value?.id}`;
        await dispatch(setDeletingReport(value));
        let body = {};
        await apiRequest('delete', endpoint, {
          formData: body,
          token: auth.user.token,
        });
        if (value?.reportPostOwner) {
          const userDeletionEndpoint = `/users/${value?.reportPostOwner?.id}/v2/delete?reportId=${value?.id}`;
          const { data } = await apiRequest('delete', userDeletionEndpoint, {
            token: auth.user.token,
          });
          const { report: userReport } = data;
          await dispatch(updateReportList(userReport));
          await dispatch(setDeletingReport(0));
        } else {
          await dispatch(
            updateReportList({
              ...value,
              status: 'BLOCKED',
            })
          );
          await dispatch(setDeletingReport(0));
        }
      }
    } catch (error) {
      console.log(error);
      await dispatch(setDeletingReport(0));
    }
  };
};
