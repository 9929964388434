import * as Yup from 'yup';
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import ErrorMessage from '../Utilities/ErrorMessage';
import {
  addTag,
} from '../../store/actions/tagsActions';
import TagsList from "./TagsList";

const TagsCreate = () => {
  const dispatch = useDispatch();
  const initialValues = {
    tagName: '',
    description: '',
    imageCover: '',
    imageHeader: '',
  };
  const alreadyExist = useSelector((state) => state.interests.alreadyExist);
  const history = useHistory();

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.tagName = values.tagName;
      data.description = values.description;
      data.imageCover = values.imageCover;
      data.imageHeader = values.imageHeader;
      let response = await dispatch(addTag(data));
      response && resetForm();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    tagName: Yup.string().required('Tag name is required'),
    description: Yup.string().required('Tag description is required'),
    imageCover: Yup.string().required('The image cover for tag is required'),
    imageHeader: Yup.string().required('The image header for tag is required'),
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Create Super Tags</h1>
      <br></br>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name"> Tag name</label>
            <input
              name="tagName"
              id="tagName"
              value={formik.values.tagName}
              placeholder="Enter the tag name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="tagName" formik={formik} />
          </div>
          <div className="form-group col-3 col-md-6">
            <label htmlFor="name">Tag Description</label>
            <input
              name="description"
              id="description"
              value={formik.values.description}
              placeholder="Enter the tag description value"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="description" formik={formik} />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name"> Image cover</label>
            <input
                name="imageCover"
                id="imageCover"
                value={formik.values.imageCover}
                placeholder="Enter the image cover url"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="imageCover" formik={formik} />
          </div>
          <div className="form-group col-3 col-md-6">
            <label htmlFor="name"> Image header</label>
            <input
                name="imageHeader"
                id="imageHeader"
                value={formik.values.imageHeader}
                placeholder="Enter the image header url"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="imageHeader" formik={formik} />
          </div>
        </div>
        <br></br>
        {alreadyExist && (
          <div>
            <Alert severity="error">
              The tag given already exists! try changing it
            </Alert>
            <br></br>
          </div>
        )}
        <br></br>
        <div className="d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
      <br></br>
      <div>
        <h2>Super Tags List</h2>
        <TagsList />
      </div>
    </div>
  );
};

export default TagsCreate;
