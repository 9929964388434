import inflection from 'inflection';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { metricDimensions } from '../../utils/constants';
import MetricsFilter from './MetricsFilter';
import MetricsRow from './MetricsRow';

const WeeklyMetrics = () => {
  const weekly = useSelector((state) => state.metrics.weekly);
  const headers = useSelector((state) => state.metrics.headers);
  const { metricHeaders = [] } = headers;
  const { metrics, isLoading } = weekly;

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <h1>Weekly Metrics</h1>
          <MetricsFilter dimension={metricDimensions.weekly} />
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {[{ name: '' }, ...metricHeaders].map(({ name, type }) => (
                    <th key={name}>
                      {inflection.transform(name, ['underscore', 'titleize'])}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ maxHeight: '425px' }}>
                {metrics.map((metrics, i) => (
                  <MetricsRow
                    metrics={metrics}
                    key={i}
                    dimension={metricDimensions.weekly}
                  />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default WeeklyMetrics;
