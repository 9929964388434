import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import Paginate from '../Utilities/Paginate';
import { trimString } from '../../utils/helpers';
import {
  setCommunitiesAction,
  setCommunitySearch,
} from '../../store/actions/communityActions';

const communityHeaders = [
  'S.No.',
  'Name',
  'Image',
  'Description',
  'Private',
  'Active',
  'Color',
];

const pageUrl = '/panel/communities';

const CommunityList = () => {
  const auth = useSelector((state) => state.auth);
  const communities = useSelector((state) => state.communities.list);
  const isLoading = useSelector((state) => state.communities.isLoading);
  const totalItems = useSelector((state) => state.communities.total);
  const perPage = useSelector((state) => state.communities.perPage);
  const search = useSelector((state) => state.communities.search);
  const [currentPage, setCurrentPage] = useState();

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(setCommunitiesAction(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const handleSearch = (e) => {
    dispatch(setCommunitySearch(e.target.value));
  };

  const filteredCommunities = useMemo(
    () =>
      search.trim()
        ? communities.filter((c) =>
            c.name.toLowerCase().includes(search.toLowerCase())
          )
        : communities,
    [search, communities]
  );

  const communityRow = (community, i) => (
    <tr key={community.id}>
      <td>{(currentPage - 1) * perPage + i + 1}</td>
      <td>{community.name}</td>
      <td>
        {community.imageUrl && (
          <img
            className="img-thumbnail thumb-img"
            src={community.imageUrl}
            alt={community.name}
          />
        )}
      </td>

      <td title={community.description}>
        {trimString(community.description, 140)}
      </td>
      <td className="text-center">
        <div
          className={
            'dot d-inline-block' +
            (community.isPrivate ? ' bg-success' : ' bg-danger')
          }
        ></div>
      </td>
      <td className="text-center">
        <div
          className={
            'dot d-inline-block' +
            (community.isActive ? ' bg-success' : ' bg-danger')
          }
        >{community.isActive}</div>
      </td>
      <td className="text-center">
        <div
          className="dot-lg d-inline-block"
          style={{ backgroundColor: community.color }}
        ></div>
      </td>
      <td>
        <Link to={pageUrl + '/' + community.id}>
          <i className="fas fa-edit"></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex align-items-center">
          <h1>Communities</h1>
          <div className="input-group search-field mx-3 w-auto flex-fill">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              name="search"
              value={search}
              onChange={handleSearch}
            />
            <div className="input-group-append">
              <i className="fa fa-search input-group-text font-weight-bold"></i>
            </div>
          </div>
          <Link to={pageUrl + '/create'} className="btn btn-primary ml-auto">
            Create Community
          </Link>
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {communityHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {filteredCommunities && filteredCommunities.length > 0 && (
                <tbody>
                  {filteredCommunities
                    .slice(startIndex, endIndex)
                    .map(communityRow)}
                </tbody>
              )}
            </Table>
            <Paginate
              totalItems={
                search.trim() ? filteredCommunities.length : totalItems
              }
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CommunityList;
