import * as Yup from 'yup';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import {
  addCategories,
  listCategories,
} from '../../store/actions/categoryActions';
import CategoryList from './CategoryList';
import ErrorMessage from '../Utilities/ErrorMessage';
import { icon, checkedIcon } from '../common/CommonComponents';
import { setKeywordsAction } from '../../store/actions/keywordAction';

const CategoryCreate = () => {
  const dispatch = useDispatch();
  const initialValues = {
    categoryName: '',
    fontColor: '#e1d8eb',
    backgroundColor: '#e1d8eb',
  };
  const categoryData = useSelector((state) => state.categories.categoryData);
  const auth = useSelector((state) => state.auth);
  const alreadyExist = useSelector((state) => state.categories.alreadyExist);
  const keywords = useSelector((state) => state.keywords.list);

  const useStyles = makeStyles((theme) => ({
    interest: {
      marginRight: '8px',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.name = values.categoryName;
      data.fontColor = values.fontColor;
      data.backgroundColor = values.backgroundColor;
      data.interest = selectedKeywords.map((interest) => interest.id);
      let response = await dispatch(addCategories(data));
      response && setSelectedKeywords([]) && resetForm();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setKeywordsAction());
      await dispatch(listCategories(1));
    }
  }, [dispatch, auth.user.token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 300,
  });

  const validationSchema = Yup.object().shape({
    categoryName: Yup.string().required('Category name is required'),
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Create Category</h1>
      <br></br>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name">Category name</label>
            <input
              name="categoryName"
              id="categoryName"
              value={formik.values.categoryName}
              placeholder="Enter the category name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="categoryName" formik={formik} />
          </div>
          <div className="form-group">
            <label htmlFor="color">Font color:</label>
            <input
              type="color"
              name="fontColor"
              id="fontColor"
              className="ml-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fontColor}
            />
            <ErrorMessage name="color" formik={formik} />
          </div>
          <div className="form-group pl-3">
            <label htmlFor="color">Background color:</label>
            <input
              type="color"
              name="backgroundColor"
              id="backgroundColor"
              className="ml-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.backgroundColor}
            />
            <ErrorMessage name="color" formik={formik} />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name">Interests: </label>
            <Autocomplete
              multiple
              id="keywords"
              className={classes.interest}
              options={keywords}
              disableCloseOnSelect
              filterOptions={filterOptions}
              filterSelectedOptions
              getOptionLabel={(option) => option.keyword + ' ' + option.emoji}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e, value) => setSelectedKeywords(value)}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.keyword} {option.emoji}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Keywords"
                  placeholder="Interests"
                />
              )}
              value={selectedKeywords}
            />
          </div>
        </div>
        {alreadyExist && (
          <div>
            <Alert severity="error">
              The category given already exists! try changing it
            </Alert>
            <br></br>
          </div>
        )}
        <br></br>
        <div className="d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
      <br></br>
      {categoryData && categoryData.length > 0 && (
        <div>
          <h2>Categories List</h2>
          <CategoryList data={categoryData} />
        </div>
      )}
    </div>
  );
};

export default CategoryCreate;
