import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import Paginate from '../Utilities/Paginate';
import {
  deleteInterest,
  listInterests,
} from '../../store/actions/interestActions';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';

const interestHeaders = [
  'S.No.',
  'Interest',
  'IconImg',
  'Emoji',
  'Background Color',
  'Hidden',
];

const InterestList = (props) => {
  const pageUrl = `/panel/interests`;
  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.interests.isLoading);
  const totalItems = useSelector((state) => state.interests.total);
  const perPage = useSelector((state) => state.interests.perPage);
  const deletingInterest = useSelector(
    (state) => state.interests.deletingInterest
  );
  const data = useSelector((state) => state.interests.interestData);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    if (auth.user.token && currentPage >= 2) {
      dispatch(listInterests(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handleDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      await dispatch(deleteInterest(id));
    }
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const interestRow = (data, i) => {
    return (
      <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
        <td>{startIndex + 1 + i}</td>
        <td>{data.keyword}</td>
        <td>{data.iconImg}</td>
        <td className="text-center h4">{data.emoji}</td>
        <td className="text-center">
          <div
            className="dot-lg d-inline-block"
            style={{ backgroundColor: data.backgroundColor }}
          ></div>
        </td>
        <td className="text-center">{data.hiddenFlag ? 'True': 'False'}</td>
        <td>
          {deletingInterest === data.id ? (
            <i className="fas fa-spinner fa-pulse ml-3"></i>
          ) : (
            <>
              <Link to={pageUrl + '/' + data.id + '/edit'}>
                <i className="fas fa-edit"></i>
              </Link>
              <span
                onClick={() => {
                  setDeleteData({ id: data.id, message: data.keyword });
                  setShow(!show);
                }}
                className="remove ml-4"
              >
                <i className="fas fa-trash text-danger"></i>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-3">
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {interestHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {data && data.length > 0 && (
                <tbody>
                  {data.slice(startIndex, endIndex).map(interestRow)}
                </tbody>
              )}
            </Table>
            {data && (
              <Paginate
                totalItems={totalItems}
                currentPage={currentPage}
                perPage={perPage}
                pageUrl={pageUrl}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InterestList;
