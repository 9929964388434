import { categoryActions } from "../actions/categoryActions"

const initialState = {
    categoryData: [],
    isLoading: false,
    deletingCategory: 0,
    alreadyExist: false,
    editData: {},
    total: 0,
    perPage: 5
}

export const categoryReducer = (state = initialState, { type, payload }) => {
    let categoryData;
    switch (type) {
        case categoryActions.SET_CATEGORY_DATA:
            categoryData = payload.isFirstTimeLoad ? payload.categoryList : [...state.categoryData, ...payload.categoryList]
            return { ...state, categoryData, total: payload.count }

        case categoryActions.SET_ALREADY_EXIST:
            return { ...state, alreadyExist: payload }
        case categoryActions.SET_IS_LOADING:
            return { ...state, isLoading: payload }

        case categoryActions.SET_DELETING_CATEGORY:
            return { ...state, deletingCategory: payload }

        case categoryActions.ADD_CATEGORY:
            payload.addedNow = true
            return { ...state, categoryData: [payload, ...state.categoryData], total: state.total + 1 }

        case categoryActions.DELETE_CATEGORY:
            const categoryArray = [...state.categoryData]
            const updatedcategoryData = categoryArray.filter((item) => item.id !== payload);
            return { ...state, categoryData: updatedcategoryData, total: state.total - 1 }

        case categoryActions.SET_EDIT_CATEGORY: {
            const index = state.categoryData.findIndex(
                (item) => item.id.toString() === payload
            );
            return { ...state, editData: state.categoryData[index] };
        }
        case categoryActions.UPDATE_CATEGORY_LIST: {
            const data = [...state.categoryData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) {
                data[index] = payload
            }
            return { ...state, categoryData: data }
        }

        case categoryActions.ADD_CATEGORY_COMPLETED: {
            const data = [...state.categoryData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) data[index].addedNow = false;
            return { ...state, categoryData: data }
        }

        default:
            return state;
    }
}