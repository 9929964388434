import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCommunitiesAction } from '../../store/actions/communityActions';
import { setKeywordsAction } from '../../store/actions/keywordAction';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import { setUsersAction } from '../../store/actions/userActions';
import CpPage from '../PageLayouts/CpPage';
import ChannelCreate from './ChannelCreate';
import ChannelList from './ChannelList';

const Communities = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchCommunities = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setCommunitiesAction(1));
      await dispatch(setKeywordsAction());
      await dispatch(setUsersAction());
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchCommunities();
  }, [fetchCommunities]);

  return (
    <div>
      <CpPage ListPage={ChannelList} CreatePage={ChannelCreate} />
    </div>
  );
};

export default Communities;
