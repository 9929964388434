import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Paginate from '../Utilities/Paginate';
import {setInterestTagList} from '../../store/actions/interestTagAction';
import InterestTagsTable from './InterestTagsTable';
import InterestTagsSearch from './InterestTagsSearch';

const pageUrl = '/panel/interest-tags';

const InterestTagsList = () => {
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const interestTags = useSelector((state) => state.interestTags.interestTagList);
  const isLoading = useSelector((state) => state.interestTags.isLoading);
  const totalItems = useSelector((state) => state.interestTags.total);
  const perPage = useSelector((state) => state.interestTags.perPage);

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();
  const [valueSearch, setValueSearch] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');

  const handleSearch = () => {
    setCurrentPage(1);
    setCurrentSearch(valueSearch)
    history.push(`${pageUrl}?search=${valueSearch}&page=1`);
    dispatch(setInterestTagList(1, currentSearch,))
  }

  useEffect(() => {
    const {page, search} = queryString.parse(location.search);
    setCurrentSearch(search ? search : '');
    setCurrentPage(page ? +page : 1);
    setValueSearch(search ? search : '')
  }, [location.search]);

  const startIndex = (currentPage - 1) * perPage;

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(setInterestTagList(currentPage, currentSearch));
    }
  }, [auth.user.token, currentPage, currentSearch, dispatch]);

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <InterestTagsSearch handleSearch={handleSearch} setValueSearch={setValueSearch} valueSearch={valueSearch} />
      </div>
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <InterestTagsTable interestTags={interestTags} startIndex={startIndex}/>
            <Paginate
              totalItems={totalItems}
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
              search={currentSearch}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InterestTagsList;
