import * as Yup from 'yup';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setSubmitError } from '../../utils/requests';
import { addSubEthnics, listSubEthnics } from '../../store/actions/ethnicActions';
import EthnicList from './EthnicList';
import ErrorMessage from '../Utilities/ErrorMessage';
import { Dropdown } from 'react-bootstrap';

const EthnicCreate = () => {
  const dispatch = useDispatch();
  const initialValues = {
    subCommunityName: '',
  };
  const subEthnicData = useSelector((state) => state.ethnics.subEthnicData);
  const auth = useSelector((state) => state.auth);
  const ethnicData = useSelector((state) => state.ethnics.ethnicData);

  useEffect(() => {
    if (ethnicData && ethnicData.length > 0) {
      setSelectedEthnic(ethnicData[0]);
    }
  }, [ethnicData]);

  const history = useHistory();
  const [selectedEthnic, setSelectedEthnic] = useState();

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting }
  ) => {
    try {
      let data = {};
      data.name = values.subCommunityName;
      data.id = selectedEthnic?.id;
      let response = await dispatch(addSubEthnics(data));
      response && resetForm();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(listSubEthnics(1));
    }
  }, [dispatch, auth.user.token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const validationSchema = Yup.object().shape({
    subCommunityName: Yup.string().required('Sub community name is required'),
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Create Sub-Community</h1>
      <br></br>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name">Sub-Community name</label>
            <input
              name="subCommunityName"
              id="subCommunityName"
              value={formik.values.subCommunityName}
              placeholder="Enter the Sub-Community name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="subCommunityName" formik={formik} />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name">Community name</label>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {selectedEthnic?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {ethnicData &&
                  ethnicData.map((item) => {
                    return (
                      <Dropdown.Item
                        key={item.id.toString()}
                        onClick={() => {
                          setSelectedEthnic(item);
                        }}
                      >
                        {item?.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <br></br>
        <div className="d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Create
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
      <br></br>
      {subEthnicData && subEthnicData?.length > 0 && (
        <div>
          <h2>Sub-Community List</h2>
          <EthnicList
            data={subEthnicData}
            ethnicData={ethnicData}
            formik={formik}
          />
        </div>
      )}
    </div>
  );
};

export default EthnicCreate;
