import { delay3Seconds } from '../../utils/constants';
import { apiRequest } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = "/keywords"

export const interestActions = {
    SET_INTEREST_DATA: 'SET_INTEREST_DATA',
    SET_IS_LOADING: 'SET_IS_LOADING',
    ADD_INTEREST: 'ADD_INTEREST',
    DELETE_INTEREST: 'DELETE_INTEREST',
    SET_DELETING_INTEREST: 'SET_DELETING_INTEREST',
    CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
    SET_EDIT_INTEREST: 'SET_EDIT_INTEREST',
    UPDATE_INTEREST_LIST: 'UPDATE_INTEREST_LIST',
    ADD_INTEREST_COMPLETED: 'ADD_INTEREST_COMPLETED',
    SET_ALREADY_EXIST: 'SET_ALREADY_EXIST'
}

export const setAlreadyExist = (data) => {
    return { type: interestActions.SET_ALREADY_EXIST, payload: data };
};

export const setInterestData = ({ interestList, count, isFirstTimeLoad = true }) => {
    return { type: interestActions.SET_INTEREST_DATA, payload: { interestList, count, isFirstTimeLoad } };
};

export const setIsLoading = (data) => {
    return { type: interestActions.SET_IS_LOADING, payload: data };
};

export const setInterestDeleting = (data) => {
    return { type: interestActions.SET_DELETING_INTEREST, payload: data };
};

export const removeInterest = (data) => {
    return { type: interestActions.DELETE_INTEREST, payload: data }
}

export const addInterest = (data) => {
    return { type: interestActions.ADD_INTEREST, payload: data };
};

export const addInterestCompleted = (data) => {
    return { type: interestActions.ADD_INTEREST_COMPLETED, payload: data };
};

export const setEditInterest = (interestId) => {
    return async (dispatch, getState) => {
        await dispatch({ type: interestActions.SET_EDIT_INTEREST, payload: interestId })
    }
}

export const updateInterestList = (data) => {
    return { type: interestActions.UPDATE_INTEREST_LIST, payload: data }
}

export const listInterests = (currentPage) => {
    return async (dispatch, getState) => {
        const { interests } = getState();
        const { interestData, perPage } = interests;
        let flag = true;
        const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
            list: interestData,
            currentPage,
            perPage,
            flag
        });
        const endpoint = `${pageUrl}`;
        if (shouldFetch) {
            try {
                await dispatch(setIsLoading(true))
                const { data } = await apiRequest(
                    'get',
                    endpoint,
                    {
                        params
                    }
                );
                const { keywords: interestList, count } = data;
                await dispatch(setInterestData({ interestList, count, isFirstTimeLoad }))
                await dispatch(setIsLoading(false))
            } catch (err) {
                console.log(err)
            }
        }
    }
}

export const addInterests = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/create`;
            const { data } = await apiRequest(
                'post',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { interest, created } = data;
            if (created) {
                await dispatch(addInterest(interest))
                setTimeout(async () => {
                    await dispatch(addInterestCompleted(interest))
                }, delay3Seconds)
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return created;
        }
    }
}

export const deleteInterest = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value}/remove`;
            await dispatch(setInterestDeleting(value))
            await apiRequest(
                'delete',
                endpoint,
                {
                    token: auth.user.token
                }
            );
            await dispatch(removeInterest(value))
            await dispatch(setInterestDeleting(0))
        }
    }
}

export const editInterest = (value) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        if (auth.user.token) {
            const endpoint = `${pageUrl}/${value.id}/update`;
            const { data } = await apiRequest(
                'put',
                endpoint,
                {
                    formData: value,
                    token: auth.user.token
                }
            );
            const { interest, updated } = data
            if (updated) {
                await dispatch(updateInterestList(interest))
            } else {
                await dispatch(setAlreadyExist(true));
                setTimeout(async () => {
                    await dispatch(setAlreadyExist(false))
                }, delay3Seconds);
            }
            return updated
        }
    }
}