import { delay3Seconds } from '../../utils/constants';
import { apiRequest } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = '/ethnic';

export const ethnicActions = {
  SET_SUB_ETHNIC_DATA: 'SET_SUB_ETHNIC_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',
  ADD_SUB_ETHNIC: 'ADD_SUB_ETHNIC',
  DELETE_SUB_ETHNIC: 'DELETE_SUB_ETHNIC',
  SET_DELETING_SUB_ETHNIC: 'SET_DELETING_SUB_ETHNIC',
  CHECK_ALREADY_EXIST: 'CHECK_ALREADY_EXIST',
  SET_EDIT_SUB_ETHNIC: 'SET_EDIT_SUB_ETHNIC',
  UPDATE_SUB_ETHNIC_LIST: 'UPDATE_SUB_ETHNIC_LIST',
  ADD_SUB_ETHNIC_COMPLETED: 'ADD_SUB_ETHNIC_COMPLETED',
  SET_ALREADY_EXIST: 'SET_ALREADY_EXIST',
  ADD_ETHNIC_DATA: 'ADD_ETHNIC_DATA',
  EMPTY_EDIT_DATA: 'EMPTY_EDIT_DATA',
};

export const setAlreadyExist = (data) => {
  return { type: ethnicActions.SET_ALREADY_EXIST, payload: data };
};

export const setSubEthnicData = ({ subEthnicList, count, isFirstTimeLoad = true }) => {
  return {
    type: ethnicActions.SET_SUB_ETHNIC_DATA,
    payload: { subEthnicList, count, isFirstTimeLoad },
  };
};

export const setIsLoading = (data) => {
  return { type: ethnicActions.SET_IS_LOADING, payload: data };
};

export const setSubEthnicDeleting = (data) => {
  return { type: ethnicActions.SET_DELETING_SUB_ETHNIC, payload: data };
};

export const removeSubEthnic = (data) => {
  return { type: ethnicActions.DELETE_SUB_ETHNIC, payload: data };
};

export const addSubEthnic = (data) => {
  return { type: ethnicActions.ADD_SUB_ETHNIC, payload: data };
};

export const addSubEthnicCompleted = (data) => {
  return { type: ethnicActions.ADD_SUB_ETHNIC_COMPLETED, payload: data };
};

export const setEditSubEthnic = (subethnicId) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: ethnicActions.SET_EDIT_SUB_ETHNIC,
      payload: subethnicId,
    });
  };
};
export const checkAlreadyExist = (data) => {
  return { type: ethnicActions.CHECK_ALREADY_EXIST, payload: data };
};

export const updateSubEthnicList = (data) => {
  return { type: ethnicActions.UPDATE_SUB_ETHNIC_LIST, payload: data };
};

export const setEthnicData = (data) => {
  return { type: ethnicActions.ADD_ETHNIC_DATA, payload: data };
}

export const emptyEditData = () => {
  return { type: ethnicActions.EMPTY_EDIT_DATA };
}

export const listSubEthnics = (currentPage) => {
  return async (dispatch, getState) => {
    const { auth, ethnics } = getState();
    const { subEthnicData, perPage } = ethnics;
    let flag = true;
    const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
      list: subEthnicData,
      currentPage,
      perPage,
      flag,
    });
    const endpoint = `${pageUrl}/admin/list`;
    if (shouldFetch) {
      await dispatch(setIsLoading(true));
      const { data } = await apiRequest('get', endpoint, {
        token: auth.user.token,
        params,
      });
      const { subEthnicList, count } = data;
      await dispatch(
        setSubEthnicData({ subEthnicList, count, isFirstTimeLoad })
      );
      await dispatch(setIsLoading(false));
    }
  };
};

export const addSubEthnics = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    if (auth.user.token) {
      const endpoint = `${pageUrl}/${value.id}/add`;
      const { data } = await apiRequest('post', endpoint, {
        formData: value,
        token: auth.user.token,
      });
      const { subEthnic, created = true } = data;
      if (created) {
        await dispatch(addSubEthnic(subEthnic));
        setTimeout(async () => {
          await dispatch(addSubEthnicCompleted(subEthnic));
        }, delay3Seconds);
      }
      return created;
    }
  };
};

export const deleteSubEthnic = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    if (auth.user.token) {
      const endpoint = `${pageUrl}/${value}/remove/subethnic`;
      await dispatch(setSubEthnicDeleting(value));
      await apiRequest('delete', endpoint, {
        token: auth.user.token,
      });
      await dispatch(removeSubEthnic(value));
      await dispatch(setSubEthnicDeleting(0));
    }
  };
};

export const editSubEthnic = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    value.mode = 'subEthnic';
    if (auth.user.token) {
      const endpoint = `${pageUrl}/${value.id}/edit`;
      const { data } = await apiRequest('put', endpoint, {
        formData: value,
        token: auth.user.token,
      });
      const { ethnic, updated } = data;
      if (updated) {
        await dispatch(updateSubEthnicList(ethnic));
      }
      return updated;
    }
  };
};

export const listEthnic = () => {
  return async (dispatch, getState) => {
    const { auth } = getState();

    const endpoint = `${pageUrl}/community/list`;
    await dispatch(setIsLoading(true));
    const { data } = await apiRequest('get', endpoint, {
      token: auth.user.token,
    });
    const { ethnics } = data;
    await dispatch(setEthnicData(ethnics));
    await dispatch(setIsLoading(false));
  };
};