import { searchKeywordActions } from '../actions/SearchKeywordActions';

const initialState = {
  keywordsList: [],
  isLoading: false,
};
export const SearchKeywordsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case searchKeywordActions.SET_SEARCH_KEYWORDS_LIST:
      return {
        ...state,
        keywordsList: payload,
      };

    case searchKeywordActions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};
