import React, {useCallback, useEffect, useRef, useState} from 'react';

const sampleImg = '';

const apiUrl = (process.env.REACT_APP_API_URL || '') + '/api';

export const uploadImageToS3 = async (imageData, token) => {
  if (!imageData) return null;
  let myHeaders = new Headers();
  myHeaders.append("Authorization",`Bearer ${token}`)

  let formdata = new FormData();
  formdata.append("files", imageData, imageData?.name);

  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
  };

  try {
      const data = await (await fetch(`${apiUrl}/uploads/image`, requestOptions)).json()
      return data?.imageUrl
  } catch (e) {
      return null
  }
}
const ImageUpload = ({
  prevImg,
  setFieldValue,
  imgAlt,
  children,
  className,
  setUploadedImg,
  clearImage,
}) => {
  const fileRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(prevImg || sampleImg);
  const [imageFile, setImageFile] = useState();

  const handleRevertImg = useCallback((removeImg = false) => {
    if (clearImage) {
      const revertImg = removeImg ? '' : prevImg;
      setImageSrc(revertImg);
      setFieldValue(revertImg);
      setImageFile(null);
      setUploadedImg(null);
      fileRef.current.value = '';
    }
  }, [clearImage, prevImg, setFieldValue, setUploadedImg]);

  useEffect(() => {
    setImageSrc(prevImg);
  }, [prevImg]);

  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setUploadedImg(file);
      setImageFile(file);
    }
  };
  return (
    <div className={className}>
      <label htmlFor="imageUrl">Image:</label>
      <input
        id="file"
        name="file"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileRef}
        onChange={handleFileUpload}
      />
      <div className="d-flex flex-wrap flex-column align-items-start">
        {imageSrc && (
          <div className="img-container mb-4">
            <img
              className="img-fluid"
              src={imageSrc}
              alt={imageFile ? imageFile.name : imgAlt}
              style={{ maxHeight: 300 }}
            />
          </div>
        )}
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => fileRef.current.click()}
          >
            Upload
          </button>
          {imageSrc && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRevertImg(true)}
            >
              Remove
            </button>
          )}
          {prevImg && prevImg !== imageSrc && (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => handleRevertImg()}
            >
              Revert
            </button>
          )}
        </div>
      </div>

      {children}
    </div>
  );
};

export const ImagesUpload = () => {
  const [images, setImages] = useState([]);

  const handleFileChange = (event) => {
    setImages(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append('files', images[i]);
    }

    try {
        let myHeaders = new Headers();
        myHeaders.append("Authorization",`Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTIzMTcsIkVtYWlsIjoiZWxlbmEudnlnb3Zza2FAZ21haWwuY29tIiwiaWF0IjoxNzIyMzgxMzk5LCJleHAiOjE3MjQ5NzMzOTl9.FDn3jpoXsBxONNuzEuQQfLoY1FlrbtNtgBBn4_FRgFI`)
      const response = await fetch('http://localhost:3500/api/uploads/images', {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      });
      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error('Error uploading images', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" multiple onChange={handleFileChange} />
        <button type="submit">Upload Images</button>
      </form>
    </div>
  );
};

export default ImageUpload;
