import React from 'react';
import './RedirectToApp.css';
import { redirectLinkToDevice } from '../RedirectLinkToDevice';

export function Community({ currentData }) {
  return (
    <div className="conatiner main d-flex align-items-center">
      <div className="row m-0 w-100">
        <div className="col-sm-12">
          <div>
            <div className="d-flex align-items-center justify-content-center flex-column mb-4">
              <div className="logoWrap imgBordered">
                {currentData?.imageUrl ? (
                  <img
                    src={currentData?.imageUrl}
                    className="w-100 imgBordered"
                    alt=""
                  />
                ) : (
                  <div className="nonImageCommunity">
                    {currentData?.name?.charAt(0) +
                      currentData?.name?.charAt(1)}
                  </div>
                )}
              </div>

              <p className="mb-1 mt-2">{currentData?.name}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-4">
              <button onClick={() => redirectLinkToDevice()} className="openBtn">
                Join Space
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <p className="mb-3">Don't have an account in Diem?</p>
              <div className="d-flex align-items-center">
                <div className="playImg mr-2">
                  <a href="https://apps.apple.com/in/app/diem-womxns-social-platform/id1524855792">
                    <img alt="" src="./icons/app.png" className="w-100" />
                  </a>
                </div>
                <div className="playImg">
                  <a href="https://play.google.com/store/apps/details?id=com.askdiem.app&hl=en">
                    <img alt="" src="./icons/play.png" className="w-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
