import { apiRequest, getErrorMsg } from '../../utils/requests';
import { getPagingParams } from '../../utils/store-helper';

const pageUrl = '/communities';
const feedPageUrl = '/feed';

export const communityActions = {
  SET_COMMUNITIES: 'SET_COMMUNITIES',
  SET_CURRENT_COMMUNITY: 'SET_CURRENT_COMMUNITY',
  ADD_COMMUNITY: 'ADD_COMMUNITY',
  EDIT_COMMUNITY: 'EDIT_COMMUNITY',
  COMMUNITIES_LOADING: 'COMMUNITIES_LOADING',
  CURRENT_COMMUNITY_LOADING: 'CURRENT_COMMUNITY_LOADING',
  SET_COMMUNITIES_ERROR: 'SET_COMMUNITIES_ERROR',
  SET_CURRENT_COMMUNITY_ERROR: 'SET_CURRENT_COMMUNITY_ERROR',
  SET_COMMUNITY_ADD_ERROR: 'SET_COMMUNITY_ADD_ERROR',
  SET_SEARCH: 'COMMUNITIES:SET_SEARCH',
  SET_FEED: 'COMMUNITIES:SET_FEED',
  SET_DELETING_FEED: 'COMMUNITIES:SET_DELETING_FEED',
  SET_EDIT_FEED: 'COMMUNITIES:SET_EDIT_FEED',
  ADD_FEED: 'COMMUNITIES:ADD_FEED',
  ADD_FEED_COMPLETED: 'COMMUNITIES:ADD_FEED_COMPLETED',
  REMOVE_FEED: 'COMMUNITIES:REMOVE_FEED'
};

export const setLoading = (isLoading) => {
  return { type: communityActions.COMMUNITIES_LOADING, payload: { isLoading } };
};

export const setCurrentLoading = (isLoading) => {
  return {
    type: communityActions.CURRENT_COMMUNITY_LOADING,
    payload: { isLoading },
  };
};

export const setCommunities = ({
  communities,
  count,
  isFirstTimeLoad = true,
}) => {
  return {
    type: communityActions.SET_COMMUNITIES,
    payload: { communities, count, isFirstTimeLoad },
  };
};

export const setCommunity = ({ community }) => {
  return {
    type: communityActions.SET_CURRENT_COMMUNITY,
    payload: { community },
  };
};

export const setCommunitiesError = (error) => {
  return {
    type: communityActions.SET_COMMUNITIES_ERROR,
    payload: { error },
  };
};

export const setcurrentItemError = (error) => {
  return {
    type: communityActions.SET_CURRENT_COMMUNITY_ERROR,
    payload: { error },
  };
};

export const setCommunitiesAction = (currentPage) => {
  return async (dispatch, getState) => {
    try {
      const { communities, auth } = getState();
      const { list, perPage } = communities;
      const { shouldFetch, params, isFirstTimeLoad } = getPagingParams({
        list,
        currentPage,
        perPage,
      });
      if (shouldFetch) {
        await dispatch(setLoading(true));
        const response = await apiRequest(
          'get',
          `${pageUrl}?private=1&inactive=true&users=1`,
          {
            token: auth.user.token,
            params,
          }
        );
        const { communities, count } = response.data;
        await dispatch(setCommunities({ communities, count, isFirstTimeLoad }));
        await dispatch(setCommunitiesError(null));
      }
    } catch (err) {
      await dispatch(setCommunitiesError(getErrorMsg(err)));
    }
    await dispatch(setLoading(false));
  };
};

export const setCommunityAction = (id) => {
  return async (dispatch, getState) => {
    await dispatch(setCurrentLoading(true));
    try {
      const { communities, auth } = getState();
      const { list } = communities;
      let community = list.find((comm) => comm.id === id);
      if (!community && auth.user.token) {
        const response = await apiRequest('get', `${pageUrl}/${id}?users=1`, {
          token: auth.user.token,
        });
        community = response.data.community;
      }
      await dispatch(setCommunity({ community }));
      await dispatch(setcurrentItemError(null));
    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
    }
    await dispatch(setCurrentLoading(false));
  };
};

export const addCommunity = ({ community }) => {
  return { type: communityActions.ADD_COMMUNITY, payload: { community } };
};

export const editCommunity = ({ community }) => {
  return { type: communityActions.EDIT_COMMUNITY, payload: { community } };
};

export const addEditCommunityAction = ({
  values,
  isCreatePage,
  onSuccess,
  onError,
}) => {
  return async (dispatch, getState) => {
    try {
      const { communities, auth } = getState();
      const { list } = communities;
      const method = isCreatePage ? 'post' : 'patch';
      const endpoint = isCreatePage ? pageUrl : `${pageUrl}/${values.id}`;
      const dispatchAction = isCreatePage ? addCommunity : editCommunity;
      const { data } = await apiRequest(method, endpoint, {
        formData: values,
        token: auth.user.token,
      });
      const { community } = data;
      await dispatch(setcurrentItemError(null));
      if (community) {
        if (list.length === 0) await dispatch(setCommunitiesAction());
        await dispatch(dispatchAction({ community }));
      }
      if (onSuccess) await onSuccess(community);
    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
      if (onError) await onError(err);
    }
  };
};

export const setCommunitySearch = (search) => {
  return { type: communityActions.SET_SEARCH, payload: search };
};

export const addFeedData = (data) => {
  return {
    type: communityActions.ADD_FEED,
    payload: data
  }
}

export const addFeedCompleted = (data) => {
  return {
    type: communityActions.ADD_FEED_COMPLETED,
    payload: data
  }
}

export const addFeed = (value) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const endpoint = `${feedPageUrl}/create`
    const { data } = await apiRequest(
      'post',
      endpoint,
      {
        formData: value,
        token: auth.user.token
      }
    );
    const { feed } = data
    await dispatch(addFeedData(feed))
    setTimeout(async () => {
      await dispatch(addFeedCompleted(feed.id))
    }, 3000)
  }
}
export const setFeedData = (data) => {
  return {
    type: communityActions.SET_FEED,
    payload: data
  }
}

export const setDeletingFeed = (feedId) => {
  return {
    type: communityActions.SET_DELETING_FEED,
    payload: feedId
  }
}

export const removeFeed = (feedId) => {
  return {
    type: communityActions.REMOVE_FEED,
    payload: feedId
  }
}

export const removeFeedAction = (communityId, feedId) => {
  return async (dispatch, getState) => {
    try {
      await (dispatch(setDeletingFeed(feedId)))
      const { auth } = getState();
      let communityDetails = {
        communityId: communityId
      }
      await apiRequest('post', `${feedPageUrl}/${feedId}/remove`, {
        formData: communityDetails,
        token: auth.user.token,
      });

      await dispatch(removeFeed(feedId))
      await (dispatch(setDeletingFeed(null)))

    } catch (err) {
      await dispatch(setcurrentItemError(getErrorMsg(err)));
    }
  };
};

export const getFeedData = (communityId) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState()
      let response;
      if (communityId) {
        const endpoint = `${feedPageUrl}/${communityId}/list`
        response = await apiRequest(
          'get',
          endpoint,
          {
            token: auth.user.token
          }
        );
        let feedData = response.data.feeds
        await dispatch(setFeedData(feedData))
      }
    } catch (err) {
      console.log(err)
    }
  }
}
export const editFeed = (feedId) => {
  return async (dispatch, getState) => {
    await dispatch(
      {
        type: communityActions.SET_EDIT_FEED,
        payload: feedId
      }
    )
  }
}

export const updateFeed = ({ data, feedid }) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      let response;
      const endpoint = `${feedPageUrl}/${feedid}/update`
      response = await apiRequest(
        'post',
        endpoint,
        {
          formData: data,
          token: auth.user.token
        }
      );
      let feedData = response.data.feeds
      await dispatch(setFeedData(feedData))
    } catch (err) {
      console.log(err)
    }
  }
}