import React from "react";
import CommunityCreate from "./CommunityCreate"
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import CommunityFeedEdit from "./CommunityFeedEdit";

const CommunityTab = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const [tabValue, setTabValue] = React.useState(
    location.pathname.includes("/feed") ? 1 : 0
  );

  const TabPanel = (prop) => {
    const { children, value, index, ...other } = prop;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    tabColor: {
      backgroundColor: "#007bff",
      color: "white",
    },
  }));
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { id } = useParams();

  return (
    <div className="card shadow">
      <div className={classes.root}>
        {/* <AppBar position="static"> */}
        <Tabs
          className={classes.tabColor}
          elevation={0}
          value={tabValue}
          onChange={handleChange}
        >
          <Tab
            label="Community"
            component={NavLink}
            exact
            to={path.replace(/:id$/, id)}
            {...a11yProps(0)}
          />
          {/*Add users there*/}
          {/*{id && (*/}
          {/*  <Tab*/}
          {/*    label="Sub feed"*/}
          {/*    exact*/}
          {/*    component={NavLink}*/}
          {/*    to={path.replace(/:id$/, id) + "/feed"}*/}
          {/*    {...a11yProps(1)}*/}
          {/*  />*/}
          {/*)}*/}
        </Tabs>
        <Switch>
          <Route
            exact
            path={path}
            render={(routeParams) => {
              return (
                <TabPanel value={tabValue} index={0}>
                  <CommunityCreate {...routeParams} />
                </TabPanel>
              );
            }}
          />
          <Route
            exact
            path={path + "/feed/:feedid/edit"}
            render={(routeParams) => {
              return <CommunityFeedEdit {...routeParams} />;
            }}
          />
          {/*<Route*/}
          {/*  path={path + "/feed"}*/}
          {/*  render={(routeParams) => {*/}
          {/*    return (*/}
          {/*      <TabPanel value={tabValue} index={1}>*/}
          {/*        <CommunityFeeds {...routeParams} />*/}
          {/*      </TabPanel>*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
        </Switch>
      </div>
    </div>
  );
};

export default CommunityTab;
