import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentLoading } from '../../store/actions/layoutActions';
import CpPage from '../PageLayouts/CpPage';
import InterestCreate from './InterestCreate';
import InterestEdit from './InterestEdit';

const Interest = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchInterests = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(setMainContentLoading(false));
    }
  }, [auth.user.token, dispatch]);

  useEffect(() => {
    fetchInterests();
  }, [fetchInterests]);
  return (
    <div>
      <CpPage ListPage={InterestCreate} EditPage={InterestEdit} />
    </div>
  );
};

export default Interest;
