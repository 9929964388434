import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';
const ENDPOINT = 'http://localhost:3500';

function SocketTest() {
  const auth = useSelector((state) => state.auth);

  const [response, setResponse] = useState('');

  useEffect(() => {
    if (auth.user.token) {
      const socket = socketIOClient(ENDPOINT, {
        auth: { token: auth.user.token },
      });
      socket.on('message', (data) => {
        setResponse(data);
        console.log(data);
      });
      socket.on('connect_error', (err) => {
        console.log(err.message);
      });
      socket.on('notifications', (data) => {
        console.log(data);
      });
      socket.on('removeNotification', (data) => {
        console.log(data);
      });
    }
  }, [auth.user.token]);

  return (
    <p>
      It's <time dateTime={response}>{response}</time>
    </p>
  );
}

export default SocketTest;
