import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import Paginate from '../Utilities/Paginate';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import ReportedUserModal from '../Reports/ReportedUserModal';
import {
  deleteNewReport,
  deleteNewReportAndBlockUser,
  listWebReports, modifyNewReport,
} from '../../store/actions/reportsAction';
import { apiRequest } from '../../utils/requests';
import './WebReports.css';

const reportHeaders = [
  'S.No.',
  'Content',
  'Content Owner',
  'Content Type',
  'Reported(count)',
  'Status',
];

const HANDLED_STATUSES = ['DELETED', 'BLOCKED', 'RESOLVED'];
const DELETED_STATUSES = ['DELETED', 'BLOCKED'];

export const WebReports = (props) => {
  const defaultMessages = [
    'Are you sure to delete this',
    `and block this user?`,
  ];
  const pageUrl = `/panel/web-reports`;
  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.reports.isLoading);
  const totalItems = useSelector((state) => state.reports.total);
  const perPage = useSelector((state) => state.reports.perPage);
  const deletingReport = useSelector((state) => state.reports.deletingReport);
  const data = useSelector((state) => state.reports.reportsData);
  const [show, setShow] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [userData, setUserData] = useState('');
  const [modifyData, setModifyData] = useState();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState({});

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    if (auth.user.token && currentPage) {
      dispatch(listWebReports(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handleDelete = async (params) => {
    if (auth.user.token && modifyData) {
      modifyData.block
        ? await dispatch(deleteNewReportAndBlockUser(modifyData))
        : await dispatch(deleteNewReport(modifyData));
    }
  };

  const handleModify = async (params) => {
    if (auth.user.token && params) {
     setModifyData(params)
     await dispatch(modifyNewReport(params));
    }
  };

  const startIndex = (currentPage - 1) * perPage;

  const reportRow = (data, i) => {
    const type = data?.typeofContent
    const isActivity = ['activity', 'activities'].includes(type);
    const showLink = isActivity && !DELETED_STATUSES.includes(data.status) && data?.url;
    const disabled = HANDLED_STATUSES.includes(data.status) || (modifyData && modifyData.id === data?.id);
    return (
      <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
        <td>{startIndex + 1 + i}</td>
        <td>
          {showLink ? <a href={data?.url} target="_blank" rel="noreferrer" className="link-primary">
              {data.content}
          </a> : data.content}
        </td>
        <td>
          {data?.reportPostOwner
            ? `${data?.reportPostOwner.username}`
            : 'Diemer'}
        </td>

        <td className="text-capitalize">{type}</td>

        <td className="text-capitalize">
          <a
            type="button"
            onClick={async () => {
              const { data: rs } = await apiRequest(
                'get',
                `/report/v2/${data?.id}/fetchreasons`,
                {token: auth.user.token}
              );
              const { reasons } = rs;
              const userListString = reasons?.map((item) => `${item?.reason} - ${item?.reported}\n`).join('');
              setTitle('Reasons');
              setShowUsers(true);
              setUserData(userListString);
            }}
            className="btn btn-link"
          >
            {`${data?.reportedByCount}`}
          </a>
        </td>
        {deletingReport.id === data.id ? (
          <td>
            <i className="fas fa-spinner fa-pulse ml-3"></i>
          </td>
        ) : (
          <td
            className={data.status === 'DELETED' ? 'text-success' : 'text-info'}
          >
            {data.status}
          </td>
        )}
        <td>
          <div className="d-flex gap-columns">
            <button
              type="button"
              className="btn btn-info"
              disabled={disabled}
              onClick={() => handleModify({...data})}
            >
              Resolve
            </button>
            <button
              type="button"
              className="btn btn-warning"
              disabled={disabled}
              onClick={async () => {
                let dataToDelete = {...data, block: false};

                setMessage({
                  content: `${defaultMessages[0]} ${data.typeofContent}?`,
                  block: false,
                });
                setModifyData(dataToDelete);
                setShow(!show);
              }}
            >
              Delete
            </button>
            <button
              type="button"
              disabled={disabled}
              className="btn btn-danger"
              onClick={async () => {
                setMessage({
                  content: `${defaultMessages[0]} ${data?.typeofContent} ${defaultMessages[1]}`,
                  block: true,
                });
                let dataToDelete = {...data, block: true};
                setModifyData(dataToDelete);
                setShow(!show);
              }}
            >
              Delete&nbsp;&&nbsp;Block user
            </button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="card shadow p-4">
      <h1>Web Reports</h1>
      <br></br>
      <div className="p-3">
        <div className="row">
          {isLoading ? (
            <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
              <i className="fas fa-spinner fa-pulse"></i>
            </div>
          ) : (
            <>
              <ConfirmationPopup
                show={show}
                data={modifyData}
                handleDelete={handleDelete}
                report={true}
                message={message}
              />
              <ReportedUserModal
                show={showUsers}
                setShowUsers={setShowUsers}
                data={userData}
                title={title}
                setUserData={setUserData}
                setTitle={setTitle}
              />
              <Table striped bordered hover>
                <thead>
                <tr>
                  {reportHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
                </thead>
                {data && data.length > 0 && (
                  <tbody>
                  {data.map(reportRow)}
                  </tbody>
                )}
              </Table>
              {data && (
                <Paginate
                  totalItems={totalItems}
                  currentPage={currentPage}
                  perPage={perPage}
                  pageUrl={pageUrl}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
