import { apiRequest } from '../../utils/requests';

export const authActions = {
  LOGOUT: 'LOGOUT',
  SET_USER: 'SET_USER',
  AUTO_LOGIN_ERROR: 'AUTO_LOGIN_ERROR',
};

export const logoutAction = () => {
  return { type: authActions.LOGOUT };
};

export const setUserAction = (
  { user, token },
  history,
  { storeToken = false, isAutoLogin = false } = {}
) => {
  return {
    type: authActions.SET_USER,
    payload: { user, token, storeToken, isAutoLogin, history },
  };
};

export const setAutoLoginError = () => {
  return { type: authActions.AUTO_LOGIN_ERROR };
};

export const autoLoginUser = (token) => {
  return async (dispatch) => {
    try {
      const response = await apiRequest('get', `/users/autologin`, { token });
      const { user } = response.data;
      await dispatch(setUserAction({ user, token }));
    } catch (err) {
      await dispatch(setAutoLoginError());
    }
  };
};
