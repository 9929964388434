import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  editInterestTag, setAlreadyExist, setEditInterestTags, setInterestTagList
} from '../../store/actions/interestTagAction';
import ErrorMessage from '../Utilities/ErrorMessage';

import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {icon, checkedIcon} from '../common/CommonComponents';
import {delay3Seconds} from '../../utils/constants';
import {setKeywordsAction} from '../../store/actions/keywordAction';
import { setSubmitError } from '../../utils/requests';


const InterestTagEdit = () => {
  const location = useLocation();
  const {id} = useParams();
  const {page = 1} = queryString.parse(location.search);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const interestTags = useSelector((state) => state.interestTags.interestTagList);
  const editData = useSelector((state) => state.interestTags.editData);
  const alreadyExist = useSelector((state) => state.interestTags.alreadyExist);
  const keywords = useSelector((state) => state.keywords.list);
  const history = useHistory();
  const [editInterestTagsData, setEditInterestTagsData] = useState();
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const initialValues = {
    tagName: editInterestTagsData?.tagName || '',
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect( () => {
    async function fetchData() {
      if (id && auth.user.token) {
        (!interestTags || !interestTags.length) && (await dispatch(setInterestTagList(page)));
        await dispatch(setEditInterestTags(id));
      }
    }
    fetchData();
  }, [dispatch, interestTags, id, auth, page]);

  useEffect(() => {
    if (editData) {
      const {relatedKeywords: interests} = editData;
      setSelectedKeywords(interests || []);
      setEditInterestTagsData(editData);
    }
  }, [editData]);

  const handleSubmit = async (
    values,
    {setErrors, setSubmitting}
  ) => {
    try {
      let data = {};
      data.tagName = values.tagName;
      data.id = id;
      data.relatedKeywords = selectedKeywords.map((interest) => interest.id);
      await dispatch(editInterestTag(data, id));
      history.goBack();
    } catch (error) {
      if (error?.response?.data?.error === "Tag already exists") {
        await dispatch(setAlreadyExist(true));
        setTimeout(async () => {
          await dispatch(setAlreadyExist(false));
        }, delay3Seconds);
      }
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    tagName: Yup.string().required('Tag name is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  useEffect( () => {
    async function fetchKeywords() {
      if (auth.user.token) {
        await dispatch(setKeywordsAction());
      }
    }
    fetchKeywords()
  }, [dispatch, auth.user.token]);

  return (
    <div className="card shadow p-4">
      <h1>Edit Interest's Tag</h1>
      <form className="padding needs-validation" onSubmit={formik.handleSubmit} key={id}>
        <div className="input-group search-field mx-3 w-auto flex-fill">
          <div className="row">
            <label htmlFor="tagName">Tag name</label>
            <input
              name="tagName"
              id="tagName"
              value={formik.values.tagName}
              placeholder="Enter the tag name"
              onChange={formik.handleChange}
              className="form-control"
            />
            <ErrorMessage name="tagName" formik={formik}/>
            {alreadyExist && (
              <div className="smallPadding">
                <Alert severity="error">
                  The tag given already exists! Try change it
                </Alert>
                <br/>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="padding form-group col-12 col-md-6">
            <label htmlFor="name">Onboarding Interests</label>
            <Autocomplete
              multiple
              id="keywords"
              options={keywords}
              disableCloseOnSelect
              filterSelectedOptions
              getOptionLabel={(option) => option.keyword + ' ' + option.emoji}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e, value) => setSelectedKeywords(value)}
              renderOption={(option, {selected}) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.keyword} {option.emoji}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Keywords"
                  placeholder="Interests"
                />
              )}
              value={selectedKeywords}
            />
          </div>
        </div>
        {formik.errors.submitError && (
          <div className="row">
            <div className="alert alert-danger">
              {formik.errors.submitError}
            </div>
          </div>
        )}
        <div className="padding d-flex">
          <button
            className="btn btn-success mr-5"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Update
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => history.goBack()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default InterestTagEdit;