import * as Yup from 'yup';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { setSubmitError } from '../../utils/requests';
import ErrorMessage from '../Utilities/ErrorMessage';
import {
  editTags,
  listTags,
  setEditTags,
} from '../../store/actions/tagsActions';

const TagsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const interestList = useSelector((state) => state.interests.interestData);
  const editData = useSelector((state) => state.interests.editData);
  const alreadyExist = useSelector((state) => state.interests.alreadyExist);
  const [editTagsData, setEditTagsData] = useState();
  const history = useHistory();

  const initialValues = {
    tagName: editTagsData?.tagName || '',
    description: editTagsData?.description || '',
    imageCover: editTagsData?.imageCover || '',
    imageHeader: editTagsData?.imageHeader || '',
  };

  const fetchTagsData = useCallback(async () => {
    if (id) {
      !interestList && (await dispatch(listTags(1)));
      await dispatch(setEditTags(id));
    }
  }, [dispatch, interestList, id]);

  useEffect(() => {
    if (editData) {
      setEditTagsData(editData);
    }
  }, [editData]);

  useEffect(() => {
    fetchTagsData();
  }, [fetchTagsData]);

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      data.tagName = values.tagName;
      data.description = values.description;
      data.imageCover = values.imageCover;
      data.imageHeader = values.imageHeader;
      data.id = id;
      let response = await dispatch(editTags(data));
      response && history.goBack();
    } catch (error) {
      setSubmitError(error, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    tagName: Yup.string().required('Tag name is required'),
    description: Yup.string().required('Tag description is required'),
    imageCover: Yup.string().required('The image cover for tag is required'),
    imageHeader: Yup.string().required('The image header for tag is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className="card shadow p-4">
      <h1>Edit Super Tags</h1>
      <br></br>
      <form className="needs-validation" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name"> Tag name</label>
            <input
                name="tagName"
                id="tagName"
                value={formik.values.tagName}
                placeholder="Enter the tag name"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="tagName" formik={formik} />
          </div>
          <div className="form-group col-3 col-md-6">
            <label htmlFor="name">Tag Description</label>
            <input
                name="description"
                id="description"
                value={formik.values.description}
                placeholder="Enter the tag description value"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="description" formik={formik} />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="name"> Image cover</label>
            <input
                name="imageCover"
                id="imageCover"
                value={formik.values.imageCover}
                placeholder="Enter the image cover url"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="imageCover" formik={formik} />
          </div>
          <div className="form-group col-3 col-md-6">
            <label htmlFor="name"> Image header</label>
            <input
                name="imageHeader"
                id="imageHeader"
                value={formik.values.imageHeader}
                placeholder="Enter the image header url"
                onChange={formik.handleChange}
                className="form-control"
            />
            <ErrorMessage name="imageHeader" formik={formik} />
          </div>
        </div>
        <br></br>
        {alreadyExist && (
            <div>
              <Alert severity="error">
                The tag given already exists! try changing it
              </Alert>
              <br></br>
            </div>
        )}
        <br></br>
        <div className="d-flex">
          <button
              className="btn btn-success mr-5"
              type="submit"
              disabled={formik.isSubmitting}
          >
            Create
          </button>
          <button
              className="btn btn-danger"
              type="button"
              onClick={() => history.goBack()}
              disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default TagsEdit;
