import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import ErrorMessage from "../Utilities/ErrorMessage";
import ImageUpload from "../Utilities/ImageUpload";
import { useHistory, useParams } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import {
  editFeed,
  getFeedData,
  updateFeed,
} from "../../store/actions/communityActions";
import { setSubmitError, uploadUsingSignedUrl } from "../../utils/requests";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { icon, checkedIcon } from "../common/CommonComponents";
import * as Yup from "yup";

const CommunityFeedEdit = () => {
  const history = useHistory();
  const { id, feedid } = useParams();
  const keywords = useSelector((state) => state.keywords.list);
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const [copied, setCopied] = useState(false);
  const editFeedDataSource = useSelector((state) => state.communities.editFeed);
  const auth = useSelector((state) => state.auth);

  const [editFeedData, setEditFeedData] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editFeedDataSource) {
      const { keywords, imageUrl } = editFeedDataSource;
      setEditFeedData(editFeedDataSource);
      setSelectedKeywords(keywords || []);
      setPrevImg(imageUrl || "");
    }
  }, [editFeedDataSource]);

  let copyTimeout;
  const handleDeepLinkCopy = () => {
    setCopied(true);
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => setCopied(false), 2000);
  };

  const fetchInfo = useCallback(async () => {
    await dispatch(getFeedData(id));
    await dispatch(editFeed(feedid));
  }, [dispatch, id, feedid]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  const handleSubmit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      let data = {};
      const keyPrefix = "feed/" + (feedid || "new");
      const imageUrl = await uploadUsingSignedUrl(
        keyPrefix,
        uploadedImg,
        auth.user.token
      );
      if (!imageUrl) {
        setFieldValue("imageUrl", prevImg);
        data.imageUrl = prevImg;
      } else {
        setFieldValue("imageUrl", imageUrl);
        data.imageUrl = imageUrl;
      }
      data.keywords = selectedKeywords.map((keyword) => keyword.id);
      data.color = values.color;
      await dispatch(updateFeed({ data, feedid }));
      history.goBack();
    } catch (err) {
      setSubmitError(err, setErrors);
      console.log(err);
    }
  };

  const handleChange = (event) => {
    const key = event ? event.which : window.event.keycode;
    if (key === 32 || key === 9) {
      return event.preventDefault();
    }
    return event;
  };

  const initialValues = {
    imageUrl:
      editFeedData && editFeedData.imageUrl ? editFeedData.imageUrl : "",
    color: editFeedData && editFeedData.color ? editFeedData.color : "#e1d8eb",
    keywords:editFeedData && editFeedData.keywords ? editFeedData.keywords : []
  };
  const validationSchema = Yup.object().shape({
    keywords: Yup.array().min(1, "Keyword is required")
  });
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema
  });
  const [uploadedImg, setUploadedImg] = useState();
  const [prevImg, setPrevImg] = useState("");
  const { setFieldValue } = formik;
  const setImageUrl = (imageUrl) => {
    setFieldValue("imageUrl", imageUrl);
  };
  useEffect(()=>{
    setFieldValue('keywords',selectedKeywords)
  },[selectedKeywords, setFieldValue])

  return (
    <div className="card shadow">
      <div className="card-body">
        {editFeedData !== undefined && (
          <>
            <h1>Edit Feed</h1>
            <CopyToClipboard
              text={editFeedData.deepLink || ''}
              onCopy={handleDeepLinkCopy}
            >
              <div className="form-group input-group" title="click to copy">
                <div className="input-group-append">
                  {copied && (
                    <span className="input-group-text text-white bg-success">
                      Copied to clipboard!
                    </span>
                  )}
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={editFeedData.dynamicLink || ''}
                  readOnly
                />
                <div className="input-group-append">
                  <i className="input-group-text fas fa-copy"></i>
                </div>
              </div>
            </CopyToClipboard>
            <React.Fragment>
              <form className="needs-validation" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="name">Subfeed Name:</label>
                    <input
                      name="subFeedName"
                      id="subFeedName"
                      value={editFeedData.name || ''}
                      placeholder="Enter the name used to create Feed group"
                      onKeyPress={(event) => handleChange(event)}
                      onChange={formik.handleChange}
                      className="form-control h-50"
                      readOnly
                    />
                    <ErrorMessage name="subFeedName" formik={formik} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="color">Color:</label>
                    <input
                      type="color"
                      name="color"
                      id="color"
                      className="ml-4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.color || ''}
                    />
                    <ErrorMessage name="color" formik={formik} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex flex-column mb-4">
                    <div className="form-group">
                      <label htmlFor="color">Keyword:</label>
                      <Autocomplete
                        multiple
                        id="keywords"
                        options={keywords || []}
                        disableCloseOnSelect
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option.keyword + ' ' + option.emoji
                        }
                        getOptionSelected={(option, value) => {
                          return option.id === value.id;
                        }}
                        onChange={(e, value) => setSelectedKeywords(value)}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.keyword} {option.emoji}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Keywords"
                            placeholder="Interests"
                          />
                        )}
                        value={formik.values.keywords}
                      />
                      <ErrorMessage name="keywords" formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <ImageUpload
                      prevImg={prevImg}
                      setFieldValue={setImageUrl}
                      imgAlt={formik.values.imageUrl}
                      className="mb-4"
                      setUploadedImg={setUploadedImg}
                    >
                      <ErrorMessage name="imageUrl" formik={formik} />
                    </ImageUpload>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-success mr-5"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    update
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </form>
              <br></br>
            </React.Fragment>
          </>
        )}
      </div>
    </div>
  );
};

export default CommunityFeedEdit;
