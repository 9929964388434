import { apiRequest } from '../../utils/requests';

export const searchKeywordActions = {
  SET_SEARCH_KEYWORDS_LIST: 'SET_SEARCH_KEYWORDS_LIST',
  SET_IS_LOADING: 'SET_IS_LOADING',
};

export const setSearchKeywordsList = (data) => {
  return {
    type: searchKeywordActions.SET_SEARCH_KEYWORDS_LIST,
    payload: data,
  };
};

export const setSearchKeywordsLoader = (data) => {
  return {
    type: searchKeywordActions.SET_IS_LOADING,
    payload: data,
  };
};

export const exportKeywords = ({ mode, fromDate, toDate }) => {
  return async (dispatch, getState) => {
    await dispatch(setSearchKeywordsLoader(true));
    const { auth } = getState();
    const apiUrl = `/search/${mode}/wordcount/download?startDate=${fromDate}&endDate=${toDate}`;
    try {
      const response = await apiRequest('get', apiUrl, {
        token: auth.user.token,
      });
      if (response) {
        const headers = response.headers;
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: headers['content-type'] })
        );
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = 'keywords.csv';
        link.click();
        document.body.removeChild(link);
      }
      await dispatch(setSearchKeywordsLoader(false));
    } catch (err) {
      await dispatch(setSearchKeywordsLoader(false));
    }
  };
};

export const fetchTop100Keywords = ({ mode, fromDate, toDate }) => {
  return async (dispatch, getState) => {
    await dispatch(setSearchKeywordsLoader(true));
    await dispatch(setSearchKeywordsList([]));

    const { auth } = getState();
    const apiUrl = `/search/${mode}/wordcount/fetch?startDate=${fromDate}&endDate=${toDate}`;
    try {
      const response = await apiRequest('get', apiUrl, {
        token: auth.user.token,
      });
      if (response) {
        const { result = [] } = response.data;
        await dispatch(setSearchKeywordsList(result));
      }
      await dispatch(setSearchKeywordsLoader(false));
    } catch (err) {
      await dispatch(setSearchKeywordsLoader(false));
    }
  }
}
