import { reportActions } from '../actions/reportsAction';

const initialState = {
  reportsData: [],
  isLoading: false,
  deletingReport: 0,
  alreadyExist: false,
  total: 0,
  perPage: 10,
};

export const reportsReducer = (state = initialState, { type, payload }) => {
  let reportsData;
  switch (type) {
    case reportActions.SET_REPORT_DATA:
      return {
        ...state,
        reportsData: payload.reports,
        total: payload.count,
      };
    case reportActions.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case reportActions.SET_DELETING_REPORT:
      return { ...state, deletingReport: payload };
    case reportActions.UPDATE_REPORT_DATA: {
      const data = [...state.reportsData];
      const index = data.findIndex((item) => item.id === payload.id);
      if (index !== undefined) {
        data[index] = payload;
      }
      return { ...state, reportsData: data };
    }

    //NEW web app report
    case reportActions.SET_NEW_REPORT_DATA:
      reportsData = payload.isFirstTimeLoad
        ? payload.reports
        : [...state.reportsData, ...payload.reports];
      return { ...state, reportsData: reportsData, total: payload.count };

    default:
      return state;
  }
};
