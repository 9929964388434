import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ReportedUserModal = ({
  show,
  setShowUsers,
  data,
  handleDelete,
  setUserData,
  title,
  setTitle,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setUserData('');
    setTitle('');
    setShowUsers(false);
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Modal
      dialogClassName="primary"
      show={open}
      onHide={handleClose}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <textarea
            disabled
            defaultValue={data}
            className="form-control"
            rows="5"
            id="comment"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          close
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ReportedUserModal;
