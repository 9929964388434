import { interestActions } from "../actions/interestActions"

const initialState = {
    interestData: [],
    isLoading: false,
    deletingInterest: 0,
    alreadyExist: false,
    editData: {},
    total: 0,
    perPage: 5
}

export const interestReducer = (state = initialState, { type, payload }) => {
    let interestData;
    switch (type) {
        case interestActions.SET_INTEREST_DATA:
            interestData = payload.isFirstTimeLoad ? payload.interestList : [...state.interestData, ...payload.interestList]
            return { ...state, interestData, total: payload.count }

        case interestActions.SET_ALREADY_EXIST:
            return { ...state, alreadyExist: payload }
        case interestActions.SET_IS_LOADING:
            return { ...state, isLoading: payload }

        case interestActions.SET_DELETING_INTEREST:
            return { ...state, deletingInterest: payload }

        case interestActions.ADD_INTEREST:
            payload.addedNow = true
            return { ...state, interestData: [payload, ...state.interestData], total: state.total + 1 }

        case interestActions.DELETE_INTEREST:
            const categoryArray = [...state.interestData]
            const updatedinterestData = categoryArray.filter((item) => item.id !== payload);
            return { ...state, interestData: updatedinterestData, total: state.total - 1 }

        case interestActions.SET_EDIT_INTEREST: {
            const index = state.interestData.findIndex(
                (item) => item.id.toString() === payload
            );
            return { ...state, editData: state.interestData[index] };
        }
        case interestActions.UPDATE_INTEREST_LIST: {
            const data = [...state.interestData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) {
                data[index] = payload
            }
            return { ...state, interestData: data }
        }

        case interestActions.ADD_INTEREST_COMPLETED: {
            const data = [...state.interestData]
            const index = data.findIndex((item) => item.id === payload.id)
            if (index !== undefined) data[index].addedNow = false;
            return { ...state, interestData: data }
        }

        default:
            return state;
    }
}