import { sessionActions } from "../actions/sessionActions"

export const initialState = {
    sessionList: [],
    errors: { listError: null, deleteError: null },
    total: 0,
    isLoading: false,
    perPage: 10,
    deletingSession: null,
    isDeleteSessionLoading: false,
    search: ''
}

export const sessionReducer = (state = initialState, { type, payload }) => {
    let sessionList;
    switch(type){
        case sessionActions.SET_SESSIONS:
            return {
                ...state,
                sessionList: payload.sessions,
                total: payload.count,
            };
        case sessionActions.SET_DELETING_SESSION:
            return { ...state, deletingSession: payload };
        case sessionActions.DELETE_SESSION:
            sessionList = state.sessionList.filter((session) => session.id !== payload);
            return { ...state, sessionList: sessionList };
        case sessionActions.SESSIONS_LOADING:
            return { ...state, isLoading: payload }
        case sessionActions.DELETE_SESSION_LOADING:
            return { ...state, isDeleteSessionLoading: payload }
        case sessionActions.SET_SEARCH_SESSION:
            return { ...state, search: payload }
        case sessionActions.SET_SESSION_DELETE_ERROR:
            return { 
                ...state, 
                errors: {
                    ...state.errors,
                    deleteError: payload
                } 
            }
        default:
            return state;
    }
}