import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import {
  deleteCategory,
  listCategories,
} from '../../store/actions/categoryActions';
import Paginate from '../Utilities/Paginate';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';

const categoryHeaders = [
  'S.No.',
  'Category Name',
  'font color',
  'background color',
];

const CategoryList = (props) => {
  const pageUrl = `/panel/categories`;
  const auth = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.categories.isLoading);
  const totalItems = useSelector((state) => state.categories.total);
  const perPage = useSelector((state) => state.categories.perPage);
  const deletingCategory = useSelector(
    (state) => state.categories.deletingCategory
  );
  const data = useSelector((state) => state.categories.categoryData);

  const location = useLocation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();

  useEffect(() => {
    if (auth.user.token && currentPage >= 2) {
      dispatch(listCategories(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handleDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      await dispatch(deleteCategory(id));
    }
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const categoryRow = (data, i) => {
    return (
      <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id}>
        <td>{startIndex + 1 + i}</td>
        <td>{data.name}</td>
        <td className="text-center">
          <div
            className="dot-lg d-inline-block"
            style={{ backgroundColor: data.fontColor }}
          ></div>
        </td>
        <td className="text-center">
          <div
            className="dot-lg d-inline-block"
            style={{ backgroundColor: data.backgroundColor }}
          ></div>
        </td>
        <td>
          {deletingCategory === data.id ? (
            <i className="fas fa-spinner fa-pulse ml-3"></i>
          ) : (
            <>
              <Link to={pageUrl + '/' + data.id + '/edit'}>
                <i className="fas fa-edit"></i>
              </Link>
              <span
                onClick={() => {
                  setDeleteData({ id: data.id, message: data.name });
                  setShow(!show);
                }}
                className="ml-4"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-trash text-danger"></i>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-3">
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {categoryHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {data && data.length > 0 && (
                <tbody>
                  {data.slice(startIndex, endIndex).map(categoryRow)}
                </tbody>
              )}
            </Table>
            {data && (
              <Paginate
                totalItems={totalItems}
                currentPage={currentPage}
                perPage={perPage}
                pageUrl={pageUrl}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
