import { channelActions } from '../actions/channelActions';

export const initialState = {
  list: [],
  current: null,
  errors: { listError: null, currentItemError: null },
  total: 0,
  isLoading: false,
  isCurrentLoading: false,
  perPage: 10,
  deletingChannel: null,
  search: '',
};

export const channelReducer = (state = initialState, { type, payload }) => {
  let list;
  switch (type) {
    case channelActions.CHANNELS_LOADING:
      return { ...state, isLoading: payload.isLoading };
    case channelActions.CURRENT_CHANNEL_LOADING:
      return { ...state, isCurrentLoading: payload.isLoading };
    case channelActions.SET_CURRENT_CHANNEL:
      return { ...state, current: payload.channel };
    case channelActions.SET_CHANNELS:
      list = payload.isFirstTimeLoad
        ? payload.channels
        : [...state.list, ...payload.channels];
      return {
        ...state,
        list,
        total: payload.count,
      };
    case channelActions.ADD_CHANNEL:
      list = [...state.list, payload.channel];
      return { ...state, list };
    case channelActions.EDIT_CHANNEL:
      const idx = state.list.findIndex(
        (comm) => comm.id === payload.channel.id
      );
      list = [...state.list];
      list[idx] = payload.channel;
      return { ...state, list };
    case channelActions.REMOVE_CHANNEL:
      list = state.list.filter((comm) => comm.id !== payload.channel.id);
      return { ...state, list };
    case channelActions.SET_CHANNELS_ERROR:
      return {
        ...state,
        errors: { ...state.errors, listError: payload.error },
      };
    case channelActions.SET_CURRENT_CHANNEL_ERROR:
      return {
        ...state,
        errors: { ...state.errors, currentItemError: payload.error },
      };
    case channelActions.SET_DELETING_CHANNEL:
      return { ...state, deletingChannel: payload.id };
    case channelActions.SET_SEARCH:
      return { ...state, search: payload };
    default:
      return state;
  }
};
