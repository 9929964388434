import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const NavbarLink = ({ children, to, exact }) => {
  let match = useRouteMatch({
    path: to,
    exact,
  });

  return (
    <li className={'nav-item ' + (match ? 'active' : '')}>
      <Link className="nav-link" to={to}>
        {children}
      </Link>
    </li>
  );
};

export default NavbarLink;
