import axios from 'axios';

const apiUrl = (process.env.REACT_APP_API_URL || '') + '/api';

export const apiRequest = async (
  method,
  endPoint,
  { token, formData, storeToken = false, params = {} }
) => {
  const options = {
    headers: {
      Authorization: 'Bearer ' + (token || ''),
    },
    params,
  };
  if (storeToken && formData) formData.storeToken = true;
  const url = `${apiUrl.replace(/\/$/, '')}/${endPoint.replace(/^\//, '')}`;

  let response;
  if (['get', 'delete'].includes(method))
    response = await axios[method](url, options);
  else response = await axios[method](url, formData, options);
  return response;
};

export const setSubmitError = (err, setErrors) => {
  let errorMessage = getErrorMsg(
    err,
    'Error in submitting the form. Please try again.'
  );
  setErrors({
    submitError: errorMessage,
  });
};

export const getErrorMsg = (
  err,
  defaultMsg = 'Something went wrong! Please try again.'
) => {
  let errorMessage =
    err.response && err.response.data.errors
      ? err.response.data.errors[0].message
      : defaultMsg;
  return errorMessage;
};

export const uploadUsingSignedUrl = async (keyPrefix, uploadedImg, token) => {
  let imageUrl = '';
  if (uploadedImg) {
    const urlReponse = await apiRequest('post', '/uploads/image', {
      formData: { keyPrefix, fileType: uploadedImg.type },
      token: token,
    });
    await axios.put(urlReponse.data.url, uploadedImg, {
      headers: { 'Content-Type': uploadedImg.type },
    });
    imageUrl = process.env.REACT_APP_S3_BUCKET_URL + urlReponse.data.key;
  }
  return imageUrl;
};
