import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
  apiRequest,
  setSubmitError,
  uploadUsingSignedUrl,
} from '../../utils/requests';
import { useSelector, useDispatch } from 'react-redux';
import { getUserName, sanitizeResponse } from '../../utils/helpers';
import ImageUpload from '../Utilities/ImageUpload';
import ErrorMessage from '../Utilities/ErrorMessage';
import {
  addEditChannelAction,
  setChannelAction,
} from '../../store/actions/channelActions';

const types = [
  { label: 'Messaging', value: 'messaging' },
  { label: 'Commerce', value: 'commerce' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'Livestream', value: 'livestream' },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChannelCreate = (props) => {
  const auth = useSelector((state) => state.auth);
  const [communities, setCommunities] = useState([]);
  const channel = useSelector((state) => state.channels.current);
  const keywords = useSelector((state) => state.keywords.list);
  const users = useSelector((state) => state.users.list);
  const isLoading = useSelector((state) => state.channels.isCurrentLoading);

  const { path } = useRouteMatch();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);
  const [prevImg, setPrevImg] = useState('');
  const [uploadedImg, setUploadedImg] = useState();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const isCreatePage = path.includes('create');
  const pageAction = isCreatePage ? 'Create' : 'Edit';

  const initialValues = {
    id: '',
    name: '',
    imageUrl: '',
    type: types[0].value,
    communityId: 0,
    isActive: true,
    isPrivate: false,
    autoAddMembers: false,
  };

  const handleFormSumbit = async (
    values,
    { setErrors, resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      setErrors({ submitError: '' });
      const keyPrefix = 'channels/' + (values.id || 'new');
      const imageUrl = await uploadUsingSignedUrl(
        keyPrefix,
        uploadedImg,
        auth.user.token
      );
      if (imageUrl) {
        setFieldValue('imageUrl', imageUrl);
        values = { ...values, imageUrl };
      }
      const filteredUsers = filterUsers(selectedUsers, +values.communityId);
      values.keywords = selectedKeywords.map((keyword) => keyword.id);
      values.memberIds = filteredUsers.map((user) => user.id);

      delete values.users;
      await dispatch(
        addEditChannelAction({
          values,
          isCreatePage,
          onSuccess: async (channel) => {
            setSubmitting(false);
            if (channel) {
              try {
                history.goBack();
              } catch (err) {
                history.push('/channels');
              }
            }
          },
          onError: async (err) => {
            setSubmitError(err, setErrors);
            setSubmitting(false);
          },
        })
      );
    } catch (err) {
      setSubmitError(err, setErrors);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Channel Name is required'),
    imageUrl: Yup.string().url('Enter a valid image url'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSumbit,
    validationSchema,
  });

  const { setValues, setFieldValue, values } = formik;

  const filterUsers = useCallback(
    (users, communityId, log) => {
      let filteredUsers = users;
      if (false && communityId)
        filteredUsers = users.filter((user) => {
          const communitIds = user.communities.map((com) => com.id);
          return communitIds.includes(communityId);
        });
      if (channel && channel.creator)
        filteredUsers = filteredUsers.filter(
          (user) => user.id !== channel.creator.id
        );
      else if (auth.user)
        filteredUsers = filteredUsers.filter(
          (user) => user.id !== auth.user.id
        );
      return filteredUsers;
    },
    [auth.user, channel]
  );

  const fetchCommunities = useCallback(async () => {
    try {
      const response = await apiRequest(
        'get',
        '/communities/simplelist?private=1',
        {
          token: auth.user.token,
        }
      );
      const { communities } = response.data;
      communities.forEach((community) => sanitizeResponse(community));
      setCommunities(communities);

      let communityId = communities[0].id;
      const defaultCommunity = communities.find((c) => c.name === 'askdiem');
      if (defaultCommunity) communityId = defaultCommunity.id;
      setFieldValue('communityId', communityId);
    } catch (err) {}
  }, [auth.user.token, setCommunities, setFieldValue]);

  const fetchInfo = useCallback(async () => {
    if (isCreatePage) await fetchCommunities();
    if (!isCreatePage && params.id) {
      dispatch(setChannelAction(+params.id));
    }
  }, [fetchCommunities, params, isCreatePage, dispatch]);

  useEffect(() => {
    if (channel && !isCreatePage) {
      sanitizeResponse(channel);
      setValues(channel);
      setPrevImg(channel.imageUrl || '');
      setSelectedKeywords(channel.keywords || []);
      setSelectedUsers(channel.users || []);
    }
  }, [channel, isCreatePage, setValues]);

  useEffect(() => {
    if (isCreatePage) {
      const userKeywords = (auth.user.keywords || []).map((k) => k.id);
      let defaultKeywords = keywords.filter((k) => userKeywords.includes(k.id));
      setSelectedKeywords(defaultKeywords);
    }
  }, [auth.user.keywords, keywords, isCreatePage, setSelectedKeywords]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  const setImageUrl = (imageUrl) => {
    setFieldValue('imageUrl', imageUrl);
  };

  let copyTimeout;
  const handleDeepLinkCopy = () => {
    setCopied(true);
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => setCopied(false), 2000);
  };

  const filteredUsers = useMemo(() => {
    return filterUsers(users, +values.communityId);
  }, [users, values.communityId, filterUsers]);

  const filteredSelectedUsers = useMemo(() => {
    return filterUsers(selectedUsers, +values.communityId);
  }, [selectedUsers, values.communityId, filterUsers]);

  return (
    <div className="card shadow">
      <div className="card-body">
        <h1>{pageAction} Channel</h1>
        {isLoading ? (
          <div className="fa-3x d-flex justify-content-center">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <form className="needs-validation" onSubmit={formik.handleSubmit}>
            {!isCreatePage && channel && channel.deepLink && (
              <>
                <CopyToClipboard
                  text={channel.deepLink}
                  onCopy={handleDeepLinkCopy}
                >
                  <div className="form-group input-group" title="click to copy">
                    <div className="input-group-append">
                      {copied && (
                        <span className="input-group-text text-white bg-success">
                          Copied to clipboard!
                        </span>
                      )}
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={channel.deepLink}
                      readOnly
                    />
                    <div className="input-group-append">
                      <i className="input-group-text fas fa-copy"></i>
                    </div>
                  </div>
                </CopyToClipboard>
              </>
            )}
            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="name">Channel Name:</label>
                <input
                  name="name"
                  id="name"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <ErrorMessage name="name" formik={formik} />
              </div>

              <div className="form-group col-12 col-md-6">
                <label htmlFor="type">Type:</label>
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                >
                  {types.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
                <ErrorMessage name="type" formik={formik} />
              </div>
            </div>
            <div className="row">
              <ImageUpload
                prevImg={prevImg}
                setFieldValue={setImageUrl}
                imgAlt={formik.values.name}
                className="col-12 col-lg-6 order-lg-2 mb-4"
                setUploadedImg={setUploadedImg}
              >
                <ErrorMessage name="imageUrl" formik={formik} />
              </ImageUpload>
              <div className="col-12 col-lg-6 d-flex flex-column mb-4">
                {isCreatePage && (
                  <div className="form-group">
                    <label htmlFor="communityId">Community:</label>
                    <select
                      name="communityId"
                      id="communityId"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={+formik.values.communityId}
                    >
                      {communities.map((community) => (
                        <option key={community.id} value={community.id}>
                          {community.name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="communityId" formik={formik} />
                  </div>
                )}
                <div className="form-group">
                  <Autocomplete
                    multiple
                    id="keywords"
                    options={keywords}
                    disableCloseOnSelect
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                      option.keyword + ' ' + option.emoji
                    }
                    getOptionSelected={(option, value) => {
                      return option.id === value.id;
                    }}
                    onChange={(e, value) => setSelectedKeywords(value)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.keyword} {option.emoji}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Keywords"
                        placeholder="Interests"
                      />
                    )}
                    value={selectedKeywords}
                  />
                </div>
                <div className="form-group">
                  <Autocomplete
                    multiple
                    id="users"
                    options={filteredUsers}
                    disableCloseOnSelect
                    filterSelectedOptions
                    getOptionLabel={(option) => getUserName(option)}
                    getOptionSelected={(option, value) => {
                      return option.id === value.id;
                    }}
                    onChange={(e, value) => setSelectedUsers(value)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {getUserName(option)}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Members"
                        placeholder="Enter the email"
                      />
                    )}
                    value={filteredSelectedUsers}
                  />
                </div>
                <div className="form-group mt-auto">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        name="autoAddMembers"
                        id="autoAddMembers"
                        className="form-check-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.autoAddMembers}
                      />
                      Add members by default
                    </label>
                  </div>
                  <ErrorMessage name="autoAddMembers" formik={formik} />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        name="isPrivate"
                        id="isPrivate"
                        className="form-check-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isPrivate}
                      />
                      Private Channel
                    </label>
                  </div>
                  <ErrorMessage name="isPrivate" formik={formik} />
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        name="isActive"
                        id="isActive"
                        className="form-check-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isActive}
                      />
                      Active
                    </label>
                  </div>
                  <ErrorMessage name="isActive" formik={formik} />
                </div>
                {formik.errors.submitError && (
                  <div className="alert alert-danger">
                    {formik.errors.submitError}
                  </div>
                )}
                <div className="d-flex">
                  <button
                    className="btn btn-primary mr-5"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {isCreatePage ? 'Create' : 'Update'}
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChannelCreate;
