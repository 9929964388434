import React from 'react';
import { redirectLinkToDevice } from '../RedirectLinkToDevice';
import diemLogo from '../../../assets/images/DiemLogo.png';
import { isMobile } from 'react-device-detect';
import './RedirectToApp.css';

export function Message({ currentData }) {
  const result =
    Object.keys(currentData).length !== 0 ? currentData?.results[0] : null;
  const actor = result ? result?.actor?.data : null;
  return (
    <div
      className="main d-flex align-items-center diem-blue-gradient"
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: '100%',
        overflow: 'scroll',
      }}
    >
      <div
        className="d-flex flex-column justify-content-between sessionCardCont"
        style={{
          minHeight: isMobile ? '100%' : '90%',
          width: isMobile ? '90%' : '450px',
          paddingTop: 15,
          paddingBottom: 5,
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img alt="" src={diemLogo} className="diemLogo" />
          <text className="logoText">You’ve got knowledge!</text>
          {actor && (
            <div className="d-flex justify-content-center align-items-center p-2">
              <div>
                {actor?.profileImage ? (
                  <img alt="" src={actor?.profileImage} className="actorImage" />
                ) : (
                  <div className="nonImageAvatar actorImage">
                    <span className="d-flex justify-content-center align-items-center h-100">
                      {`${actor?.name?.charAt(0)}${actor?.name?.charAt(1)}`}
                    </span>
                  </div>
                )}
              </div>
              <text className="dateAvTxt">{actor?.name}</text>
            </div>
          )}
          <text className="logoText">
            invited you to contribute to this Diem...
          </text>
        </div>

        <div
          className="d-flex flex-column justify-content-between"
          style={{ flex: 1 }}
        >
          <div
            className="sessionCard cardColor text-white"
            style={{ marginTop: 30 }}
          >
            {result && actor && (
              <div
                className="d-flex flex-column"
                style={{ padding: 10, paddingTop: 15 }}
              >
                <div className="w-100 align-items-center d-flex justify-content-between">
                  <div>
                    {/* <div className="d-flex flex-row justify-content-between">
                      <div className="mb-2 subCatText">
                        {result?.subCategoryName}
                      </div>
                    </div> */}
                    <div className="d-flex">
                      <div>
                        {actor?.profileImage ? (
                          <img
                            alt=""
                            src={actor?.profileImage}
                            className="dateAvatar imgBordered"
                          />
                        ) : (
                          <div className="nonImageAvatar">
                            {`${actor?.name?.charAt(0)}${actor?.name?.charAt(
                              1
                            )}`}
                          </div>
                        )}
                      </div>
                      <span className="dateAvTxt">{`${actor?.name}`}</span>
                      {/* {actor?.isVerified && (
                        <div style={{ marginTop: -2, marginLeft: 2 }}>
                          <Star />
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <h6 className="mb-3 mt-3 diemText">{result?.text}</h6>
                </div>
              </div>
            )}
          </div>
          <div className="ellipseStyle diem-light-blue-gradient">
            <input
              type="button"
              value="View in the Diem app"
              className="ellipseButton"
              onClick={() => redirectLinkToDevice()}
              style={{
                padding: isMobile ? 5 : 0,
                paddingBottom: isMobile ? 10 : 0,
              }}
            />
          </div>
          {/* <input
            type="button"
            value="View in the Diem app"
            className="ellipseStyle diem-light-blue-gradient"
            style={{
              padding: isMobile ? 5 : 0,
              paddingBottom: isMobile ? 10 : 0,
            }}
          /> */}
          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="blockTitle-1" style={{ color: '#000' }}>
              Don't have an account in Diem?
            </p>
            <div className="d-flex align-items-center">
              <div className="playImg-1 mr-2">
                <a href="https://apps.apple.com/in/app/diem-womxns-social-platform/id1524855792">
                  <img alt="" src="./icons/app.png" className="w-100" />
                </a>
              </div>
              <div className="playImg-1">
                <a href="https://play.google.com/store/apps/details?id=com.askdiem.app&hl=en">
                  <img alt="" src="./icons/play.png" className="w-100" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
