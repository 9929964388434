import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import { routes } from '../../routes/metricRoutes';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { NavLink } from 'react-router-dom';
import { fetchMetricsData } from '../../store/actions/metricActions';

const Metrics = () => {
  const auth = useSelector((state) => state.auth);
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => { 
    if(auth.user.token){
      dispatch(fetchMetricsData());
    }
  }, [dispatch,auth.user.token]);

  return (
    <div>
      <Nav
        variant="tabs"
        defaultActiveKey={path + '/activeusers'}
        className="mb-5"
      >
        <li className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            isActive={() => pathname === path || pathname === path + '/daily'}
            to={path + '/daily'}
          >
            Daily
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={path + '/weekly'}
          >
            Weekly
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={path + '/monthly'}
          >
            Monthly
          </NavLink>
        </li>
      </Nav>
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={path + route.path}
            exact={route.exact}
            render={(routeParams) => (
              <route.component {...(route.props || {})} {...routeParams} />
            )}
          />
        ))}
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default Metrics;
