import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { DateTime, Interval } from 'luxon';
import moment from 'moment';
import Paginate from '../Utilities/Paginate';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';
import { searchSessionsList, sessionDelete, setSessionDelete, setSessionSearchKey, setSessionsList } from '../../store/actions/sessionActions';
import Alert from '@material-ui/lab/Alert';

const sessionHeaders = [
  'S.No.',
  'Session Name',
  'Session Start Time',
  'Session Status'
];
const pageUrl = '/panel/sessions';

const SessionsList = () => {
  const auth = useSelector((state) => state.auth);
  const sessions = useSelector((state) => state.sessions.sessionList);
  const isLoading = useSelector((state) => state.sessions.isLoading);
  const isDeleteSessionLoading = useSelector((state) => state.sessions.isDeleteSessionLoading);
  const totalItems = useSelector((state) => state.sessions.total);
  const perPage = useSelector((state) => state.sessions.perPage);
  const search = useSelector((state) => state.sessions.search);
  const deletingSession = useSelector((state) => state.sessions.deletingSession);
  const deleteError = useSelector((state) => state.sessions.errors.deleteError);

  const [show, setShow] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const setSessionToDelete = async (data) => {
    await dispatch(setSessionDelete(data))
  };

  const startIndex = (currentPage - 1) * perPage;

  const handleSessionDelete = async (data) => {
    setShow(false)
    await dispatch(sessionDelete(data?.id, currentPage));
    if(search.length > 0){
      await dispatch(searchSessionsList(currentPage))
    }else{
      await dispatch(setSessionsList(currentPage))
    }
  }

  const handleSearch = (e) => {
    dispatch(setSessionSearchKey(e.target.value));
    if(e.target.value === ''){
      setPageUrl(1)
      dispatch(setSessionsList(1))
    }
  };

  const searchSession = () => {
    setPageUrl(1)
    dispatch(searchSessionsList(1))
  }

  const clearSearch = () => {
    setPageUrl(1)
    dispatch(setSessionSearchKey(''));
    dispatch(setSessionsList(1))
  }

  const setPageUrl = (currentPage) => {
    let { url, query } = queryString.parseUrl(pageUrl);
    query = { ...query, page: currentPage };
    setCurrentPage(currentPage)
    history.push(queryString.stringifyUrl({ url, query }))
  };

  useEffect(() => {
    if (auth.user.token && currentPage) {
      if(search.length > 0){
        dispatch(searchSessionsList(currentPage))
      }else{
        dispatch(setSessionsList(currentPage))
      }
    }
  }, [auth.user.token, currentPage, dispatch]);

  const sessionStartedStatus = useCallback(
    (
      startTime,
      endTime,
      {sessionCompletedFn, sessionStartedFn, upcomingSessionFn} = {},
    ) => {
      let todayDate = DateTime.local();
      let endedTime = DateTime.fromISO(endTime, {
        zone: 'local',
      });

      // Calculate If Session has started
      let startTimes = DateTime.fromISO(startTime, {
        zone: 'local',
      });
      let startPlusTime = DateTime.fromISO(startTime, {
        zone: 'local',
      }).plus({minutes: 30});
      let todayDatePlusOne = DateTime.local().plus({minutes: 1});
      const hasSessionStarted = Interval.fromDateTimes(
        startTimes,
        endedTime,
      ).contains(todayDatePlusOne);
      const hasSessionEnded = Interval.fromDateTimes(
        startTimes,
        startPlusTime,
      ).contains(todayDate);
      let betweeenTwoHours = false;
      let sessionStatusCard = 'Upcoming Session';

      let result = endedTime
        .diff(todayDate, ['months', 'days', 'hours'])
        .toObject();
      let isGreater = Math.sign(result.hours);
      if (
        result.days <= 0 &&
        isGreater === -1 &&
        (result.hours <= -2 || result.days <= 0)
      ) {
        sessionStatusCard = 'Session Completed';
        if (sessionCompletedFn) sessionCompletedFn();
        if (result.hours >= -2 && result.hours <= -0 && !(result.days < 0)) {
          betweeenTwoHours = true;
        }
      } else if (hasSessionStarted) {
        sessionStatusCard = 'Live';
        if (sessionStartedFn) sessionStartedFn();
      } else if (result.days >= 0 && isGreater === 1) {
        sessionStatusCard = 'Upcoming Session';
        if (upcomingSessionFn) upcomingSessionFn();
      }
      return sessionStatusCard;
    },
    [],
  );

  const sessionRow = (session, i) => (
    <tr key={session.id}>
      <td>{startIndex + 1 + i}</td>
      <td>{session.name}</td>
      <td>{moment(session.startTime).format('MMM Do, hh:mm a')}</td>
      <td>{sessionStartedStatus(session?.startTime, session?.endTime)}</td>
      <td>
        {isDeleteSessionLoading && session.id === deletingSession.id ? (
          <i className="fas fa-spinner fa-pulse ml-3"></i>
        ) : (
          <>
            <span
              onClick={() => {
                setShow(true)
                setSessionToDelete(session)
              }}
              className="remove ml-4"
            >
              <i className="fas fa-trash text-danger"></i>
            </span>
          </>
        )}
      </td>
    </tr>
  );

  return (
    <div>
      <div className="row card p-2 shadow-sm">
        <div className="col-12 d-flex align-items-center">
          <h1>Sessions</h1>
          <div className="input-group search-field mx-3 w-auto flex-fill align-items-center">
            <input 
              className="form-control py-2 border" 
              placeholder="Search" 
              name="search"
              id="example-search-input"
              value={search}
              onChange={handleSearch}
              onKeyPress={(e) => {
                if(e.key === "Enter"){
                  searchSession()
                }
              }}
            />
            <span className="input-group-append">
              {
                search?.length !== 0 && 
                <button className="btn btn-outline-secondary border-left-0 border" type="button" onClick={() => clearSearch()}>
                    <i className="fa fa-times"></i>
                </button>
              }
            </span>
            <button
                className={'input-group-append btn'}
                onClick={() => searchSession()}
            >
                <i className="fa fa-search input-group-text font-weight-bold"></i>
            </button>
          </div>
        </div>
      </div>
      {
        deleteError &&
        <div>
          <Alert severity="error">
            Something went wrong while deleting session!
          </Alert>
          <br></br>
        </div>
      }
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deletingSession}
              handleDelete={handleSessionDelete}
              closePopup={() => setShow(false)}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {sessionHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              {sessions && sessions.length > 0 && (
                <tbody>
                  {sessions.map(sessionRow)}
                </tbody>
              )}
            </Table>
            <Paginate
              totalItems={totalItems}
              currentPage={currentPage}
              perPage={perPage}
              pageUrl={pageUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SessionsList;
