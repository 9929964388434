import React, {useCallback, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import Paginate from '../Utilities/Paginate';
import {
  deleteTags,
  listTags,
} from '../../store/actions/tagsActions';
import ConfirmationPopup from '../Utilities/ConfirmationPopup';

const interestHeaders = [
  'N°.',
  'Tag Name',
  'Description',
  'Image Cover',
  'Image Header',
  'Connected Interest'
];

const TagsList = (props) => {
  const pageUrl = `/panel/tags`;
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const fetchListTags = useCallback(async () => {
    if (auth.user.token) {
      await dispatch(listTags(1));
    }
  }, [dispatch, auth.user.token]);

  useEffect(() => {
    fetchListTags();
  }, [fetchListTags]);

  const isLoading = useSelector((state) => state.tags.isLoading);
  const totalItems = useSelector((state) => state.tags.total);
  const perPage = useSelector((state) => state.tags.perPage);
  // const deletingTags = useSelector((state) => state.tags.deletingInterest);
  const data = useSelector((state) => state.tags.tagsData);

  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    if (auth.user.token && currentPage >= 2) {
      dispatch(listTags(currentPage));
    }
  }, [auth.user.token, currentPage, dispatch]);

  useEffect(() => {
    const { page } = queryString.parse(location.search);
    setCurrentPage(page ? +page : 1);
  }, [location.search]);

  const handleDelete = async (params) => {
    const { id } = params;
    if (auth.user.token) {
      await dispatch(deleteTags(id));
    }
  };

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const tagsRow = (data, i) => {
    return (
        <tr className={data.addedNow ? 'blink-anim' : ''} key={data.id + i}>
        <td>{startIndex + 1 + i}</td>
        <td>{data.tagName}</td>
        <td>{data.description}</td>
        <td>{data.imageCover}</td>
        <td>{data.imageHeader}</td>
        <td>{data.relatedKeyword?.keyword}</td>
      </tr>
    );
  };

  return (
    <div className="p-3">
      <div className="row">
        {isLoading ? (
          <div className="fa-3x d-flex flex-fill justify-content-center pt-3">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>
        ) : (
          <>
            <ConfirmationPopup
              show={show}
              data={deleteData}
              handleDelete={handleDelete}
            />
            <Table striped bordered hover>
              <thead>
                <tr>
                  {interestHeaders.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              {data && data.length > 0 && (
                <tbody>
                  {data.slice(startIndex, endIndex).map(tagsRow)}
                </tbody>
              )}
            </Table>
            {data && (
              <Paginate
                totalItems={totalItems}
                currentPage={currentPage}
                perPage={perPage}
                pageUrl={pageUrl}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TagsList;
